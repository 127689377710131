import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import { baseUrl } from "../../config/const";
import Controls from "../../components/controls/Controls";

export default function DeleteVoucher({ param, location }) {
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const deleteVoucher = async (param) => {
    await Axios.delete(
      process.env.NODE_ENV === "production"
        ? `${baseUrl}/deleteVoucher/${param}`
        : `/deleteVoucher/${param}`
    )
      .then((response) => {
        setNotify({
          message: response.data.msg,
          isOpen: true,
          type: "success",
          location: location,
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
          location: location,
        });
      });
  };
  return (
    <>
      <Controls.ActionButton
        color="secondary"
        onClick={() => {
          setConfirmPopup({
            isOpen: true,
            title: `Are you sure to delete voucher ${param}?`,
            subtitle: "You can't undo the operation",
            onConfirm: () => {
              deleteVoucher(param);
            },
          });
        }}
      >
        <CloseIcon fontSize="medium" />
      </Controls.ActionButton>

      <Controls.Notification notify={notify} setNotify={setNotify} />
      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </>
  );
}
