import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Controls from './Controls';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    dialog : {
        padding : theme.spacing(2),
        position : 'absolute',
        top : theme.spacing(5)
    },
    dialogTitle : {
        textAlign :'center'
    },
    dialogContent : {
        textAlign : 'center'
    },
    dialogAction : {
        justifyContent :'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))
export default function PopUpNotification({popUpNotification,setPopUpNotification}){
    const classes = useStyles()
    const history = useHistory()
    return(
        <div>
            <Dialog open={popUpNotification.isOpen} className={classes.dialog}>
                <DialogTitle className={classes.dialogTitle}>
                    <IconButton disableRipple className={classes.titleIcon}>
                        <NotListedLocationIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography variant="h6">
                        {popUpNotification.title}
                    </Typography>
                    <Typography variant="subtitle2">
                        {popUpNotification.subTitle}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Controls.Button
                    text="OK"
                    onClick={() => {
                        setPopUpNotification({
                            ...popUpNotification,
                            isOpen : false
                        })
                        history.push("/") 
                    }} />
                </DialogActions>
            </Dialog>
        </div>
    )
}