import React, { useState, useEffect } from "react";
import Controls from "../../components/controls/Controls";
import axios from "axios";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { Form, useForm } from "../../components/useForm";
import { Grid } from "@material-ui/core";
import { baseUrl } from "../../config/const";

export default function ProductCompletion({ item, location }) {
  const [formInfo, setFormInfo] = useState({
    brand: [],
    color: [],
    department: [],
    category: [],
    supplier: [],
  });
  /**
   *
   * @param {*} e indicates the event of getting form's component information
   */
  const getFormInfo = async (e) => {
    await axios
      .get(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/getFormInfo`
          : `/getFormInfo`
      )
      .then((response) => {
        setFormInfo({
          ...formInfo,
          brand: response.data.results.brand,
          color: response.data.results.color,
          department: response.data.results.department,
          category: response.data.results.category,
          supplier: response.data.results.supplier,
        });
      });
  };

  const [iv, setIv] = useState({
    brand: 0,
  });

  useEffect(() => {
    getFormInfo().then(() => {
      setIv({
        ...iv,
        brand: 18,
      });
    });
  }, []);

  console.log(iv.brand);
  const initialValues = {
    images: [],
    brand: item.brand !== "-" ? item.brandid : item.brand,
    department: item.department !== "-" ? item.departmentid : item.department,
    category: item.category !== "-" ? item.categoryid : item.category,
    color: item.color !== "-" ? item.colorid : item.color,
    description: item.description,
    material: item.material,
    supplier: item.supplier,
    size: item.size === null ? [] : item.size,
    pgol: "-",
    price4: "",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("pgol" in fieldValues) {
      temp.pgol =
        fieldValues.pgol === "-" || fieldValues.pgol.length === 0
          ? "Golongan produk harus diisi"
          : "";
    }
    if ("brand" in fieldValues) {
      temp.brand =
        fieldValues.brand === "-" || fieldValues.brand.length === 0
          ? "Brand harus diisi diisi"
          : "";
    }
    if ("category" in fieldValues) {
      temp.category =
        fieldValues.category === "-" || fieldValues.category.length === 0
          ? "Kategori produk harus diisi"
          : "";
    }
    if ("description" in fieldValues) {
      temp.description = fieldValues.description
        ? ""
        : "Deskripsi barang harus diisi";
    }
    if ("department" in fieldValues) {
      temp.department =
        fieldValues.department === "-" || fieldValues.department.length === 0
          ? "Department produk harus diisi diisi"
          : "";
    }
    if ("size" in fieldValues) {
      if (fieldValues.size.length === 0) {
        temp.size = "Size cannot be empty";
      }
      // else if(fieldValues.size.length > 7){
      //     temp.size = "Max of 7 different Size"
      // }
      else {
        temp.size = "";
      }
    }
    if ("price4" in fieldValues) {
      if (values.pgol !== "NORMAL") {
        temp.price4 = fieldValues.price4 ? "" : "Harga Nett harus diisi";
      }
    }
    if ("supplier" in fieldValues) {
      temp.supplier =
        fieldValues.supplier === "-" || fieldValues.supplier.length === 0
          ? "Supplier harus diisi diisi"
          : "";
    }
    if ("material" in fieldValues) {
      temp.material = fieldValues.material ? "" : "Material barang harus diisi";
    }
    if ("color" in fieldValues) {
      temp.color =
        fieldValues.color === "-" || fieldValues.color.length === 0
          ? "Warna produk harus diisi"
          : "";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const formData = new FormData();
  // for(const key of Object.keys(values.images)){
  //     formData.append('images',values.images[key])
  // }
  formData.append(
    "brand",
    formInfo.brand.find((x) => x.name === values.brand)?.id
  );
  formData.append(
    "category",
    formInfo.category.find((x) => x.name === values.category)?.id
  );
  formData.append("description", values.description);
  formData.append(
    "department",
    formInfo.department.find((x) => x.name === values.department)?.id
  );
  formData.append(
    "supplier",
    formInfo.supplier.find((x) => x.name === values.supplier)?.id
  );
  // formData.append('brand',values.brand)
  // formData.append('pname',values.pname)
  // formData.append('color',values.color)
  // formData.append('department',formInfo.department.find(x => x.name === values.department)?.id)
  // formData.append('department',values.department)
  // formData.append('category',values.category)
  // formData.append('supplier',values.supplier)
  for (const key of Object.keys(values.size)) {
    formData.append("size", values.size[key]);
  }
  formData.append(
    "color",
    formInfo.color.find((x) => x.name === values.color)?.id
  );
  formData.append("material", values.material);
  formData.append("pgol", values.pgol);

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      axios
        .put(
          process.env.NODE_ENV === "production"
            ? `${baseUrl}/completeProduct/${item.sku}`
            : `/completeProduct/${item.sku}`,
          {
            brand:
              values.brand === "Pollux"
                ? formInfo.brand.find((x) => x.name === values.brand)?.id
                : values.brand,
            category: values.category,
            description: values.description,
            department: values.department,
            supplier: values.supplier,
            size: values.size,
            color: values.color,
            material: values.material,
            pgol: values.pgol,
            price4: values.price4,
          }
        )
        .then((response) => {
          setNotify({
            ...notify,
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: location,
          });
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: err.response.data.msg,
            type: "error",
            location: location,
          });
        });
    }
  };

  //Adding New Size
  const [addNewSize, setAddNewSize] = useState({
    value: "",
    validate: true,
    message: "",
  });

  const [hideDelete, setHideDelete] = useState({
    isHidden: true,
    color: "red",
  });

  const [openSizePopup, setOpenSizePopup] = useState(false);
  const deleteNewSize = (item) => {
    setValues({
      ...values,
      size: values.size.filter((x) => x !== item),
    });
  };

  const handleNewSize = async (e) => {
    setAddNewSize({
      ...addNewSize,
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (values.size.includes(addNewSize.value)) {
      setAddNewSize({
        ...addNewSize,
        validate: false,
        message: "Tidak boleh sama",
      });
    } else if (addNewSize.value === "") {
      setAddNewSize({
        ...addNewSize,
        validate: false,
        message: "Tidak boleh kosong",
      });
    } else {
      setAddNewSize({
        ...addNewSize,
        validate: true,
        message: "",
      });
    }
  }, [addNewSize.value]);

  const submitNewSize = (event, newSize) => {
    event.preventDefault();
    setValues({
      ...values,
      size: [...values.size, newSize],
    });
    setAddNewSize({
      ...addNewSize,
      value: "",
    });
  };

  const typeOptions = [
    { id: "DISC", name: "Discount" },
    { id: "OB", name: "Obral" },
    { id: "BS", name: "Big Size" },
    { id: "NORMAL", name: "Harga Normal" },
  ];
  return (
    <>
      <Controls.ActionButton color="primary" onClick={() => setOpenPopup(true)}>
        <EditOutlined fontSize="medium" />
      </Controls.ActionButton>

      <Controls.Popup
        title="Complete Product"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form>
          <div className="title">
            SKU: {item.sku} <br />
            Product Name: {item.pname}
          </div>

          <Grid container>
            <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
              <Controls.Label label="Golongan Produk" />
              <Controls.Select
                name="pgol"
                value={values.pgol}
                error={errors.pgol}
                onChange={handleInputChange}
                options={typeOptions}
              />

              <Controls.Label label="Brand: " />
              <Controls.Select
                name="brand"
                value={values.brand}
                onChange={handleInputChange}
                options={formInfo.brand}
                error={errors.brand}
              />

              <Controls.Label label="Department: " />
              <Controls.Select
                options={formInfo.department}
                name="department"
                value={values.department}
                error={errors.department}
                onChange={handleInputChange}
              />

              <Controls.Label label="Category: " />
              <Controls.Select
                options={formInfo.category}
                name="category"
                value={values.category}
                error={errors.category}
                onChange={handleInputChange}
              />

              <Controls.Label label="Color: " />
              <Controls.Select
                options={formInfo.color}
                name="color"
                value={values.color}
                onChange={handleInputChange}
                error={errors.color}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
              <Controls.Label label="Description: " />
              <Controls.TextArea
                name="description"
                value={values.description}
                onChange={handleInputChange}
                error={errors.description}
                rows={5}
                cols={5}
              />

              <Controls.Label label="Material: " />
              <Controls.Input
                name="material"
                value={values.material}
                onChange={handleInputChange}
                error={errors.material}
              />

              <Controls.Label label="Supplier: " />
              <Controls.Select
                options={formInfo.supplier}
                name="supplier"
                value={values.supplier}
                onChange={handleInputChange}
                error={errors.supplier}
              />

              <div style={{ display: values.pgol === "-" && "none" }}>
                <div
                  style={{
                    display: values.pgol !== "NORMAL" ? "block" : "none",
                  }}
                >
                  <Controls.Label label="Harga 4: " />
                  <Controls.Input
                    type="number"
                    name="price4"
                    value={values.price4}
                    placeholder="Harga Nett, untuk produk "
                    onChange={handleInputChange}
                    error={errors.price4}
                  />
                </div>
              </div>

              <Controls.Label label="Add Sizes: " />
              {values.size.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "inline-flex", position: "relative" }}
                  >
                    <span className="sizing" key={index}>
                      {item}
                    </span>
                    <a
                      hidden={hideDelete.isHidden}
                      onClick={() => deleteNewSize(item)}
                      style={{ color: hideDelete.color }}
                      className="xtag"
                    >
                      <DeleteOutline />
                    </a>
                  </div>
                );
              })}
              <span className="error">{errors.size}</span>
              <Controls.Button
                text="Tambah Size +"
                onClick={() => setOpenSizePopup(!openSizePopup)}
              />
              <Controls.Button
                text="Delete"
                icon={<DeleteOutline />}
                color="secondary"
                onClick={() =>
                  setHideDelete({
                    ...hideDelete,
                    isHidden: !hideDelete.isHidden,
                  })
                }
              />
              <Controls.Popup
                title="Add New Size"
                openPopup={openSizePopup}
                setOpenPopup={setOpenSizePopup}
              >
                <Form
                  onSubmit={(event) => {
                    if (addNewSize.validate === true) {
                      submitNewSize(event, addNewSize.value);
                    } else {
                      event.preventDefault();
                    }
                  }}
                >
                  <Controls.Label label="Add Sizes: " />
                  <Controls.Input
                    autoFocus
                    value={addNewSize.value}
                    onChange={handleNewSize}
                    error={addNewSize.message}
                  />

                  <Controls.Button text="Add New Size" type="submit" />
                </Form>
              </Controls.Popup>
            </Grid>
          </Grid>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Controls.Button onClick={onSubmit} text="Complete Product" />
          </div>
        </Form>
      </Controls.Popup>

      <Controls.Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
