import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  makeStyles,
  TableHead,
  TableContainer,
  TableFooter,
  Paper,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 5,
    whiteSpace: "nowrap",
  },
}))(TableCell);

export default function useTable(records, headCells, filterFn, noPagination) {
  const useStyles = makeStyles((theme) => ({
    table: {
      marginTop: theme.spacing(3),
      "& thead th": {
        fontWeight: "600",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
      "& tbody td": {
        fontWeight: "300",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
        cursor: "pointer",
      },
    },
    root: {
      width: "100%",
    },
    container: {
      maxHeight: !noPagination ? "50vh" : null,
    },
  }));

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const pages = [noPagination ? 1000 : 5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    // <Paper className={classes.root}>
    <TableContainer className={classes.container}>
      <Table stickyHeader>{props.children}</Table>
    </TableContainer>
    // </Paper>
  );

  const TblHead = ({ styles }) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ fontWeight: "bolder", ...styles }}
            >
              {/*If disable sorting is true, so the headerCell can't be sorted and filtered */}
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  //ini pertama pasti berdasarkan order normal, terus 'asc'
                  direction={orderBy === headCell.id ? order : "asc"}
                  //switch button di sini yang buat ganti bolak balik asc-desc
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={(event, newPage) => handleChangePage(event, newPage)}
      onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      //comparator(a[0],b[0]) means that it compares the records
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      //a[1],b[1] indicates the created index
      return a[1] - b[1];
    });
    //el[0] only because we only want the records (without the index)
    return stabilizedThis.map((el) => el[0]);
  }
  /**
   *
   * @param {*} order indicates the ordering type (either asc or desc)
   * @param {*} orderBy indicates the what headCell is being sorted
   * @returns either -1 or 1
   */
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  /**
   *
   * @param {*} a comparator variable
   * @param {*} b comparator variable
   * @param {*} orderBy the cellId
   * @returns integer of sorting
   */
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //It carries all the records
  /**
   *
   * @returns array of records after Paging and Sorting
   */
  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  useEffect(() => {
    setPage(0);
  }, [records]);

  return {
    TblContainer,
    TblHead,
    TblPagination,
    TableFooter,
    recordsAfterPagingAndSorting,
    styledTableCell: StyledTableCell,
  };
}
