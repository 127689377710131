import React, { useEffect, useState } from "react";
import useTable from "../components/useTable";
import Axios from "axios";
import Controls from "../components/controls/Controls";
import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import moment from "moment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { baseUrl } from "../config/const";
import CloseIcon from "@material-ui/icons/Close";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { useForm, Form } from "../components/useForm";
import {
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";

export function EditBanner({ item }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const initialValues = {
    title: item.title,
    description: item.description,
    startdate: null,
    enddate: null,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("enddate" in fieldValues) {
      temp.enddate =
        fieldValues.enddate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("description" in fieldValues) {
      temp.description = fieldValues.description
        ? ""
        : "Description shouldn't be empty";
    }
    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "Ttile harus diisi";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const updateBanner = async (param) => {
    await Axios.put(`${baseUrl}/updateBanner/${param}`, {
      title: values.title,
      description: values.description,
      startdate: new Date(values.startdate).toLocaleDateString(),
      enddate: new Date(values.enddate).toLocaleDateString(),
    })
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/marketing/banner",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/banner",
        });
      });
  };
  return (
    <>
      <Controls.ActionButton onClick={() => setOpenPopup(true)}>
        <EditOutlined />
      </Controls.ActionButton>
      <Controls.Popup
        title={`Edit Banner of ${item.title}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Grid container>
          <Grid xs={12}>
            <h2>Edit Banner Expiration date</h2>
            <Form>
              <Controls.Label label="Banner Title" />
              <Controls.Input
                name="title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
              />

              <Controls.Label label="description" />
              <Controls.TextArea
                name="description"
                value={values.description}
                error={errors.description}
                onChange={handleInputChange}
              />
              <span
                style={{ display: item.type === "banner" ? "none" : "block" }}
              >
                Untuk Gallery dan Thumbnail, Description akan ditampilkan pada
                web
              </span>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingInline: "10px",
                  }}
                >
                  <p>
                    Current Start Date: {moment(item.startdate).format("LL")}
                  </p>
                  <Controls.Label
                    label="New Start Date: "
                    style={{ fontWeight: "bold" }}
                  />
                  <Controls.DatePicker
                    name="startdate"
                    selected={values.startdate}
                    onChange={(date) => handleDatePicker("startdate", date)}
                    error={errors.startdate}
                  />
                  <div>{moment(values.startdate).format("LL")}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingInline: "10px",
                  }}
                >
                  <p>Current End Date: {moment(item.enddate).format("LL")}</p>
                  <Controls.Label
                    label="New End Date: "
                    style={{ fontWeight: "bold" }}
                  />
                  <Controls.DatePicker
                    name="enddate"
                    minDate={values.startdate}
                    selected={values.enddate}
                    onChange={(date) => handleDatePicker("enddate", date)}
                    error={errors.enddate}
                  />
                  <div>{moment(values.enddate).format("LL")}</div>
                </div>
              </div>
            </Form>
          </Grid>
          <Controls.Button
            style={{ margin: "auto" }}
            text="Ganti masa berlangsung banner"
            onClick={() => updateBanner(item.id)}
          />
        </Grid>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default function BannerList({ records, getBanners }) {
  const headCells = [
    { id: "code", label: "Code", disableSorting: true },
    { id: "title", label: "Title", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "type", label: "Type", disableSorting: true },
    { id: "startdate", label: "Start Date" },
    { id: "enddate", label: "End Date" },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [bannerImage, setBannerImage] = useState({
    image: [],
    description: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("all");

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      textAlign: "left",
    },
    title: {
      margin: "0",
    },
  }));

  const classes = useStyles();

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  /**
   *
   * @param {*} e indicates the search event
   */
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.title.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };

  const deleteBanner = async (param) => {
    await Axios.delete(`${baseUrl}/deleteBanner/${param}`)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/marketing/banner",
        });
        getBanners();
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/banner",
        });
      });
  };

  return (
    <div className="banner-list" style={{ padding: "20px" }}>
      <Controls.PageHeader
        title="Banner List"
        icon={<ListAltIcon fontSize="large" />}
      />

      <FormControl className={classes.root} component="fieldset">
        <FormLabel component="legend">Filter Category</FormLabel>
        <RadioGroup
          row
          aria-label="category"
          // name="controlled-radio-buttons-group"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          <FormControlLabel value="All" control={<Radio />} label="All" />
          <FormControlLabel value="Banner" control={<Radio />} label="Banner" />
          <FormControlLabel
            value="Gallery"
            control={<Radio />}
            label="Gallery"
          />
          <FormControlLabel
            value="Thumbnail"
            control={<Radio />}
            label="Thumbnail"
          />
        </RadioGroup>
      </FormControl>
      <Paper>
        <Toolbar>
          <Controls.Label label="Search: " />
          <Controls.Input
            inputprops={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            placeholder="Search banner title here..."
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting()
              .filter((item) => {
                if (selectedCategory.toLowerCase() === "all") {
                  return true;
                }
                return item.type === selectedCategory.toLowerCase();
              })
              .map((item) => (
                <TableRow key={item.code} hover role="checkbox" tabIndex={-1}>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenPopup(true);
                      setBannerImage({
                        ...bannerImage,
                        image: item.banner,
                        description: item.description,
                      });
                    }}
                  >
                    {item.code}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell
                    style={{
                      color:
                        item.status === "Upcoming"
                          ? "#ECB753"
                          : item.status === "Ongoing"
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell>{item.type}</TableCell>

                  <TableCell>{moment(item.startdate).format("LL")}</TableCell>
                  <TableCell>{moment(item.enddate).format("LL")}</TableCell>
                  <TableCell>
                    <EditBanner item={item} />
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmPopup({
                          isOpen: true,
                          title: `Are you sure to delete promotion/banner ${item.code}?`,
                          subtitle: "You can't undo the operation",
                          onConfirm: () => {
                            deleteBanner(item.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />

      <Controls.Popup
        title="Banner Image"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        {bannerImage.image.map((item, index) => (
          <img
            style={{ width: "40vw", margin: "auto" }}
            src={item}
            key={index}
          />
        ))}
        <Controls.Label label="Description: " />
        <span>{bannerImage.description}</span>
      </Controls.Popup>
    </div>
  );
}
