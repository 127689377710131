import React from 'react'
import './controls.css'

export default function TextArea({name, value, onChange, error=null,placeholder,...other}){
    return (
        <div>
        <textarea
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
        />
        <span className="error">{error}</span>
        </div>
    )
}