import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./controls.css";

export default function PickDate({
  name,
  minDate,
  value,
  selected,
  onChange,
  error = null,
  placeholder,
}) {
  return (
    <div style={{ zIndex: 3 }}>
      <DatePicker
        name={name}
        minDate={minDate}
        selected={selected}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
      />
      <span className="error">{error}</span>
    </div>
  );
}
