import { useState, useEffect, useContext } from "react";
import { getPointsAdded } from "./helper/VouchersHelper";

import {
  Paper,
  Toolbar,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Controls from "../components/controls/Controls";
import useTable from "../components/useTable";
import { AuthContext } from "../AppContext";
import { useForm } from "../components/useForm";
import "../styles/global.css";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export default function PointsAdded() {
  const { state, dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const headCells = [
    { id: "nofaktur", label: "Nomor Faktur", disableSorting: true },
    { id: "toko", label: "Toko", disableSorting: true },
    { id: "customeremail", label: "Email Customer" },
    { id: "customerphone", label: "Handphone Customer", disableSorting: true },
    { id: "customerid", label: "ID Customer", disableSorting: true },
    { id: "date", label: "Tanggal Klaim", disableSorting: true },
  ];
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    search: "",
    date: "",
    toko: "",
  });

  const [filtered, setFiltered] = useState([]);

  // console.log(filteringContent)
  // console.log(filtered)

  function handleFilter() {
    let filteredDisplay = state.pointsAdded;

    if (filteringContent.search) {
      // console.log("search filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        return item.nofaktur.includes(filteringContent.search);
      });
    }

    if (filteringContent.date) {
      // console.log("date filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (!filteringContent.date) {
          return true;
        }
        const date = new Date(item.date);
        const dateMapped = `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}`;
        return dateMapped === filteringContent.date;
      });
    }

    if (filteringContent.toko) {
      // console.log("toko filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (filteringContent.toko === "-") {
          return true;
        }
        return item.toko === filteringContent.toko;
      });
    }

    setFiltered(filteredDisplay);
  }

  const initialValues = {
    images: [],
    code: "",
    title: "",
    description: "",
    type: "-",
    sku: "",
    startdate: null,
    enddate: null,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("enddate" in fieldValues) {
      temp.enddate = fieldValues.enddate ? "" : "Start Date shouldn't be empty";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange, handleDatePicker } =
    useForm(initialValues, true, validate);

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    handleFilter();
  }, [filteringContent]); //eslint-disable-line

  useEffect(() => {
    getPointsAdded(dispatch, setNotify);
  }, []); //eslint-disable-line

  return (
    <div className="complete-product">
      <h1>Points Added List</h1>
      <Paper elevation={2}>
        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              onChange={(e) => {
                setFilteringContent({
                  ...filteringContent,
                  search: e.target.value,
                });
              }}
              placeholder="CARI NO FAKTUR"
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Select date: " />
            <Controls.DatePicker
              name="enddate"
              minDate={values.startdate}
              selected={values.enddate}
              onChange={(date) => {
                handleDatePicker("enddate", date);

                setFilteringContent({
                  ...filteringContent,
                  date: date
                    ? `${date.getDate()}-${
                        date.getMonth() + 1
                      }-${date.getFullYear()}`
                    : "",
                });
              }}
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={values.toko}
              onChange={(e) => {
                // console.log(e)
                handleInputChange(e);

                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value,
                });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
            />
          </Toolbar>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => {
              const date = new Date(item.date);
              const dateFormatted = `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}`;

              return (
                <TableRow key={item.sku} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{item.nofaktur}</TableCell>
                  <TableCell>{item.toko}</TableCell>
                  <TableCell>{item.customeremail}</TableCell>
                  <TableCell>{item.customerphone}</TableCell>
                  <TableCell>{item.customerid}</TableCell>
                  <TableCell>{dateFormatted}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}
