import { createContext } from "react";

const localData = JSON.parse(localStorage.getItem("notouser"));

export const initialState = !localData
  ? {
      isAuthenticated: false,
      id: "",
      username: "",
      email: "",
      role: "",
      accessToken: "",
      msg: "",
      onlineSalesList: [],
      completeProductList: [],
      incompleteProductList: [],
      archivedProductList: [],
      brandList: [],
      colorList: [],
      departmentList: [],
      categoryList: [],
      promotedProductList: [],
      supplierList: [],
      bannerList: [],
      promotionList: [],
      vouchersClaimed: [],
      pointsAdded: [],
      listKasir:[]
    }
  : {
      isAuthenticated: true,
      id: localData.id,
      username: localData.username,
      email: localData.email,
      role: localData.role,
      accessToken: localData.accessToken,
      msg: localData.msg,
      onlineSalesList: localData.onlineSalesList,
      completeProductList: localData.completeProductList,
      incompleteProductList: localData.incompleteProductList,
      archivedProductList: localData.archivedProductList,
      brandList: localData.brandList,
      colorList: localData.colorList,
      departmentList: localData.departmentList,
      categoryList: localData.categoryList,
      promotedProductList: localData.promotedProductList,
      supplierList: localData.supplierList,
      bannerList: localData.bannerList,
      promotionList: localData.promotionList,
      vouchersClaimed: localData.vouchersClaimed,
      pointsAdded: localData.pointsAdded,
      listKasir: localData.listKasir
    };

export const reducer = (state, action) => {
    switch(action.type) {
        case "LOGIN":
            localStorage.setItem(
              "notouser",
              JSON.stringify({ ...state, ...action.payload.user })
            );
            return {
              ...state,
              isAuthenticated: true,
              ...action.payload.user,
            };
        case "LOGOUT":
            localStorage.clear();
            return {
            ...state,
            isAuthenticated: false,
            ...action.payload.user,
        };
        case "UPDATE_LIST":
          localStorage.setItem(
            "notouser",
            JSON.stringify({ ...state, ...action.payload })
          );
          return {
            ...state,
            ...action.payload
          };

        default:
            return state;
    }
}

export const AuthContext = createContext();