import { useEffect, useRef, useState, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { baseUrl } from "../../config/const";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../AppContext";
import { useHistory } from "react-router-dom";

const getRandomInt = () => {
  return Math.floor(Math.random() * 10000);
};

const handleFormikNestedFieldError = (formik, field, subField, index) => {
  return (
    Boolean(Object.keys(formik.touched).includes(field)) &&
    Boolean(Object.keys(formik.errors).includes(field)) &&
    formik.errors[field][index] &&
    Boolean(formik.errors[field][index][subField])
  );
};

const handleFormikNestedFieldHelperText = (formik, field, subField, index) => {
  return (
    Boolean(Object.keys(formik.touched).includes(field)) &&
    Boolean(Object.keys(formik.errors).includes(field)) &&
    formik.errors[field][index] &&
    formik.errors[field][index][subField]
  );
};

const Insert = () => {
  const { state } = useContext(AuthContext);
  const [listToko, setListToko] = useState([]);

  const history = useHistory();

  const getListKasir = async () => {
    try {
      const { data: dataKasir } = await axios.get(
        `${baseUrl}/getPoinPenjualan`
      );

      setListToko(dataKasir.listKasir);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getListKasir();
  }, []);

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Insert and Update KPI
      </Typography>
      <BonusIndex listToko={listToko} />
      <StoreTarget listToko={listToko} />
      <Suggestions />
    </>
  );
};

const Suggestions = () => {
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [openModalForm, toggleModalForm] = useState(false);
  const [edit, setEdit] = useState({ isEditing: false, id: "" });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const formSchema = yup.object({
    type: yup.string().required("type harus diisi"),
    suggestions: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        score: yup.number().required("score harus diisi"),
        suggestion: yup.string().required("suggestion harus diisi"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      type: "omzet",
      suggestions: [{ id: String(getRandomInt()), score: 100, suggestion: "" }],
    },
    validationSchema: formSchema,
    onSubmit: () => {
      if (edit.isEditing) {
        updateSuggestion();
      } else {
        insertSuggestion();
      }
    },
  });

  const handleToggleModalForm = () => {
    toggleModalForm(true);
  };

  const handleCloseModalForm = () => {
    toggleModalForm(false);

    setTimeout(() => {
      if (edit.isEditing) {
        setEdit({ isEditing: false, id: "" });
      }
      resetForm();
    }, 500);
  };

  const insertSuggestion = async () => {
    try {
      await axios.post(`${baseUrl}/insertSuggestion`, {
        type: formik.values.type,
        poinsaran: formik.values.suggestions,
      });

      await getAllSuggestions();

      handleCloseModalForm();
    } catch (err) {
      console.error(err);
    }
  };

  const updateSuggestion = async () => {
    try {
      await axios.put(`${baseUrl}/updateSuggestion/${edit.id}`, {
        type: formik.values.type,
        poinsaran: formik.values.suggestions,
      });

      await getAllSuggestions();

      handleCloseModalForm();

      setEdit({ isEditing: false, id: "" });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteSuggestion = async (id) => {
    try {
      await axios.delete(`${baseUrl}/deleteSuggestion/${id}`);

      await getAllSuggestions();

      setEdit({ isEditing: false, id: "" });
      setDeleteDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const addSuggestionWithinModalForm = () => {
    const nextKey =
      Number(
        formik.values.suggestions[formik.values.suggestions.length - 1]
          .percentage
      ) + 10;

    formik.setFieldValue("suggestions", [
      ...formik.values.suggestions,
      {
        id: String(getRandomInt()),
        score: nextKey ? nextKey : 100,
        suggestion: "",
      },
    ]);
  };

  const deleteSuggestionWithinModalForm = (suggestionId) => {
    const deletedIndex = formik.values.suggestions.findIndex(
      ({ id }) => id === suggestionId
    );

    const _suggestions = formik.values.suggestions;

    _suggestions.splice(deletedIndex, 1);

    formik.setFieldValue("suggestions", _suggestions);
  };

  const getAllSuggestions = async () => {
    try {
      const {
        data: {
          data: { suggestions },
        },
      } = await axios.get(`${baseUrl}/getAllSuggestions`);

      console.log(suggestions);

      setAllSuggestions(suggestions);
    } catch (err) {
      console.error(err);
    }
  };

  const getSpecificSuggestions = async (datagridParams) => {
    try {
      const {
        data: {
          data: { suggestions },
        },
      } = await axios.get(
        `${baseUrl}/getSpecificSuggestions/${datagridParams.id}`
      );

      const _suggestions = suggestions.map(({ score, suggestion }) => ({
        id: getRandomInt(),
        score: Number(score),
        suggestion,
      }));

      formik.setValues({
        type: datagridParams.type,
        suggestions: _suggestions,
      });

      formik.setTouched(
        {
          type: true,
          suggestions: _suggestions.map(() => ({
            id: true,
            score: true,
            suggestion: true,
          })),
        },
        true
      );

      handleToggleModalForm(datagridParams);
    } catch (err) {
      console.error(err);
    }
  };

  const resetForm = async () => {
    formik.resetForm();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                setEdit({ isEditing: true, id: params.row.id });
                getSpecificSuggestions(params.row);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setDeleteDialog(true);
                setEdit({ isEditing: true, id: params.row.id });
              }}
            >
              Delete
            </Button>

            <Dialog
              open={
                deleteDialog &&
                (edit.isEditing ? edit.id === params.row.id : false)
              }
              onClose={() => {
                setDeleteDialog(false);
                setEdit({ isEditing: false, id: "" });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Hapus suggestion"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Apakah anda ingin menghapus data suggestion ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deleteSuggestion(params.row.id);
                  }}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getAllSuggestions();
  }, []);

  return (
    <div style={{ height: 400, marginBottom: 100, width: "100%" }}>
      <Box style={{ textAlign: "left" }}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Suggestions
        </Typography>
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleToggleModalForm}
          style={{ marginBottom: 10 }}
        >
          Insert New Suggestions
        </Button>
      </Box>
      <DataGrid
        rows={allSuggestions}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
      <Dialog
        open={openModalForm}
        onClose={handleCloseModalForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {edit.isEditing ? "Edit" : "Tambahkan"} Suggestion
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}

            <FormControl component="fieldset">
              <FormLabel component="legend">Suggestion Type</FormLabel>
              <RadioGroup
                aria-label="type"
                name="type"
                id="type"
                value={formik.values.type}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="omzet"
                  control={<Radio />}
                  label="Omzet"
                  style={{ flexDirection: "row", display: "flex" }}
                />
                <FormControlLabel
                  value="pollux"
                  control={<Radio />}
                  label="Pollux"
                  style={{ flexDirection: "row", display: "flex" }}
                />
              </RadioGroup>
            </FormControl>

            {formik.values.suggestions.map(
              ({ score, suggestion, id }, index) => {
                return (
                  <Box style={{ display: "flex" }}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="score"
                      label="Score"
                      name={`suggestions[${index}].score`}
                      type="number"
                      fullWidth
                      value={score}
                      style={{ marginRight: 10, flex: 1 }}
                      onChange={formik.handleChange}
                      error={handleFormikNestedFieldError(
                        formik,
                        "suggestions",
                        "score",
                        index
                      )}
                      helperText={handleFormikNestedFieldHelperText(
                        formik,
                        "suggestions",
                        "score",
                        index
                      )}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="suggestion"
                      label="Suggestion"
                      name={`suggestions[${index}].suggestion`}
                      type="text"
                      multiline
                      fullWidth
                      value={suggestion}
                      onChange={formik.handleChange}
                      error={handleFormikNestedFieldError(
                        formik,
                        "suggestions",
                        "suggestion",
                        index
                      )}
                      helperText={handleFormikNestedFieldHelperText(
                        formik,
                        "suggestions",
                        "suggestion",
                        index
                      )}
                      style={{ flex: 4 }}
                    />
                    {formik.values.suggestions.length !== 1 && (
                      <IconButton
                        aria-label="delete suggestion"
                        style={{ padding: 0 }}
                        onClick={() => {
                          deleteSuggestionWithinModalForm(score);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Box>
                );
              }
            )}
            <Button
              onClick={() => {
                addSuggestionWithinModalForm();
              }}
              color="primary"
            >
              Tambah Suggestion
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalForm} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {edit.isEditing ? "Update" : "Insert"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const StoreTarget = ({ listToko }) => {
  const [allStoreTarget, setAllStoreTarget] = useState([]);
  const [openModalForm, toggleModalForm] = useState(false);
  const [edit, setEdit] = useState({ isEditing: false, id: "" });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const formSchema = yup.object({
    toko: yup.string().required("toko harus diisi"),
    period: yup.date().required("period harus diisi"),
    omzet: yup.number().required("omzet harus diisi"),
    pollux: yup.number().required("pollux harus diisi"),
    wanita: yup.number().required("wanita harus diisi"),
    pria: yup.number().required("pria harus diisi"),
    anak: yup.number().required("anak harus diisi"),
    sports: yup.number().required("sports harus diisi"),
    poinpenjualan: yup.number().required("poin penjualan harus diisi"),
    newmember: yup.number().required("new member harus diisi"),
  });

  const formik = useFormik({
    initialValues: {
      toko: "PA2M",
      type: "omzet",
      period: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
        .toISOString()
        .split("T")[0],
      omzet: 0,
      pollux: 0,
      wanita: 0,
      anak: 0,
      pria: 0,
      sports: 0,
      poinpenjualan: 0,
      newmember: 0,
    },
    validationSchema: formSchema,
    onSubmit: () => {
      if (edit.isEditing) {
        updateStoreTarget();
      } else {
        insertStoreTarget();
      }
    },
  });

  const handleToggleModalForm = () => {
    toggleModalForm(true);
  };

  const handleCloseModalForm = () => {
    toggleModalForm(false);

    setTimeout(() => {
      if (edit.isEditing) {
        setEdit({ isEditing: false, id: "" });
      }
      resetForm();
    }, 500);
  };

  const insertStoreTarget = async () => {
    try {
      await axios.post(`${baseUrl}/insertStoreTarget`, {
        ...formik.values,
      });

      await getAllStoreTarget();

      handleCloseModalForm();
    } catch (err) {
      console.error(err);
    }
  };

  const updateStoreTarget = async () => {
    try {
      await axios.put(`${baseUrl}/updateStoreTarget/${edit.id}`, {
        ...formik.values,
      });

      await getAllStoreTarget();

      handleCloseModalForm();

      setEdit({ isEditing: false, id: "" });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteStoreTarget = async (id) => {
    try {
      await axios.delete(`${baseUrl}/deleteStoreTarget/${id}`);

      await getAllStoreTarget();

      setEdit({ isEditing: false, id: "" });
      setDeleteDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllStoreTarget = async () => {
    try {
      const {
        data: {
          data: { storetarget },
        },
      } = await axios.get(`${baseUrl}/getAllStoreTarget`);

      setAllStoreTarget(storetarget);
    } catch (err) {
      console.error(err);
    }
  };

  const resetForm = async () => {
    formik.resetForm();
  };

  const getSpecificStoreTarget = async (id) => {
    try {
      const {
        data: {
          data: { storetarget },
        },
      } = await axios.get(`${baseUrl}/getSpecificStoreTarget/${id}`);

      delete storetarget[0].id;

      formik.setValues({
        ...storetarget[0],
        period: storetarget[0].period.split("T")[0],
      });

      formik.setTouched(
        {
          toko: true,
          type: true,
          period: true,
          omzet: true,
          pollux: true,
          wanita: true,
          anak: true,
          pria: true,
          sports: true,
          poinpenjualan: true,
          newmember: true,
        },
        true
      );

      handleToggleModalForm();
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "period",
      headerName: "Period",
      width: 200,
      valueGetter: (params) => {
        return params.row.period.split("T")[0];
      },
    },
    {
      field: "toko",
      headerName: "Toko",
      width: 150,
    },
    {
      field: "omzet",
      headerName: "Omzet",
      width: 150,
    },
    {
      field: "pollux",
      headerName: "Pollux",
      width: 150,
    },
    {
      field: "wanita",
      headerName: "Wanita",
      width: 150,
    },
    {
      field: "pria",
      headerName: "Pria",
      width: 150,
    },
    {
      field: "anak",
      headerName: "Anak",
      width: 150,
    },
    {
      field: "sports",
      headerName: "Sports",
      width: 150,
    },
    {
      field: "poinpenjualan",
      headerName: "Poin Penjualan",
      width: 180,
    },
    {
      field: "newmember",
      headerName: "New Member",
      width: 180,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                getSpecificStoreTarget(params.row.id);
                setEdit({ isEditing: true, id: params.row.id });
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setDeleteDialog(true);
                setEdit({ isEditing: true, id: params.row.id });
              }}
            >
              Delete
            </Button>

            <Dialog
              open={
                deleteDialog &&
                (edit.isEditing ? edit.id === params.row.id : false)
              }
              onClose={() => {
                setDeleteDialog(false);
                setEdit({ isEditing: false, id: "" });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Hapus store target"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Apakah anda ingin menghapus data store target ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deleteStoreTarget(params.row.id);
                  }}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getAllStoreTarget();
  }, []);

  return (
    <div style={{ height: 400, marginBottom: 100, width: "100%" }}>
      <Box style={{ textAlign: "left" }}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Store Target
        </Typography>
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleToggleModalForm}
          style={{ marginBottom: 10 }}
        >
          Insert New Store Target
        </Button>
      </Box>
      <DataGrid
        rows={allStoreTarget}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
      <Dialog
        open={openModalForm}
        onClose={handleCloseModalForm}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {edit.isEditing ? "Edit" : "Tambahkan"} Store Target
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}

            <FormControl fullWidth>
              <InputLabel id="select-toko-label">Toko</InputLabel>
              <Select
                labelId="toko-label"
                id="toko"
                value={formik.values.toko}
                name="toko"
                onChange={formik.handleChange}
              >
                {listToko.map((toko) => (
                  <MenuItem key={toko.username} value={toko.username}>
                    {toko.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="period"
              label="Period"
              name="period"
              type="date"
              fullWidth
              value={formik.values.period}
              style={{ marginRight: 10, flex: 1 }}
              onChange={formik.handleChange}
              error={formik.touched.period && Boolean(formik.errors.period)}
              helperText={formik.touched.period && formik.errors.period}
            />
            <TextField
              autoFocus
              margin="dense"
              id="omzet"
              label="Omzet"
              name="omzet"
              type="number"
              fullWidth
              value={formik.values.omzet}
              onChange={formik.handleChange}
              error={formik.touched.omzet && Boolean(formik.errors.omzet)}
              helperText={formik.touched.omzet && formik.errors.omzet}
            />

            <TextField
              autoFocus
              margin="dense"
              id="pollux"
              label="Pollux"
              name="pollux"
              type="number"
              fullWidth
              value={formik.values.pollux}
              onChange={formik.handleChange}
              error={formik.touched.pollux && Boolean(formik.errors.pollux)}
              helperText={formik.touched.pollux && formik.errors.pollux}
            />

            <TextField
              autoFocus
              margin="dense"
              id="wanita"
              name="wanita"
              label="Wanita"
              type="number"
              fullWidth
              value={formik.values.wanita}
              onChange={formik.handleChange}
              error={formik.touched.wanita && Boolean(formik.errors.wanita)}
              helperText={formik.touched.wanita && formik.errors.wanita}
            />

            <TextField
              autoFocus
              margin="dense"
              id="pria"
              name="pria"
              label="Pria"
              type="number"
              fullWidth
              value={formik.values.pria}
              onChange={formik.handleChange}
              error={formik.touched.pria && Boolean(formik.errors.pria)}
              helperText={formik.touched.pria && formik.errors.pria}
            />

            <TextField
              autoFocus
              margin="dense"
              id="anak"
              name="anak"
              label="Anak"
              type="number"
              fullWidth
              value={formik.values.anak}
              onChange={formik.handleChange}
              error={formik.touched.anak && Boolean(formik.errors.anak)}
              helperText={formik.touched.anak && formik.errors.anak}
            />

            <TextField
              autoFocus
              margin="dense"
              id="sports"
              label="Sports"
              name="sports"
              type="number"
              fullWidth
              value={formik.values.sports}
              onChange={formik.handleChange}
              error={formik.touched.sports && Boolean(formik.errors.sports)}
              helperText={formik.touched.sports && formik.errors.sports}
            />

            <TextField
              autoFocus
              margin="dense"
              id="poinpenjualan"
              name="poinpenjualan"
              label="Poin Penjualan"
              type="number"
              fullWidth
              value={formik.values.poinpenjualan}
              onChange={formik.handleChange}
              error={
                formik.touched.poinpenjualan &&
                Boolean(formik.errors.poinpenjualan)
              }
              helperText={
                formik.touched.poinpenjualan && formik.errors.poinpenjualan
              }
            />

            <TextField
              autoFocus
              margin="dense"
              id="newmember"
              name="newmember"
              label="New Member"
              type="number"
              fullWidth
              value={formik.values.newmember}
              onChange={formik.handleChange}
              error={
                formik.touched.newmember && Boolean(formik.errors.newmember)
              }
              helperText={formik.touched.newmember && formik.errors.newmember}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalForm} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {edit.isEditing ? "Update" : "Insert"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const BonusIndex = ({ listToko }) => {
  const [allStoreMonthlyBonuses, setAllStoreMonthlyBonuses] = useState([]);
  const [openModalForm, toggleModalForm] = useState(false);
  const [edit, setEdit] = useState({ isEditing: false, id: "" });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const formSchema = yup.object({
    toko: yup.string().required("toko harus diisi"),
    type: yup.string().required("type harus diisi"),
    period: yup.date().required("period harus diisi"),
    bonuses: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        percentage: yup.number().required("percentage harus diisi"),
        bonus: yup.string().required("bonus harus diisi"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      toko: "PA2M",
      type: "omzet",
      period: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
        .toISOString()
        .split("T")[0],
      bonuses: [{ id: String(getRandomInt()), percentage: 100, bonus: 0 }],
    },
    validationSchema: formSchema,
    onSubmit: () => {
      if (edit.isEditing) {
        updateBonusIndex();
      } else {
        insertBonusIndex();
      }
    },
  });

  const handleToggleModalForm = () => {
    toggleModalForm(true);
  };

  const handleCloseModalForm = () => {
    toggleModalForm(false);

    setTimeout(() => {
      if (edit.isEditing) {
        setEdit({ isEditing: false, id: "" });
      }
      resetForm();
    }, 500);
  };

  const insertBonusIndex = async () => {
    try {
      await axios.post(`${baseUrl}/insertStoreMonthlyBonus`, {
        ...formik.values,
      });

      await getAllStoreMonthlyBonuses();

      handleCloseModalForm();
    } catch (err) {
      console.error(err);
    }
  };

  const updateBonusIndex = async () => {
    try {
      await axios.put(`${baseUrl}/updateStoreMonthlyBonus/${edit.id}`, {
        ...formik.values,
      });

      await getAllStoreMonthlyBonuses();

      handleCloseModalForm();

      setEdit({ isEditing: false, id: "" });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteBonusIndex = async (id) => {
    try {
      await axios.delete(`${baseUrl}/deleteStoreMonthlyBonus/${id}`);

      await getAllStoreMonthlyBonuses();

      setEdit({ isEditing: false, id: "" });
      setDeleteDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const addBonusWithinModalForm = () => {
    const nextKey =
      Number(
        formik.values.bonuses[formik.values.bonuses.length - 1].percentage
      ) + 10;

    formik.setValues({
      ...formik.values,
      bonuses: [
        ...formik.values.bonuses,
        {
          id: String(getRandomInt()),
          percentage: nextKey ? nextKey : 100,
          bonus: 0,
        },
      ],
    });
  };

  const deleteBonusWithinModalForm = (bonusId) => {
    const deletedIndex = formik.values.bonuses.findIndex(
      ({ id }) => id === bonusId
    );

    let _bonuses = formik.values.bonuses;

    _bonuses.splice(deletedIndex, 1);

    formik.setFieldValue("bonuses", _bonuses);
  };

  const getAllStoreMonthlyBonuses = async () => {
    try {
      const {
        data: {
          data: { bonuses },
        },
      } = await axios.get(`${baseUrl}/getAllStoreMonthlyBonuses`);

      setAllStoreMonthlyBonuses(bonuses);
    } catch (err) {
      console.error(err);
    }
  };

  const getSpecificStoreMonthlyBonus = async (datagridParams) => {
    try {
      const {
        data: {
          data: { bonuses },
        },
      } = await axios.get(
        `${baseUrl}/getSpecificMonthlyBonus/${datagridParams.id}`
      );

      const _bonuses = bonuses.map(({ percentage, bonus }) => ({
        id: getRandomInt(),
        percentage: Number(percentage),
        bonus: Number(bonus),
      }));

      formik.setValues({
        toko: datagridParams.toko,
        period: datagridParams.period.split("T")[0],
        type: datagridParams.type,
        bonuses: _bonuses,
      });

      formik.setTouched(
        {
          toko: true,
          type: true,
          period: true,
          bonuses: _bonuses.map(() => ({
            id: true,
            percentage: true,
            bonuses: true,
          })),
        },
        true
      );

      handleToggleModalForm(datagridParams);
    } catch (err) {
      console.error(err);
    }
  };

  const resetForm = async () => {
    formik.resetForm();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
    },
    {
      field: "toko",
      headerName: "Toko",
      width: 150,
    },
    {
      field: "period",
      headerName: "Period",
      width: 150,
      valueGetter: (params) => `${params.row.period.split("T")[0]}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                setEdit({ isEditing: true, id: params.row.id });
                getSpecificStoreMonthlyBonus(params.row);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setDeleteDialog(true);
                setEdit({ isEditing: true, id: params.row.id });
              }}
            >
              Delete
            </Button>

            <Dialog
              open={
                deleteDialog &&
                (edit.isEditing ? edit.id === params.row.id : false)
              }
              onClose={() => {
                setDeleteDialog(false);
                setEdit({ isEditing: false, id: "" });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Hapus bonus index"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Apakah anda ingin menghapus data bonus index ini?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deleteBonusIndex(params.row.id);
                  }}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getAllStoreMonthlyBonuses();
  }, []);

  return (
    <div style={{ height: 400, marginBottom: 100, width: "100%" }}>
      <Box style={{ textAlign: "left" }}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Bonus Index
        </Typography>
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleToggleModalForm}
          style={{ marginBottom: 10 }}
        >
          Insert New Bonus
        </Button>
      </Box>
      <DataGrid
        rows={allStoreMonthlyBonuses}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
      <Dialog
        open={openModalForm}
        onClose={handleCloseModalForm}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {edit.isEditing ? "Edit" : "Tambahkan"} Bonus Index
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}

            <FormControl component="fieldset">
              <FormLabel component="legend">Bonus Type</FormLabel>
              <RadioGroup
                aria-label="bonus-type"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="omzet"
                  control={<Radio />}
                  label="Omzet"
                  style={{ flexDirection: "row", display: "flex" }}
                />
                <FormControlLabel
                  value="pollux"
                  control={<Radio />}
                  label="Pollux"
                  style={{ flexDirection: "row", display: "flex" }}
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-toko-label">Toko</InputLabel>
              <Select
                labelId="toko-select"
                id="toko"
                value={formik.values.toko}
                name="toko"
                onChange={formik.handleChange}
              >
                {listToko.map((toko) => (
                  <MenuItem key={toko.username} value={toko.username}>
                    {toko.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              id="period"
              label="Period"
              name="period"
              type="date"
              fullWidth
              value={formik.values.period}
              style={{ marginRight: 10, flex: 1 }}
              onChange={formik.handleChange}
            />

            {formik.values.bonuses.map(({ percentage, bonus, id }, index) => {
              return (
                <Box style={{ display: "flex" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="percentage"
                    label="Percentage"
                    type="number"
                    name={`bonuses[${index}].percentage`}
                    fullWidth
                    value={percentage}
                    style={{ marginRight: 10, flex: 1 }}
                    onChange={formik.handleChange}
                    error={handleFormikNestedFieldError(
                      formik,
                      "bonuses",
                      "percentage",
                      index
                    )}
                    helperText={handleFormikNestedFieldHelperText(
                      formik,
                      "bonuses",
                      "percentage",
                      index
                    )}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="bonus"
                    label="Bonus"
                    type="number"
                    name={`bonuses[${index}].bonus`}
                    fullWidth
                    value={bonus}
                    onChange={formik.handleChange}
                    style={{ flex: 4 }}
                    error={handleFormikNestedFieldError(
                      formik,
                      "bonuses",
                      "bonus",
                      index
                    )}
                    helperText={handleFormikNestedFieldHelperText(
                      formik,
                      "bonuses",
                      "bonus",
                      index
                    )}
                  />
                  {formik.values.bonuses.length !== 1 && (
                    <IconButton
                      aria-label="delete bonus"
                      style={{ padding: 0 }}
                      onClick={() => {
                        deleteBonusWithinModalForm(percentage);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              );
            })}
            <Button
              onClick={() => {
                addBonusWithinModalForm();
              }}
              color="primary"
            >
              Tambah Bonus
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalForm} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {edit.isEditing ? "Update" : "Insert"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Insert;
