import React, { useState } from "react";
import axios from "axios";
import authHeader from "./auth.header";
import { baseUrl } from "../config/const";

class AuthService {
  async getCurrentUser() {
    const user = axios.get(`${baseUrl}/verifyUser`);

    const data = (await user).data;
    return {
      username: data.user[0].username,
      role: data.user[0].role,
      email: data.user[0].email,
      accessToken: data.user[0].accessToken,
    };
  }
}

export default new AuthService();
