import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./controls.css";

export default function DateRangePicker({
  name,
  error = null,
  setDates,
  dateRanges,
  chartPeriod,
}) {
  const startDate = new Date(dateRanges[0].getTime());
  const endDate = new Date(startDate.getTime());
  endDate.setDate(startDate.getDate() + 6);

  useEffect(() => {
    if (chartPeriod === "weekly") {
      setDates([startDate, endDate]);
    }
  }, []); //eslint-disable-line

  return (
    <div style={{ zIndex: 10 }}>
      <DatePicker
        name={name}
        dateFormat="dd/MM/yyyy"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          update[1] = new Date(update[0].getTime());
          update[1].setDate(update[1].getDate() - 6);

          setDates([update[1], update[0]]);
        }}
      />
      <span className="error">{error}</span>
    </div>
  );
}
