/**
 *
 * @param {*} date JS Date Object
 */
function createPeriods(firstDate, numberOfWeeks, remainderDays) {
  const _periodsGroupedData = [];
  for (let i = 0; i < numberOfWeeks + 1; i++) {
    let startDate = "";
    let endDate = "";

    if (i > 0) {
      firstDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        firstDate.getDate() + 1
      );
    }

    startDate = firstDate;

    // for remainder
    if (i === numberOfWeeks) {
      if (remainderDays > 0) {
        endDate = new Date(
          firstDate.getFullYear(),
          firstDate.getMonth(),
          firstDate.getDate() + (remainderDays > 1 ? remainderDays - 1 : 0)
        );
      } else {
        continue;
      }
    } else {
      endDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        firstDate.getDate() + 6
      );
    }

    firstDate = endDate;

    _periodsGroupedData.push({
      data: [],
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });
  }

  return _periodsGroupedData;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default createPeriods;
