import { useContext, useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../config/const";
import { DataGrid } from "@mui/x-data-grid";
import Controls from "../components/controls/Controls";
import { AuthContext } from "../AppContext";

const RoutesManagement = () => {
  const { state } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [addRouteModal, setAddRouteModal] = useState(false);
  const [revokeRouteModal, setRevokeRouteModal] = useState(false);
  const [userRoutes, setUserRoutes] = useState([]);
  const [routeForm, setRouteForm] = useState({
    role: "",
    route: {
      id: "",
      name: "",
      path: "",
    },
  });

  const handleAddRouteModal = () => {
    setAddRouteModal(!addRouteModal);

    if (!addRouteModal === false) {
      setRouteForm({
        role: "",
        route: {
          id: "",
          name: "",
          path: "",
        },
      });
    }
  };

  const handleRevokeRouteModal = () => {
    setRevokeRouteModal(!revokeRouteModal);

    if (!revokeRouteModal === false) {
      setRouteForm({
        role: "",
        route: {
          id: "",
          name: "",
          path: "",
        },
      });
    }
  };

  const getRoles = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/fe-routes/roles`);

      setRoles(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAvailableRoutes = async (role) => {
    try {
      const { data } = await axios.get(`${baseUrl}/fe-routes/available`, {
        params: {
          role,
        },
      });

      setRoutes(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserRoutes = async (role) => {
    try {
      const { data } = await axios.get(`${baseUrl}/fe-routes/routes`, {
        params: {
          role,
        },
      });

      setUserRoutes(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateRoutes = async (route_id) => {
    try {
      await axios.put(`${baseUrl}/fe-routes/${route_id}`, {
        role: routeForm.role,
      });

      getUserRoutes(routeForm.role);
      handleAddRouteModal();
    } catch (err) {
      console.error(err);
    }
  };

  const revokeRoutes = async (route_id) => {
    try {
      await axios.delete(`${baseUrl}/fe-routes/${route_id}`, {
        params: {
          role: routeForm.role,
        },
      });

      getUserRoutes(routeForm.role);
      handleRevokeRouteModal();
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
    },
    {
      headerName: "Route",
      field: "route",
      flex: 1,
    },
    {
      headerName: "Route Name",
      field: "route_name",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "action",
      type: "action",
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setRouteForm({
                role: params.row.role,
                route: {
                  id: params.row.id,
                  name: params.row.route_name,
                  path: params.row.route,
                },
              });
              handleRevokeRouteModal();
            }}
            color="primary"
          >
            Revoke
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getRoles();
    getUserRoutes(state.role);
  }, []);
  return (
    <Box>
      <Typography variant="h4">Routes Management</Typography>
      <Paper style={{ padding: 20 }}>
        <Box style={{ display: "flex" }}>
          <Toolbar style={{ alignItems: "center" }}>
            <Controls.Label label="Pilih role: " />
            <Controls.Select
              defaultValue={state.role}
              noNone={true}
              name="toko"
              onChange={(e) => {
                getUserRoutes(e.target.value);
              }}
              otherStyle={{
                marginBottom: 0,
                marginLeft: 10,
              }}
              options={roles.map(({ id, name }) => {
                return {
                  id: name,
                  name,
                };
              })}
            />
          </Toolbar>
          <Toolbar>
            <Button
              onClick={handleAddRouteModal}
              color="primary"
              variant="outlined"
            >
              Tambah Route
            </Button>
          </Toolbar>
        </Box>
        <DataGrid columns={columns} autoHeight={true} rows={userRoutes} />
      </Paper>

      <Dialog
        PaperProps={{
          style: {
            maxWidth: null,
            width: 1000,
          },
        }}
        open={addRouteModal}
        onClose={handleAddRouteModal}
      >
        <DialogTitle>Tambah Role</DialogTitle>
        <DialogContent>
          <DialogContentText>Tambah Role</DialogContentText>
          <TextField
            margin="dense"
            id="role"
            label="Role"
            fullWidth
            select
            onChange={(e) => {
              setRouteForm({ ...routeForm, role: e.target.value });
              getAvailableRoutes(e.target.value);
            }}
          >
            {roles.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            id="route"
            label="Route"
            fullWidth
            select
            onChange={(e) =>
              setRouteForm({
                ...routeForm,
                route: { ...routeForm.route, id: e.target.value },
              })
            }
          >
            {routes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.route_name} ({option.route})
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddRouteModal}>Cancel</Button>
          <Button
            onClick={() => {
              updateRoutes(routeForm.route.id);
            }}
          >
            Tambah
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            maxWidth: null,
            width: 1000,
          },
        }}
        open={revokeRouteModal}
        onClose={handleRevokeRouteModal}
      >
        <DialogTitle>Revoke Route</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Revoke Route '{routeForm.route.name} ({routeForm.route.path}
            )' dari Role {routeForm.role}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRevokeRouteModal}>Cancel</Button>
          <Button
            onClick={() => {
              revokeRoutes(routeForm.route.id);
            }}
          >
            Revoke
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RoutesManagement;
