import Input from "./input";
import Label from "./label";
import InputFile from "./inputFile";
import TextArea from "./textarea";
import Button from "./button";
import Checkbox from "./checkbox";
import Select from "./select";
import PageHeader from "./pageHeader";
import ActionButton from "./actionButton";
import Popup from "./popup";
import Notification from "./notification";
import ConfirmPopup from "./confirmPopup";
import Checkbox2 from "./checkbox2";
import DatePicker from "./pickDate";
import DateRangePicker from "./pickDateRange";
import DateRangeCustomPicker from "./pickDateRangeCustom";
import MonthPicker from "./pickMonth";
import PopUpNotification from "./popUpNotification";
import Radio from "./radio";

const Controls = {
  Input,
  Label,
  InputFile,
  TextArea,
  Button,
  Checkbox,
  Select,
  PageHeader,
  ActionButton,
  Popup,
  Notification,
  ConfirmPopup,
  Checkbox2,
  DatePicker,
  DateRangePicker,
  DateRangeCustomPicker,
  MonthPicker,
  PopUpNotification,
  Radio,
};

export default Controls;
