import { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Toolbar,
  Box,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableFooter,
  TableCell,
} from "@material-ui/core";
import ReactToPrint from "react-to-print";

import { baseUrl } from "../../../../../config/const";
import Controls from "../../../../../components/controls/Controls";
import useTable from "../../../../../components/useTable";

function formatToIndonesianCurrency(amount) {
  // Ensure the input is an integer
  if (!Number.isInteger(amount)) {
    throw new Error("Input must be an integer");
  }

  // Use Intl.NumberFormat to format the number as Indonesian Rupiah
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  // Format the amount and remove the "Rp" prefix
  let formattedAmount = formatter.format(amount);

  return formattedAmount;
}

const GajianPabrik = () => {
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const [gajianPabrik, setGajianPabrik] = useState([]);
  const [totalGaji, setTotalGaji] = useState("0");

  const ref = useRef();

  const getGajianPabrik = async () => {
    try {
      const {
        data: { results, msg, totalGaji },
      } = await axios.get(`${baseUrl}/getGajianHarianPabrik`, {
        params: {
          month: dateRanges[0].getMonth() + 1,
          year: dateRanges[0].getFullYear(),
        },
      });

      setGajianPabrik(
        results.rows.map((item, index) => ({ id: index, ...item }))
      );

      setTotalGaji(formatToIndonesianCurrency(totalGaji));
    } catch (err) {
      console.error(err);
    }
  };

  const gajianHeadCells = [
    {
      id: "username",
      disableSorting: true,
      sortable: true,
      flex: 1.5,
      label: "Nama",
    },
    {
      id: "totalabsen",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Tidak Hadir",
      width: 100,
      type: "number",
    },
    {
      id: "totalpresensi",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Hadir",
      type: "number",
    },
    {
      id: "totaltidaktercatat",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Tidak Checkin",
      type: "number",
    },
    {
      id: "totalmenitterlambat",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Terlambat (menit)",
      type: "number",
    },
    {
      id: "totalgaji",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Gaji",
      type: "number",
    },
    {
      id: "bank",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Bank",
    },
    {
      id: "bankaccount",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Nomor Akun Bank",
    },
    {
      id: "bankaccountname",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Pemilik Akun Bank",
    },
  ];

  const {
    TblHead: PsTblHead,
    TblContainer: PsTblContainer,
    styledTableCell: PsTableCell,
    recordsAfterPagingAndSorting: rsRecordsAfterPagingAndSorting,
  } = useTable(
    gajianPabrik,
    gajianHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getGajianPabrik();
    }
  }, [dateRanges]);

  return (
    <>
      <Box>
        <Typography variant="h4">Gajian Pabrik</Typography>
        <Box style={{ marginBottom: 10 }} />
        <Paper style={{ padding: 10 }} elevation={2}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box style={{ display: "flex", width: "100%" }}>
              <Toolbar style={{ padding: 0 }}>
                <Controls.Label label="Select month: " />
                <Controls.MonthPicker
                  name="month"
                  setDates={setDateRanges}
                  chartPeriod={"monthly"}
                  dateRanges={dateRanges}
                  disableFullMonth={true}
                />
              </Toolbar>

              <Toolbar style={{ marginLeft: "auto", paddingRight: 0 }}>
                <Box>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outlined" color="primary">
                        Print
                      </Button>
                    )}
                    content={() => ref.current}
                    documentTitle={`pabrik_${
                      dateRanges[0]
                        ? dateRanges[0].toLocaleDateString("en-CA")
                        : ""
                    } - ${
                      dateRanges[1]
                        ? dateRanges[1].toLocaleDateString("en-CA")
                        : ""
                    }`}
                  />
                </Box>
              </Toolbar>
            </Box>
          </Box>
          <Box style={{ marginBottom: 10 }} />
          <div ref={ref}>
            <Paper style={{ padding: 5 }}>
              <Box style={{ height: 10 }} />
              <PsTblContainer>
                <PsTblHead />
                <TableBody>
                  {rsRecordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                    >
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.username}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.totalabsen}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.totalpresensi}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.totaltidaktercatat}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.totalmenitterlambat}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.totalgaji}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.bank}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.bankaccount}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.bankaccountname}
                      </PsTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </PsTblContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "1em",
                }}
              >
                <span style={{ fontSize: 30 }}>Total: {totalGaji} </span>
              </div>
            </Paper>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default GajianPabrik;
