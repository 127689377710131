import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./controls.css";

export default function DateRangeCustomPicker({
  name,
  error = null,
  setDates,
  dateRanges,
}) {
  return (
    <div style={{ zIndex: 10 }}>
      <DatePicker
        name={name}
        dateFormat="dd/MM/yyyy"
        selectsRange={true}
        startDate={dateRanges[0]}
        endDate={dateRanges[1]}
        onChange={(update) => {
          setDates([update[0], update[1]]);
        }}
      />
      <span className="error">{error}</span>
    </div>
  );
}
