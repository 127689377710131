import React, { useEffect, useState } from "react";
import Controls from "../components/controls/Controls";
import { FormControl, Checkbox as MuiCheckBox } from "@material-ui/core";
import { useForm } from "../components/useForm";
// import '../styles/global.css'
import Axios from "axios";
import { ImageSearchRounded } from "@material-ui/icons";
import { baseUrl } from "../config/const";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import FacebookLogin from "react-facebook-login";

function Dashboard() {
  const [email, setEmail] = useState("");

  // const facebookLogin = async(req,res) => {
  //     await axios({
  //         method :"GET",
  //         url :"/facebook",
  //         headers : {
  //             Authorization : 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJoaGFsYmVydEBsaW9uLmxtdS5lZHUiLCJpYXQiOjE2MzUzMTgwODgsImV4cCI6MTYzNTQwNDQ4OH0.V5o41eE6FuU4NflG2HWXSYAiIMjOykoHfTzUnETTye0'
  //         }
  //         // headers : {
  //         //     Authorization : 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJoaGFsYmVydEBsaW9uLmxtdS5lZHUiLCJpYXQiOjE2MzUzMTgwODgsImV4cCI6MTYzNTQwNDQ4OH0.V5o41eE6FuU4NflG2HWXSYAiIMjOykoHfTzUnETTye0'
  //         // }
  //     })
  // }
  const responseFacebook = (response) => {
    Axios({
      method: "POST",
      url: "/v1/customerauth/facebook",
      data: {
        accessToken: response.accessToken,
        userID: response.userID,
      },
    }).then((response) => {
      console.log(response);
    });
  };

  return (
    <div>
      <h2>Welcome to Happy Shoes portal</h2>
    </div>
  );
}
export default Dashboard;
