import { useRef } from "react";
import { Box, Typography, Paper, Toolbar, Button } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import GajianTable from "./GajianTable";

const GajianSplitRange = ({ dataByPeriods, DateRangePicker }) => {
  const ref = useRef();

  return (
    <Box>
      <Typography variant="h4">Gajian Staff Per Minggu</Typography>
      <Box style={{ marginBottom: 10 }} />
      <Paper style={{ padding: 10 }} elevation={2}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Toolbar style={{ padding: 0 }}>{DateRangePicker}</Toolbar>
            <Box style={{ marginRight: 10 }} />
          </Box>
          <Toolbar style={{ marginRight: 0, paddingRight: 0 }}>
            <Box>
              <ReactToPrint
                trigger={() => (
                  <Button
                    disabled={!dataByPeriods.length}
                    variant="outlined"
                    color="primary"
                  >
                    Print
                  </Button>
                )}
                content={() => ref.current}
                documentTitle={
                  dataByPeriods.length
                    ? `staff_${dataByPeriods[0].startDate} - ${
                        dataByPeriods[dataByPeriods.length - 1].endDate
                      }`
                    : ""
                }
              />
            </Box>
          </Toolbar>
        </Box>
        <Box style={{ marginBottom: 10 }} />
        <div ref={ref}>
          {dataByPeriods
            .filter((p) => p.data.length > 0)
            .map((periodData, index) => (
              <Box
                key={`${periodData.startDate}-${index}`}
                style={{ marginBottom: 40 }}
              >
                <GajianTable periodData={periodData} />
              </Box>
            ))}
          <Box style={{ width: 20 }} />
        </div>
      </Paper>
    </Box>
  );
};

export default GajianSplitRange;
