import React,{useState} from 'react'
import {FormControl, Checkbox as MuiCheckBox} from '@material-ui/core'
import '../controls/controls.css'

export default function Checkbox(props){
    const {name,value,options,onChange,error=null,...other} = props
    return (
        <div style={{border : '1px',borderRadius:'10px',padding:'1px'}}>
            {options.map((option) => (
                <React.Fragment key={option}>
                    <strong>{option}</strong>
                    <MuiCheckBox
                    name={name} 
                    value={value}
                    {...other}
                    // checked={...}
                    // disabled={disabled}
                    // checked={value !== null ? 
                    //     value.indexOf(checked) === -1 ? false : true
                    // :
                    //     checked.indexOf(option) === -1 ? false : true}
                    onChange={(event) => onChange(option,event)} 
                    />                    
                </React.Fragment>
            ))}
            <span className="error">{error}</span>
        </div>
    )
}