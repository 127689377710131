import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Paper, Box, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { baseUrl } from "../../../../config/const";
import { AuthContext } from "../../../../AppContext";
import GajianKetua from "./components/GajianKetua";
import GajianStaff from "./components/GajianStaff";

const GajianToko = () => {
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const [listToko, setListToko] = useState([]);

  const getListKasir = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getListKasir();
  }, []);

  return (
    <Paper style={{ padding: 20 }}>
      <GajianStaff listToko={listToko} />
      <Box style={{ height: 80 }} />
      <Divider />
      <Box style={{ height: 70 }} />
      <GajianKetua listToko={listToko} />
    </Paper>
  );
};

export default GajianToko;
