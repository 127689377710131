import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../components/controls/Controls";
import { Form, useForm } from "../components/useForm";
import { baseUrl } from "../config/const";
import Cookies from "universal-cookie";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { CircularProgress } from "@material-ui/core";

export default function SignIn({ state, dispatch }) {
  const [success, setSuccess] = useState({
    id: "",
    username: "",
    email: "",
    role: "",
    accessToken: "",
    msg: "",
  });
  const [isLoading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(!isLoading);
  };

  const initialValues = {
    username: "",
    password: "",
  };
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("username" in fieldValues) {
      temp.username =
        fieldValues.username.length !== 0 ? "" : "Username harus diisi";
    }
    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length !== 0 ? "" : "Password harus diisi";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const login = async (e) => {
    handleLoading();
    e.preventDefault();
    if (validate()) {
      await axios
        .post(`${baseUrl}/auth/login`, {
          username: values.username,
          password: values.password,
        })
        .then((response) => {
          setSuccess({
            ...success,
            id: response.data.id,
            username: response.data.username,
            email: response.data.email,
            role: response.data.role,
            accessToken: response.data.accessToken,
            msg: response.data.msg,
          });

          dispatch({
            type: "LOGIN",
            payload: {
              user: response.data,
            },
          });
          // localStorage.setItem("notouser",response.data.accessToken)
          console.log("token adalah: " + response.data.accessToken);
          handleLoading();
        })
        .catch((err) => {
          handleLoading();
          // setSuccess({
          //   ...success,
          //   msg: err.response.data.msg,
          // });
        });
    }
  };

  // const handleGoogleLogin = async googleData => {
  //     await axios.post("/v1/officeuserauth/google",{
  //         token : googleData.tokenId
  //     })
  //     .then((response) => {
  //         console.log(response.data)
  //         console.log('google token id: '+googleData.tokenId)
  //         cookies.set('notouser',response.data.accessToken,{path:"/"})
  //         window.location.href="/"
  //     })
  //     .catch((error) => {
  //         console.error(error.response.data)
  //     })
  // }

  // const handleFacebookLogin = async facebookData => {
  //     await axios.post("/v1/officeuserauth/facebook",{
  //         accessToken : facebookData.accessToken,
  //         userID : facebookData.userID
  //     })
  //     .then((response) => {
  //         console.log(response.data)
  //         console.log('facebook token id: '+facebookData.accessToken)
  //         cookies.set('notouser',response.data.accessToken,{path:"/"})
  //         window.location.href="/"
  //     })
  //     .catch((error) => {
  //         console.error(error.response.data)
  //     })
  // }

  return (
    <div style={{ width: "100vw", height: "100vh", border: "5px solid auto" }}>
      <div style={{ width: "50%", margin: "auto", paddingBottom: "10px" }}>
        {/* <GoogleLogin
            clientId="859927390380-0goq104v7osumsnvuhgdhgoauviuksm9.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLogin}
            cookiePolicy={'single_host_origin'} />

            <FacebookLogin
            appId="885608145695944"
            autoLoad={false}
            callback={handleFacebookLogin} /> */}

        <Form onSubmit={login}>
          <Controls.PageHeader
            title="Log In Page"
            subTitle="tempat untuk sign in account"
          />
          <Controls.Label label="Username: " />
          <Controls.Input
            name="username"
            value={values.username}
            onChange={handleInputChange}
            error={errors.username}
          />

          <Controls.Label label="Password: " />
          <Controls.Input
            type="password"
            name="password"
            value={values.password}
            onChange={handleInputChange}
            error={errors.password}
          />

          <Controls.Button
            type="submit"
            disable={isLoading}
            text={
              isLoading ? (
                <CircularProgress color={"secondary"} size={25} />
              ) : (
                "Log In"
              )
            }
          />
        </Form>
        <div>
          <h3>message: {success.msg}</h3>
        </div>
      </div>
    </div>
  );
}
