import React, { useContext, useEffect, useState, Fragment } from "react";
import Controls from "../../components/controls/Controls";
import { useTheme, useMediaQuery, CircularProgress } from "@material-ui/core";
import { Paper, Toolbar } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SalesCharts = ({ dateRanges }) => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [chartData, setChartData] = useState([]);
  const [chartDataAll, setChartDataAll] = useState([]);

  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { state } = useContext(AuthContext);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    toko: "PA2M",
  });

  const getFilteredSales = () => {
    const groupedData = {};
    chartData.forEach((data) => {
      const date = new Date(data.Kltgklr).toLocaleDateString("en-CA");

      if (groupedData[date] === undefined) {
        groupedData[date] = [];
      }

      groupedData[date].push(data);
    });

    const displayed = Object.keys(groupedData).map((data) => {
      return {
        name: data,
        total: groupedData[data].reduce((prev, curr) => {
          if (
            filteringContent.toko
              .toLowerCase()
              .includes(curr.Klgd.toLowerCase())
          ) {
            return prev + curr.Klrp;
          }

          if (filteringContent.toko === "-") {
            return prev + curr.Klrp;
          }
          return prev;
        }, 0),
      };
    });

    const reorderedDisplayed = displayed.sort((a, b) => {
      return new Date(a.name).getTime() - new Date(b.name).getTime();
    });

    setDisplayedData([...reorderedDisplayed]);
  };

  const getSalesRecords = async (toko = "") => {
    if (!dateRanges[1]) {
      return;
    }
    setLoading(true);
    if (toko === "-") {
      toko = "";
    }
    try {
      const { data } = await axios.get(
        `${baseUrl}/getPjKel?toko=${toko}&startdate=${dateRanges[0].toLocaleDateString(
          "en-CA"
        )}&enddate=${dateRanges[1].toLocaleDateString("en-CA")}`
      );

      if (!toko) {
        setChartDataAll(data.records);
      } else {
        setChartData(data.records);
      }
      setLoading(false);
    } catch (error) {
      // setNotify({
      //   isOpen: true,
      //   type: "error",
      //   message: "Gagal mengambil data penjualan!",
      //   location: "/sales/analytics",
      // });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chartData.length) {
      getFilteredSales();
    }
    if (filteringContent.toko === "-" && chartDataAll.length) {
      setLoading(false);
    }
  }, [chartData]); //eslint-disable-line

  useEffect(() => {
    if (filteringContent.toko === "-") {
      setLoading(false);
    }

    getSalesRecords(filteringContent.toko);
  }, [dateRanges]); //eslint-disable-line

  useEffect(() => {
    if (filteringContent.toko === "-") {
      setChartData(chartDataAll);
    }
  }, [chartDataAll]);

  useEffect(() => {
    getSalesRecords("-");
  }, []); //eslint-disable-line

  return (
    <div className="online-sales">
      <h1>Grafik Penjualan</h1>
      <Paper elevation={3} style={{ margin: "auto", position: "relative" }}>
        {isLoading && filteringContent.toko === "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} />
            </div>
          </Fragment>
        ) : null}
        {isLoading && filteringContent.toko !== "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}
        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{
              justifyContent: !matchSm ? "space-between" : null,
              paddingLeft: !matchSm ? 0 : null,
              paddingRight: !matchSm ? 0 : null,
            }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={filteringContent.toko}
              noneLabel={"All"}
              onChange={(e) => {
                setLoading(true);
                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value,
                });
                if (e.target.value !== "-") {
                  getSalesRecords(e.target.value);
                } else {
                  setChartData(chartDataAll);
                }
              }}
              options={state.listKasir
                .map(({ username, role }) => {
                  return {
                    id: username,
                    name: username,
                    role,
                  };
                })
                .filter((opt) => opt.role === "toko")}
            />
          </Toolbar>
        </div>
        <Chart displayedData={displayedData} />
        {chartData.length ? (
          <div style={{ textAlign: "right", padding: 16 }}>
            <strong>Total Omzet</strong> : Rp{" "}
            {new Intl.NumberFormat(["ban", "id"]).format(
              parseInt(chartData.reduce((a, { Klrp }) => (a += Klrp), 0))
            )}
          </div>
        ) : null}
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

function YtickFormatter(value) {
  return `${parseInt(value) / 1000000} JT`;
}

function XtickFormatter(value) {
  const dateArr = new Date(value).toDateString().split(" ");
  value = `${dateArr[2]} ${dateArr[1]}`;

  return value;
}

function tooltipFormatter(value, name) {
  value = new Intl.NumberFormat(["ban", "id"]).format(parseInt(value));

  return [`Rp ${value}`, "Total"];
}

function labelFormatter(value) {
  return `${new Date(value).toDateString(value)}`;
}

function Chart({ displayedData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={displayedData}>
        <Line
          animationDuration={500}
          type="monotone"
          dataKey="total"
          stroke="#B80000"
        />
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="name" tickFormatter={XtickFormatter} />
        <YAxis tickFormatter={YtickFormatter} />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default SalesCharts;
