import { Paper } from "@material-ui/core";
import GajianPabrikComponent from "./components/GajianPabrikTable";

const GajianPabrik = () => {
  return (
    <Paper style={{ padding: 20 }}>
      <GajianPabrikComponent />
    </Paper>
  );
};

export default GajianPabrik;
