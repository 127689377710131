import React from 'react'
import {FormControl, Checkbox as MuiCheckBox} from '@material-ui/core'
import '../controls/controls.css'

export default function Checkbox2(props){
    return (
        <div style={{border : '1px solid',borderRadius:'10px',padding:'1px'}}>
            <React.Fragment key={props.id}>
                <span>{props.value}</span>
                <MuiCheckBox
                name={props.value} 
                value={props.value}
                checked={props.Checked}
                onChange={props.onChange} 
                />                    
            </React.Fragment>
        {/* {options.map((option) => (

        ))} */}
        {/* <span className="error">{error}</span> */}
    </div>
        // <div style={{display:'flex',flexDirection:"column"}}>
        //             <input 
        //             type="checkbox" 
        //             key={props.id} 
        //             name={props.value}
        //             onChange={props.onChange} 
        //             checked={props.isChecked} 
        //             value={props.value} />
        //             <label>{props.value}</label>                
        // </div>
    )
}