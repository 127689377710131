import { Route } from "react-router-dom";
import SupplierManagement from "./procurement/management";
import supplierTransactions from "./procurement/transaction";
import ControlProduct from "./product/components/components";
import CreateSKU from "./product/insert/createSKU";
import CompleteProducts from "./product/list/complete";
import IncompleteProducts from "./product/list/incomplete";
import UploadBanner from "./marketing/uploadBanner";
import React, { useEffect, useState } from "react";
import OfflineSales from "./sales/offline";
import OnlineSales from "./sales/online";
import InsertOnlineSales from "./sales/insertonlinesales";
import PaymentTesting from "./finance/paymentTesting";
import ArchivedProducts from "./product/list/archived";
import PriceManagement from "./product/management/promoprice";
import Promotions from "./marketing/promotions";
import VouchersClaimed from "./vouchers/VouchersClaimed";
import PointsAdded from "./vouchers/PointsAdded";
import Whatsapp from "./whatsapp/Whatsapp";
import Wishlist from "./sales/Wishlist";
import StockHarian from "./product/stock/StockHarian";
import Analytics from "./sales/analytics/Analytics";
import TeamToko from "./teamtoko/TeamToko";
import Presensi from "./teamtoko/Presensi";
import SalesAnalysis from "./kpi/sales-analysis/SalesAnalysis";
import MemberAnalysis from "./kpi/member-analysis/MemberAnalysis";
import Insert from "./kpi/insert/Insert";
import KPISales from "./kpi/sales/Sales";
import Staff from "./analytics/Staff";
import InsertOfflineSales from "./sales/InsertOffline";
import GajianStaff from "./analytics/gajian/pages/staff/GajianStaff";
import GajianToko from "./analytics/gajian/pages/toko/GajianToko";
import StoreMember from "./kpi/member/StoreMember";
import UploadOnlineSales from "./sales/UploadOnlineSales";
import axios from "axios";
import { baseUrl } from "./config/const";
import RoutesManagement from "./routes-management/RoutesManagement";
import OperStok from "./product/oper-stok/pages/OperStok";
import MovedList from "./product/oper-stok/pages/MovedList";
import GajianTrainee from "./analytics/gajian/pages/trainee/GajianTrainee";
import DeadProducts from "./product/oper-stok/pages/DeadProducts";
import GajianCost from "./analytics/gajian/pages/cost/GajianCost";
import OperStokBySKU from "./product/oper-stok/pages/OperStokBySKU";
import TeamPabrik from "./teampabrik/TeamToko";
import PresensiPabrik from "./teampabrik/Presensi";
import AbsensiPabrik from "./teampabrik/Absensi";
import Absensi from "./teamtoko/Absensi";
import GajianPabrik from "./analytics/gajian/pages/pabrik/GajianPabrik";

const routes = [
  {
    path: "/sales/online",
    component: OnlineSales,
  },
  {
    path: "/sales/offline",
    component: OfflineSales,
  },
  {
    path: "/sales/insertonlinesales",
    component: InsertOnlineSales,
  },
  {
    path: "/sales/insertofflinesales",
    component: InsertOfflineSales,
  },
  {
    path: "/sales/wishlist",
    component: Wishlist,
  },
  {
    path: "/sales/analytics",
    component: Analytics,
  },
  {
    path: "/sales/upload-online",
    component: UploadOnlineSales,
  },
  {
    path: "/vouchers/claimed",
    component: VouchersClaimed,
  },
  {
    path: "/vouchers/points",
    component: PointsAdded,
  },
  {
    path: "/hrd/teamtoko",
    component: TeamToko,
  },
  {
    path: "/hrdpabrik/teampabrik",
    component: TeamPabrik,
  },
  {
    path: "/hrdpabrik/presensipabrik",
    component: PresensiPabrik,
  },
  {
    path: "/hrdpabrik/absensipabrik",
    component: AbsensiPabrik,
  },
  {
    path: "/hrd/presensi",
    component: Presensi,
  },
  {
    path: "/hrd/absensi",
    component: Absensi,
  },
  {
    path: "/products/completeProducts",
    component: CompleteProducts,
  },
  {
    path: "/products/incompleteProducts",
    component: IncompleteProducts,
  },
  {
    path: "/products/controlProducts",
    component: ControlProduct,
  },
  {
    path: "/products/archivedProducts",
    component: ArchivedProducts,
  },
  {
    path: "/products/createSKU",
    component: CreateSKU,
  },
  {
    path: "/products/priceManagement",
    component: PriceManagement,
  },
  {
    path: "/products/stock-harian",
    component: StockHarian,
  },
  {
    path: "/supplier/transaction",
    component: supplierTransactions,
  },
  {
    path: "/supplier/management",
    component: SupplierManagement,
  },
  {
    path: "/finance/paymentGateway",
    component: PaymentTesting,
  },
  {
    path: "/marketing/banner",
    component: UploadBanner,
  },
  {
    path: "/marketing/promotion",
    component: Promotions,
  },
  {
    path: "/analytics/sales",
    component: SalesAnalysis,
  },
  {
    path: "/analytics/memberships",
    component: MemberAnalysis,
  },
  {
    path: "/analytics/gajian/toko",
    component: GajianToko,
  },
  {
    path: "/analytics/gajian/cost",
    component: GajianCost,
  },
  {
    path: "/analytics/staffs",
    component: Staff,
  },
  {
    path: "/analytics/gajian/staff",
    component: GajianStaff,
  },
  {
    path: "/analytics/gajian/trainee",
    component: GajianTrainee,
  },
  {
    path: "/analytics/gajian/pabrik",
    component: GajianPabrik,
  },
  {
    path: "/analytics/pengoperan/records",
    component: MovedList,
  },
  {
    path: "/analytics/pengoperan/dead-products",
    component: DeadProducts,
  },
  {
    path: "/analytics/pengoperan/suggestions",
    component: OperStok,
  },
  {
    path: "/analytics/pengoperan/suggestions-by-sku",
    component: OperStokBySKU,
  },
  {
    path: "/kpi/memberships",
    component: StoreMember,
  },
  {
    path: "/kpi/insert",
    component: Insert,
  },
  {
    path: "/kpi/sales",
    component: KPISales,
  },
  {
    path: "/permission/routes-management",
    component: RoutesManagement,
  },
  {
    path: "/whatsapp/send-messages",
    component: Whatsapp,
  },
];

const RoleRoutes = ({ state }) => {
  const [feRoutes, setFeRoutes] = useState([]);
  console.log(feRoutes);

  const getFeRoutes = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/fe-routes`, {
        params: { role: state.role },
      });

      setFeRoutes(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFeRoutes();
  }, []);

  return (
    <>
      {feRoutes.reduce((acc, route) => {
        route.items.forEach((item) => {
          const _route = routes.find((r) => r.path === item.path);
          if (_route) {
            acc.push(
              <Route exact path={item.path} component={_route.component} />
            );
          }
        });

        return acc;
      }, [])}

      {/* Wildcard Routes */}
      {/* {routes.map((item) => {
        return <Route exact path={item.path} component={item.component} />;
      })} */}
    </>
  );
};

export default RoleRoutes;
