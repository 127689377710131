import React from "react";
import "./controls.css";

export default function Input({
  name,
  placeholder,
  type,
  value,
  error = null,
  onChange,
  style,
  ...other
}) {
  return (
    <div style={{ marginBottom: "20px", ...style }}>
      <input
        type={type || "text"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
      />
      <span className="error">{error}</span>
    </div>
  );
}
