import { useContext, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Controls from "../components/controls/Controls";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { baseUrl } from "../config/const";
import { AuthContext } from "../AppContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const formInputLists = [
  { name: "Klgd", type: "text" },
  { name: "Klkode", type: "text" },
  { name: "Klfak", type: "text" },
  { name: "Kltgklr", type: "text" },
  { name: "Kljam", type: "text" },
  { name: "Klhfak", type: "text" },
  { name: "Klqty", type: "number" },
  { name: "Klrp", type: "number" },
  { name: "Kasir", type: "text" },
  { name: "Klsal", type: "text" },
  { name: "Klnama", type: "text" },
  { name: "Nomor", type: "number" },
  { name: "Komisi", type: "number" },
];

const InsertOfflineSales = () => {
  const { state } = useContext(AuthContext);
  const history = useHistory();

  const [sales, setSales] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", active: false });
  const [sendModal, setSendModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      Klgd: "",
      Klkode: "",
      Klfak: "",
      Kltgklr: "",
      Kljam: "",
      Klhfak: "",
      Klqty: "",
      Klrp: "",
      Kasir: "",
      Klsal: "",
      Klnama: "",
      Nomor: "",
      Komisi: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      addSales(values);
    },
  });

  const [editRowsModel, setEditRowsModel] = useState({});
  const handleEditRowsModelChange = (model) => {
    setEditRowsModel(model);

    if (JSON.stringify(model) !== "{}") {
      const _sales = [...sales];
      const index = sales.findIndex((sale) => {
        return String(sale.id) === String(Object.keys(model)[0]);
      });

      if (index >= 0) {
        _sales[index][Object.keys(model[Object.keys(model)[0]])[0]] =
          model[Object.keys(model)[0]][
            Object.keys(model[Object.keys(model)[0]])[0]
          ].value;

        setSales(_sales);
      }
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const addSales = (sale) => {
    const _sales = [...sales];
    _sales.push({ ...sale, id: Date.now() });

    setSales(_sales);
  };

  const deleteSales = (id) => {
    const deletedIndex = sales.findIndex((sale) => sale.id === id);

    const newSales = [...sales];
    newSales.splice(deletedIndex, 1);

    setSales(newSales);
  };

  const handleDeleteModal = (id) => {
    setDeleteModal({ id, active: !deleteModal.active });
  };

  const handleSendModal = () => {
    setSendModal(!sendModal);
  };

  const insertOfflineSales = async () => {
    try {
      const { data } = await axios.post(`${baseUrl}/insertOfflineSales`, {
        sales,
      });

      setNotify({
        isOpen: true,
        message: data.msg,
        type: "success",
        location: "",
      });

      handleSendModal();
      setSales([]);
      formik.resetForm();
    } catch (err) {
      console.error();
      setNotify({
        isOpen: true,
        message: err.response.data.msg,
        type: "success",
        location: "",
      });
    }
  };

  const columns = [
    {
      field: "Klgd",
      headerName: "Klgd",
      width: 150,
      editable: true,
    },
    {
      field: "Klkode",
      headerName: "Klkode",
      width: 150,
      editable: true,
    },
    {
      field: "Klfak",
      headerName: "Klfak",
      width: 150,
      editable: true,
    },
    {
      field: "Kltgklr",
      headerName: "Kltgklr",
      width: 150,
      editable: true,
    },
    {
      field: "Kljam",
      headerName: "Kljam",
      width: 150,
      editable: true,
    },
    {
      field: "Klhfak",
      headerName: "Klhfak",
      width: 150,
      editable: true,
    },
    {
      field: "Klqty",
      headerName: "Klqty",
      width: 150,
      editable: true,
    },
    {
      field: "Klrp",
      headerName: "Klrp",
      width: 150,
      editable: true,
    },
    {
      field: "Kasir",
      headerName: "Kasir",
      width: 150,
      editable: true,
    },
    {
      field: "Klsal",
      headerName: "Klsal",
      width: 150,
      editable: true,
    },
    {
      field: "Klnama",
      headerName: "Klnama",
      width: 150,
      editable: true,
    },
    {
      field: "Nomor",
      headerName: "Nomor",
      width: 150,
      editable: true,
    },
    {
      field: "Komisi",
      headerName: "Komisi",
      width: 150,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      type: "action",
      renderCell: (params) => (
        <>
          <Button onClick={() => handleDeleteModal(params.row.id)}>
            Delete
          </Button>
          <Dialog
            open={deleteModal.active && deleteModal.id === params.row.id}
            onClose={() => {
              handleDeleteModal("");
            }}
          >
            <DialogTitle>Hapus Sales</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Apakah anda ingin menghapus entry sales ini?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleDeleteModal("");
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  deleteSales(params.row.id);
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
  ];

  return (
    <div style={{ textAlign: "left" }}>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        Insert Offline Sales
      </Typography>
      <Box style={{ marginBottom: 20 }} />
      <Paper style={{ padding: 20 }}>
        <Typography style={{ textAlign: "left" }} variant="h5">
          Add Sales
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {formInputLists.map(({ name, type }, index) => (
              <>
                <TextField
                  margin="dense"
                  id={name}
                  style={{ flexBasis: "48%" }}
                  label={name}
                  name={name}
                  type={type}
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                  error={formik.touched[name] && Boolean(formik.errors[name])}
                  helperText={formik.touched[name] && formik.errors[name]}
                />
                {index % 2 === 0 && <Box style={{ flexBasis: "4%" }} />}
              </>
            ))}
          </Box>
          <Box style={{ marginBottom: 20 }} />
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </Paper>

      <Box style={{ marginBottom: 20 }} />
      <Paper style={{ padding: 20 }}>
        <Typography style={{ textAlign: "left" }} variant="h5">
          Preview
        </Typography>
        <Box style={{ marginBottom: 5 }} />
        <DataGrid
          autoHeight={true}
          columns={columns}
          rows={sales}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
        />
        <Box style={{ marginBottom: 20 }} />
        <Button
          disabled={!sales.length}
          variant="contained"
          color="primary"
          onClick={handleSendModal}
        >
          Send
        </Button>
      </Paper>
      <Dialog open={sendModal} onClose={handleSendModal}>
        <DialogTitle>Kirim Offline Sales</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Apakah anda sudah yakin dengan data offline sales ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendModal}>Cancel</Button>
          <Button onClick={insertOfflineSales}>Send</Button>
        </DialogActions>
      </Dialog>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

const formSchema = yup.object({
  Klgd: yup.string().required("Klgd harus diisi"),
  Klkode: yup.string().required("Klkode harus diisi"),
  Klfak: yup.string().required("Klfak harus diisi"),
  Kltgklr: yup.date().required("Kltgklr harus diisi"),
  Kljam: yup.string().required("Kljam harus diisi"),
  Klhfak: yup.string().required("Klhfak harus diisi"),
  Klqty: yup.number().required("Klqty harus diisi"),
  Klrp: yup.number().required("Klrp harus diisi"),
  Kasir: yup.string().required("Kasir harus diisi"),
  Klsal: yup.string().required("Klsal harus diisi"),
  Klnama: yup.string().required("Klnama harus diisi"),
  Nomor: yup.number().required("Nomor harus diisi"),
  Komisi: yup.number().required("Komisi harus diisi"),
});

export default InsertOfflineSales;
