import React, { useEffect, useState } from "react";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import Axios from "axios";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { DeleteOutline } from "@material-ui/icons";
import "../../components/controls/controls.css";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import { Search } from "@material-ui/icons";
import moment from "moment";
import { baseUrl } from "../../config/const";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export default function CreateSKU() {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const initialValues = {
    images: [],
    pgol: "-",
    sku: "",
    brand: "-",
    pname: "",
    color: "-",
    department: "-",
    category: "-",
    supplier: "-",
    description: "",
    price1: "",
    price2: "",
    price4: "",
    size: [],
    pricebs: "",
    material: "",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    var fileName = "";
    if ("pgol" in fieldValues) {
      temp.pgol = fieldValues.pgol !== "-" ? "" : "Golongan Produk harus diisi";
    }
    if ("sku" in fieldValues) {
      if (fieldValues.sku.length > 15) {
        temp.sku = "SKU maksimal 15 digit";
      } else if (fieldValues.sku.length === 0) {
        temp.sku = "SKU is required";
      } else {
        temp.sku = "";
      }
    }
    if ("images" in fieldValues) {
      [...fieldValues.images].map((file) => {
        fileName = file.name;
      });
      if (fileName.indexOf(" ") >= 0) {
        temp.images = "file name shouldn't have spaces in between";
      } else if (fieldValues.images.length > 5) {
        temp.images = "maximum images of 5";
      } else if (fieldValues.images.length === 0) {
        temp.images = "image is required!";
      } else {
        temp.images = "";
      }
    }
    if ("brand" in fieldValues) {
      temp.brand = fieldValues.brand !== "-" ? "" : "product brand is required";
    }
    if ("description" in fieldValues) {
      temp.description = fieldValues.description
        ? ""
        : "Deskripsi barang harus diisi";
    }
    if ("material" in fieldValues) {
      temp.material = fieldValues.material ? "" : "Material barang harus diisi";
    }
    if ("pname" in fieldValues) {
      temp.pname = fieldValues.pname ? "" : "nama produk harus diisi";
    }
    if ("price1" in fieldValues) {
      temp.price1 = fieldValues.price1 ? "" : "Harga 1=Nett harus diisi";
    }
    if ("price2" in fieldValues) {
      temp.price2 = fieldValues.price2 ? "" : "Harga 2 harus diisi";
    }
    if ("size" in fieldValues) {
      if (fieldValues.size.length === 0) {
        temp.size = "Size cannot be empty";
      }
      // else if(fieldValues.size.length > ){
      //     temp.size = "Max of 7 different Size"
      // }
      else if (fieldValues.size.length > 0 && fieldValues.size.length < 8) {
        temp.size = "";
      }
    }

    if ("supplier" in fieldValues) {
      temp.supplier =
        fieldValues.supplier !== "-" ? "" : "Supplier has to be filled out";
    }
    if ("department" in fieldValues) {
      temp.department =
        fieldValues.department !== "-"
          ? ""
          : "product department should be chosen";
    }
    if ("category" in fieldValues) {
      temp.category =
        fieldValues.category !== "-" ? "" : "product category should be chosen";
    }
    if ("color" in fieldValues) {
      temp.color = fieldValues.color !== "-" ? "" : "color must be selected";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const [formInfo, setFormInfo] = useState({
    brand: [],
    color: [],
    department: [],
    category: [],
    supplier: [],
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  /**
   *
   * @param {*} e indicates the event of getting form's component information
   */
  const getFormInfo = async (e) => {
    await Axios.get(
      process.env.NODE_ENV === "production"
        ? `${baseUrl}/getFormInfo`
        : `/getFormInfo`
    ).then((response) => {
      setFormInfo({
        ...formInfo,
        brand: response.data.results.brand,
        color: response.data.results.color,
        department: response.data.results.department,
        category: response.data.results.category,
        supplier: response.data.results.supplier,
      });
    });
  };

  const formData = new FormData();

  for (const key of Object.keys(values.images)) {
    formData.append("images", values.images[key]);
  }
  formData.append("sku", values.sku);
  // formData.append('brand',formInfo.brand.find(x => x.name === values.brand)?.id)
  formData.append("brand", values.brand);
  formData.append("pname", values.pname);
  // formData.append('color',formInfo.color.find(x => x.name === values.color)?.id)
  formData.append("color", values.color);
  // formData.append('department',formInfo.department.find(x => x.name === values.department)?.id)
  formData.append("department", values.department);
  formData.append("category", values.category);
  formData.append("supplier", values.supplier);
  formData.append("description", values.description);
  formData.append("price1", values.price1);
  formData.append("price2", values.price2);
  // formData.append('price4',values.price4)
  // formData.append('pricebs',values.pricebs)
  for (const key of Object.keys(values.size)) {
    formData.append("size[]", values.size[key]);
  }
  // formData.append('size',values.size)
  formData.append("material", values.material);
  formData.append("pgol", values.pgol);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("kena submit");
    if (validate()) {
      Axios.post(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/createSKU`
          : `/createSKU`,
        formData
        // {
        //     sku : values.sku,
        //     brand : values.brand,
        //     pname : values.pname,
        //     color : values.color,
        //     department : values.department,
        //     category : values.category,
        //     supplier : values.supplier,
        //     price1 : values.price1,
        //     price2: values.price2,
        //     pricebs : values.pricebs === '' ? 0 : values.pricebs ,
        //     size : values.size,
        // }
      )
        .then((response) => {
          console.log("kena syubmit");
          setNotify({
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: "/products/createSKU",
          });
        })
        .catch((err) => {
          console.log("tidak kena submit");
          setNotify({
            isOpen: true,
            message: err.response.data.msg,
            type: "error",
            location: "/products/createSKU",
          });
        });
    }
  };

  const [records, setRecords] = useState([]);
  const getTodaysSKU = () => {
    Axios.get(
      process.env.NODE_ENV === "production"
        ? `${baseUrl}/getIncompleteProducts`
        : `${baseUrl}/getIncompleteProducts`
    )
      .then((response) => {
        setRecords(
          response.data.records.filter(
            (x) => moment(x.datein) === moment(Date())
          )
        );
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/products/createSKU",
        });
      });
  };

  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "brand", label: "Brand" },
    { id: "name", label: "Name", disableSorting: true },
  ];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  /**
   *
   * @param {*} e indicates the search event
   */
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.brand.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };

  useEffect(() => {
    getFormInfo();
  }, []);

  //Adding New Size
  const [addNewSize, setAddNewSize] = useState({
    value: "",
    validate: true,
    message: "",
  });

  const [hideDelete, setHideDelete] = useState({
    isHidden: true,
    color: "red",
  });
  const [openPopup, setOpenPopup] = useState(false);

  const deleteNewSize = (item) => {
    setValues({
      ...values,
      size: values.size.filter((x) => x !== item),
    });
  };

  const handleNewSize = async (e) => {
    setAddNewSize({
      ...addNewSize,
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (values.size.includes(addNewSize.value)) {
      setAddNewSize({
        ...addNewSize,
        validate: false,
        message: "Tidak boleh sama",
      });
    } else if (addNewSize.value === "") {
      setAddNewSize({
        ...addNewSize,
        validate: false,
        message: "Tidak boleh kosong",
      });
    } else {
      setAddNewSize({
        ...addNewSize,
        validate: true,
        message: "",
      });
    }
  }, [addNewSize.value]);

  const submitNewSize = (event, newSize) => {
    event.preventDefault();
    setValues({
      ...values,
      size: [...values.size, newSize],
    });
    setAddNewSize({
      ...addNewSize,
      value: "",
    });
  };

  const typeOptions = [
    { id: "OB", name: "Obral" },
    { id: "BS", name: "Big Size" },
    { id: "normal", name: "Harga Normal" },
  ];
  return (
    <>
      <h1>Create SKU</h1>
      <Paper
        style={{
          margin: "auto",
          padding: "20px",
        }}
        elevation={3}
      >
        <Controls.PageHeader
          title="Create New SKU"
          icon={<PostAddIcon fontSize="large" />}
        />

        <Form encType="multipart/form-data">
          <Grid container>
            <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
              <Controls.Label label="Pilih Golongan Produk" />
              <Controls.Select
                name="pgol"
                value={values.pgol}
                onChange={handleInputChange}
                error={errors.pgol}
                options={typeOptions}
              />
              <Controls.Label label="SKU (kode barcode):" />
              <Controls.Input
                name="sku"
                value={values.sku}
                error={errors.sku}
                onChange={handleInputChange}
              />
              <Controls.Label label="Insert Product Images" />
              <Controls.InputFile
                name="images"
                onChange={handleFileInputChange}
                error={errors.images}
              />
              {values.images &&
                [...values.images].map((file, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ul>
                      <li key={index}>{file.name}</li>
                    </ul>
                  </div>
                ))}

              <Controls.Label label="Brand:" />
              <Controls.Select
                name="brand"
                value={values.brand}
                onChange={handleInputChange}
                error={errors.brand}
                options={formInfo.brand}
              />

              <Controls.Label label="Nama Barang:" />
              <Controls.Input
                name="pname"
                value={values.pname}
                placeholder="ex: Pollux MR40"
                error={errors.pname}
                onChange={handleInputChange}
              />

              <Controls.Label label="Color: " />
              <Controls.Select
                name="color"
                value={values.color}
                onChange={handleInputChange}
                error={errors.color}
                options={formInfo.color}
              />

              <Controls.Label label="Department: " />
              <Controls.Select
                name="department"
                value={values.department}
                onChange={handleInputChange}
                options={formInfo.department}
                error={errors.department}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
              <Controls.Label label="Category: " />
              <Controls.Select
                name="category"
                value={values.category}
                onChange={handleInputChange}
                options={formInfo.category}
                error={errors.category}
              />

              <Controls.Label label="Material:" />
              <Controls.Input
                name="material"
                value={values.material}
                placeholder="ex: kulit asli"
                error={errors.material}
                onChange={handleInputChange}
              />

              <Controls.Label label="Supplier: " />
              <Controls.Select
                name="supplier"
                value={values.supplier}
                onChange={handleInputChange}
                error={errors.supplier}
                options={formInfo.supplier}
              />

              <Controls.Label label="Description: " />
              <Controls.TextArea
                name="description"
                value={values.description}
                onChange={handleInputChange}
                error={errors.description}
                rows={5}
                cols={5}
              />

              <Controls.Label label="Harga 1: " />
              <Controls.Input
                type="number"
                name="price1"
                value={values.price1}
                placeholder="Harga Barcode -- ex: 251900"
                error={errors.price1}
                onChange={handleInputChange}
              />

              <Controls.Label label="Harga 2: " />
              <Controls.Input
                type="number"
                name="price2"
                value={values.price2}
                placeholder="Harga nett setelah diskon 5%"
                onChange={handleInputChange}
                error={errors.price2}
              />
              {/* 
                <div style={{display : values.pgol === '-' && 'none'}}>
                    <div style={{display:values.pgol === 'NORMAL' ? 'block' : 'none'}}>
                        <Controls.Label
                        label="Harga 2: "/>
                        <Controls.Input
                        type="number"
                        name="price2"
                        value={values.price2}
                        placeholder="Harga nett setelah diskon 5%"
                        onChange={handleInputChange}
                        error={errors.price2} />
                    </div>
                    <div style={{display : values.pgol !== 'NORMAL'  ? 'block' : 'none'}}>
                    <Controls.Label
                        label="Harga 4: "/>
                        <Controls.Input
                        type="number"
                        name="price4"
                        value={values.price4}
                        placeholder="Harga Nett, untuk produk "
                        onChange={handleInputChange}
                        error={errors.price4} />
                    </div>
                </div> */}

              <Controls.Label label="Add Sizes: " />
              {values.size.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "inline-flex", position: "relative" }}
                  >
                    <span className="sizing" key={index}>
                      {item}
                    </span>
                    <a
                      hidden={hideDelete.isHidden}
                      onClick={() => deleteNewSize(item)}
                      style={{ color: hideDelete.color }}
                      className="xtag"
                    >
                      <DeleteOutline />
                    </a>
                  </div>
                );
              })}
              <span className="error">{errors.size}</span>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Controls.Button
                  text="Tambah Size"
                  onClick={() => setOpenPopup(!openPopup)}
                  style={{ width: "48%", fontWeight: "bold" }}
                />

                <Controls.Button
                  text="Delete"
                  // icon={<DeleteOutline />}
                  color="secondary"
                  style={{ width: "48%", fontWeight: "bold" }}
                  onClick={() =>
                    setHideDelete({
                      ...hideDelete,
                      isHidden: !hideDelete.isHidden,
                    })
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Controls.Button
            text="Create SKU"
            onClick={onSubmit}
            style={{
              width: "100%",
              background: "rgb(177, 27, 27)",
              fontWeight: "bold",
            }}
          />
        </Form>
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.Popup
        title="Add New Size"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form
          onSubmit={(event) => {
            if (addNewSize.validate === true) {
              submitNewSize(event, addNewSize.value);
            } else {
              event.preventDefault();
            }
          }}
        >
          <Controls.Label label="Add Sizes: " />
          <Controls.Input
            autoFocus
            value={addNewSize.value}
            onChange={handleNewSize}
            error={addNewSize.message}
          />

          <Controls.Button text="Add New Size" type="submit" />
        </Form>
      </Controls.Popup>
    </>
  );
}
