import React, { useState,useEffect } from 'react'
import { Grid, Paper,Toolbar,InputAdornment, TableBody, TableRow,TableCell, ListitemecondaryAction} from '@material-ui/core';
import '../../styles/global.css'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


export default function DetailProduct({item}){
    const [images,setImages] = useState((item.images !== null || item.images === undefined) ? item.images : item.images = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png')
    const [index,setIndex] = useState(0)

    const [property,setProperty] = useState(images[index])
    const limit = item.images.length - 1
    const nextSlide = () => {
        if(index < limit){
            setIndex(prev => prev + 1)
        }else if(index === limit){
            setIndex(0)
        }
    }
    const prevSlide = () => {
        if(index > 0){
            setIndex(prev => prev -1)
        }else if (index === 0){
            setIndex(limit)
        }
    }
    useEffect(() => {
        setProperty(images[index])
    },[index])
    return (
        <>
        <Paper elevation={3}>
        <Grid container>
            <Grid item xs={12} sm={6}>
                <div className="slideshow-container">
                    <div className="mySlides">
                        <img src={property} style={{maxWidth:'100%',height:'auto'}} alt=""/>
                    </div> 
                    <div className="prev" onClick={() => prevSlide()} disabled={item.images.length === 1} >&#10094;</div>
                    <div className="next" onClick={()  => nextSlide()} disabled={item.images.length === 1} >&#10095;</div> 
                </div>
            </Grid>

            <Grid item xs={12} sm={6}>
            <div className="title" style={{display:'flex',flexDirection:'column'}}>
                <span>Brand : {item.brand}</span>
                <span>SKU : {item.sku}</span>
                <span>Name : {item.pname}</span>
                <span>Color : {item.color}</span>
                <span>Description : {item.description}</span>
                <span>Category : {item.category}</span>
                <span>Size: {item.size === null ? '-' : item.size.map((x) => (
                    <span>{x},</span>
                ))}</span>
                <span>Material : {item.material}</span>
                <span>Supplier : {item.supplier}</span>
                <span>Quantity : {item.quantity}</span>
                <span>Price 1 : Rp {item.price1}</span>
                <span>Price 2 : Rp {item.price2}</span>
                <span>Price 3: Rp {item.price3}</span>
                <span>Price 4 : Rp {item.price4}</span>
                <span>Price BigSize : Rp {item.pricebs}</span>
                <span>Date in : {item.datein}</span>
                <span>Date Updated : {item.dateupdate}</span>
                <span>Status : {item.status}</span>
            </div>
            </Grid>
        </Grid>
        </Paper>


        </>
    )
}