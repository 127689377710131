import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";

const OperDialog = ({
  open,
  handleClose,
  children,
  dialogText,
  title,
  onConfirmAction,
  confirmText,
  withoutActions,
  cancelText,
  disableBtn = false,
}) => {
  return (
    <Dialog
      PaperProps={
        withoutActions && {
          style: { width: "90vw", height: "90vh", maxWidth: "90vw" },
        }
      }
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {children}
      </DialogContent>
      {!withoutActions && (
        <DialogActions>
          <Button disabled={disableBtn} onClick={handleClose}>
            {cancelText ? cancelText : "Cancel"}
          </Button>
          <Button disabled={disableBtn} onClick={onConfirmAction}>
            {confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default OperDialog;
