import { useState } from "react";
import Controls from "../../../../components/controls/Controls";
import { Box } from "@material-ui/core";

export const useDateRangePicker = () => {
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);
  return {
    dateRanges,
    DateRangePicker: (
      <>
        <Controls.Label label="Pilih rentang tanggal: " />
        <Box style={{ width: 10 }} />
        <Controls.DateRangeCustomPicker
          name="date-range"
          setDates={setDateRanges}
          dateRanges={dateRanges}
        />
      </>
    ),
  };
};
