import {
  Paper,
  Button,
  Input,
  CircularProgress,
  Toolbar,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import Controls from "../components/controls/Controls";
import axios from "axios";
import { baseUrl } from "../config/const";
import { AuthContext } from "../AppContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const listToko = [{ name: "PONL" }, { name: "PBAZ" }];

const UploadOnlineSales = () => {
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const [toko, setToko] = useState("PONL");

  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const submitFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      const { data } = await axios.put(
        `${baseUrl}/sales/online/${toko}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      setNotify({
        isOpen: true,
        message: data.msg,
        type: "success",
        location: "/sales/upload-online",
      });
    } catch (e) {
      setNotify({
        isOpen: true,
        message: e.response.data.msg,
        type: "error",
        location: "/sales/upload-online",
      });
    }
  };

  function selectFile(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div>
      <h1>Upload Penjualan Online</h1>
      <Paper elevation={3} style={{ padding: 10, textAlign: "left" }}>
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
          }}
        >
          <Controls.Label label="Pilih toko: " />
          <Controls.Select
            name="toko"
            value={toko}
            noNone={true}
            onChange={(e) => {
              setToko(e.target.value);
            }}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            options={listToko.map(({ name, role }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>

        <div
          style={{
            display: "flex",
            marginBottom: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          <label htmlFor="btn-upload" style={{ margin: 0 }}>
            <Input
              id="btn-upload"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={selectFile}
            />
            <Button
              size="small"
              variant="outlined"
              color="primary"
              component="span"
              style={{ height: "100%" }}
            >
              UPLOAD FILE PENJUALAN ONLINE (DBF)
            </Button>
          </label>
        </div>

        {file ? (
          <div
            className="file-name"
            style={{ marginTop: 10, marginBottom: 20, textAlign: "left" }}
          >
            {file.name}
          </div>
        ) : null}

        {file && (
          <Button
            style={{ width: 60 }}
            variant="contained"
            color="primary"
            onClick={submitFile}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              "SUBMIT"
            )}
          </Button>
        )}
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default UploadOnlineSales;
