import "./styles/Summary.css"; //moved to head tag
import React from "react";
import SummaryItem from "./SummaryItem";

export default function Summary({
  totalPrice,
  subtotalPrice,
  shippingFee,
  cart,
}) {
  /*
  cart = {
    image, - harus ambil gambar dulu somehow
    code,
    size, 
    price,
    qty
  }
  */

  return (
    <div className="summary-container">
      <div className="section__header">
        <h2 className="section__title">Ringkasan belanja</h2>
      </div>
      <div className="summary-product-wrapper">
        <table
          role="presentation"
          border="0"
          cellPadding="0"
          cellSpacing="0"
          style={{ borderCollapse: "collapse", borderSpacing: 0 }}
        >
          <tbody>
            <tr>
              <td align="left">
                <table
                  role="presentation"
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  style={{ borderSpacing: "0 10px" }}
                >
                  <tbody className="summary-item">
                    {cart.map((product, index) => {
                      return (
                        <tr className="item-purchased">
                          <SummaryItem
                            key={index}
                            productImage={product.images}
                            productCode={product.sku}
                            productSize={product.size}
                            productPrice={product.price}
                            productQty={product.qty}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <div className="summary-price-total">
                  <div className="summary-price-elem">
                    <table
                      role="presentation"
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <tr>
                        <td>
                          <p>Total Product Price</p>
                        </td>
                        <td>
                          <p className="invoice-props-value">{subtotalPrice}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="summary-price-elem">
                    <table
                      role="presentation"
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <tr>
                        <td>
                          <p>Shipping Fee</p>
                        </td>
                        <td>
                          <p className="invoice-props-value">{shippingFee}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="summary-price-elem">
                    <table
                      role="presentation"
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <tr>
                        <td>
                          <p>Total Price</p>
                        </td>
                        <td>
                          <p className="invoice-props-value">{totalPrice}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
