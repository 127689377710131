import axios from "axios";
import { baseUrl } from "../../../../config/const";

async function getGajianStaff(startDate, endDate, toko, partai) {
  try {
    let {
      data: { data },
    } = await axios.get(`${baseUrl}/gajianStaff`, {
      params: {
        startDate,
        endDate,
        toko,
        partai,
      },
    });

    data = data.map((item) => ({ ...item, id: item.staff }));

    return data;
  } catch (err) {
    throw new Error(err.response.data.msg);
  }
}

export default getGajianStaff;
