import React, { useState, useEffect } from "react";
import { Form, useForm } from "../components/useForm";
import useTable from "../components/useTable";
import axios from "axios";
import { baseUrl } from "../config/const";
import Controls from "../components/controls/Controls";
import {
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Switch,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import moment from "moment";
import { FormatColorReset, Search } from "@material-ui/icons";
import UpdateVoucher from "./management/updateVoucher";
import DeleteVoucher from "./management/deleteVoucher";

export function AddVoucher() {
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const initialValues = {
    code: "",
    images: [],
    name: "",
    type: "-",
    syarat: "",
    nominal: null,
    minimal: null,
    startdate: null,
    enddate: null,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    var fileName = "";
    if ("images" in fieldValues) {
      if (fieldValues.images.length !== 0) {
        [...fieldValues.images].map((file) => {
          fileName = file.name;
        });
        if (fileName && fileName.indexOf(" ") >= 0) {
          temp.images = "File name shouldn't have spaces in between";
        } else if (fieldValues.images.length > 4) {
          temp.images = "Maximum of 4 banners/thumbnails in one upload";
        } else if (fieldValues.images.length === 0) {
          temp.images = "Banner/Thumbnails can't be null";
        } else {
          temp.images = "";
        }
      }
    }
    if ("code" in fieldValues) {
      temp.code = fieldValues.code ? "" : "Kode voucher harus diisi";
    }
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Nama voucher harus diisi";
    }
    if ("syarat" in fieldValues) {
      temp.syarat = fieldValues.syarat
        ? ""
        : "Syarat dan Ketentuan Voucher harus diisi";
    }
    if ("nominal" in fieldValues) {
      temp.nominal =
        fieldValues.nominal !== null
          ? ""
          : "Nominal potongan voucher harus diisi";
    }
    if ("minimal" in fieldValues) {
      temp.minimal =
        fieldValues.minimal !== null
          ? ""
          : "Minimal pembelian voucher harus diisi";
    }
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date harus diisi";
    }
    if ("enddate" in fieldValues) {
      temp.enddate = fieldValues.enddate !== null ? "" : "End Date harus diisi";
    }
    if ("type" in fieldValues) {
      temp.type = fieldValues.type !== "-" ? "" : "Tipe banner harus dipilih";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const formData = new FormData();
  for (const key of Object.keys(values.images)) {
    formData.append("images", values.images[key]);
  }
  formData.append("code", values.code);
  formData.append("name", values.name);
  formData.append("type", values.type);

  formData.append("syarat", values.syarat);
  formData.append("nominal", values.nominal);
  formData.append("minimal", values.minimal);

  formData.append("startdate", new Date(values.startdate).toLocaleDateString());

  formData.append("enddate", new Date(values.enddate).toLocaleDateString());

  const addNewVoucher = () => {
    axios
      .post(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/addNewVoucher`
          : `/addNewVoucher`,
        formData
      )
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/marketing/promotion",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };

  const voucherTypes = [
    { id: "global", name: "Untuk Toko Offline dan Online" },
    { id: "online", name: "Hanya untuk toko online" },
    { id: "poin", name: "Hanya untuk Program Pengumpulan Poin" },
    {
      id: "forRegistered",
      name: "Hanya untuk yang sudah jadi member sebelumnya",
    },
  ];

  return (
    <div>
      <Controls.ActionButton onClick={() => setOpenPopup(!openPopup)}>
        <PostAddIcon /> Tambah Voucher
      </Controls.ActionButton>
      <Controls.Popup
        title="Tambah Voucher Baru"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controls.Label label="Voucher Image: " />
              <Controls.InputFile
                name="images"
                onChange={handleFileInputChange}
                error={errors.images}
              />
              {values.images &&
                [...values.images].map((file, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ul>
                      <li key={index}>{file.name}</li>
                    </ul>
                  </div>
                ))}

              <Controls.Label label="Kode Voucher: " />
              <Controls.Input
                name="code"
                value={values.code}
                error={errors.code}
                onChange={handleInputChange}
                placeholder="ex: VOUC15K"
              />

              <Controls.Label label="Nama Voucher: " />
              <Controls.Input
                name="name"
                value={values.name}
                error={errors.name}
                onChange={handleInputChange}
                placeholder="ex: Voucher Ulang Tahun Happy"
              />

              <Controls.Label label="Tipe Voucher" />
              <Controls.Select
                name="type"
                value={values.type}
                onChange={handleInputChange}
                error={errors.type}
                options={voucherTypes}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Label label="Syarat dan Ketentuan" />
              <Controls.TextArea
                name="syarat"
                value={values.syarat}
                onChange={handleInputChange}
                error={errors.syarat}
                placeholder="Syarat dan Ketentuan Berlaku: "
              />

              <Controls.Label label="Nominal Potongan Voucher: " />
              <Controls.Input
                name="nominal"
                value={values.nominal}
                error={errors.nominal}
                onChange={handleInputChange}
                placeholder="ex: 25000"
              />

              <Controls.Label label="Minimal Pembelanjaan untuk Potongan Voucher: " />
              <Controls.Input
                name="minimal"
                value={values.minimal}
                error={errors.minimal}
                onChange={handleInputChange}
                placeholder="ex: 500000"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "48%",
                  }}
                >
                  <Controls.Label label="Start Date: " />
                  <Controls.DatePicker
                    name="startdate"
                    minDate={new Date()}
                    selected={values.startdate}
                    onChange={(date) => handleDatePicker("startdate", date)}
                    error={errors.startdate}
                  />
                  <div>{moment(values.startdate).format("LL")}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "48%",
                  }}
                >
                  <Controls.Label label="End Date: " />
                  <Controls.DatePicker
                    name="enddate"
                    minDate={values.startdate}
                    selected={values.enddate}
                    onChange={(date) => handleDatePicker("enddate", date)}
                    error={errors.enddate}
                  />
                  <div>{moment(values.enddate).format("LL")}</div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Controls.Button
            style={{ width: "100%", backgroundColor: "rgb(177, 27, 27)" }}
            onClick={addNewVoucher}
            text="Tambah Voucher Baru"
          />
        </Form>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default function Vouchers() {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const headCells = [
    { id: "name", label: "Nama", disableSorting: true },
    { id: "nominal", label: "Nominal", disableSorting: true },
    { id: "startdate", label: "Start Date", disableSorting: true },
    { id: "enddate", label: "End Date", disableSorting: true },
    { id: "actions", label: "actions", disableSorting: true },
  ];
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [openPopup, setOpenPopup] = useState(false);

  const [detailItem, setDetailItem] = useState({});

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => x.name.includes(target.value));
        }
      },
    });
  };

  const getAllVouchers = async (req, res) => {
    await axios
      .get(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/getAllVouchers`
          : `/getAllVouchers`
      )
      .then((response) => {
        setRecords(response.data.records);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };

  const toggleQuickClaim = async (active, code) => {
    try {
      const { data } = await axios.put(`${baseUrl}/toggleQuickClaim`, null, {
        params: { active, code },
      });

      await getAllVouchers();

      setNotify({
        isOpen: true,
        message: data.msg,
        type: "success",
        location: "/marketing/promotion",
      });
    } catch (err) {
      console.error(err);

      setNotify({
        isOpen: true,
        message: err.response.data.msg,
        type: "error",
        location: "/marketing/promotion",
      });
    }
  };

  useEffect(() => {
    getAllVouchers();
  }, []);
  return (
    <div>
      <Paper style={{ margin: "auto" }} elevation={3}>
        <h1>List Vouchers</h1>

        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "10px",
            }}
          >
            <AddVoucher />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
            }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="Cari Nama Voucher"
            />
          </div>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting()
              .sort(
                (a, b) =>
                  new Date(b.enddate).getTime() - new Date(a.enddate).getTime()
              )
              .map((item) => (
                <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell
                    onClick={() => {
                      setOpenPopup(!openPopup);
                      setDetailItem(item);
                    }}
                  >
                    {item.name}
                  </TableCell>
                  <TableCell>Rp. {item.nominal}</TableCell>
                  <TableCell>
                    {new Date(item.startdate).toDateString()}
                  </TableCell>
                  <TableCell>{new Date(item.enddate).toDateString()}</TableCell>
                  <TableCell>
                    <UpdateVoucher
                      item={item}
                      location="/marketing/promotion"
                    />
                    <DeleteVoucher
                      param={item.code}
                      location="/marketing/promotion"
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="p">quickClaim</Typography>
                      <Switch
                        defaultChecked={item.quickClaim}
                        onChange={() => {
                          toggleQuickClaim(!item.quickClaim, item.code);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Controls.Popup
        title={`Detail Voucher ${detailItem.name}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Grid container>
          <Grid xs={12} sm={6}>
            <Controls.Label label="Voucher: " />
            <img
              style={{ width: "25rem", height: "auto" }}
              src={detailItem.image}
            />

            <Controls.Label label="Nama: " />
            <Controls.Input value={detailItem.name} disabled />

            <Controls.Label label="Kode Voucher: " />
            <Controls.Input value={detailItem.code} disabled />

            <Controls.Label label="Tipe Voucher: " />
            <Controls.Input value={detailItem.type} disabled />
          </Grid>
          <Grid xs={12} sm={6}>
            <Controls.Label label="Syarat: " />
            <Controls.TextArea value={detailItem.syarat} disabled />
            <Controls.Label label="Nominal: " />
            <Controls.Input value={detailItem.nominal} disabled />

            <Controls.Label label="Minimal: " />
            <Controls.Input value={detailItem.minimal} disabled />

            <Controls.Label label="Start Date: " />
            <Controls.Input
              value={new Date(detailItem.startdate).toDateString()}
              disabled
            />

            <Controls.Label label="End Date: " />
            <Controls.Input
              value={new Date(detailItem.enddate).toDateString()}
              disabled
            />
          </Grid>
        </Grid>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
