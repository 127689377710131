import "./App.css";
import SignIn from "./account/SignIn";
import AdminDrawer from "./components/adminDrawer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./dashboard/dashboard";
import React, { useEffect, useReducer } from "react";
import NotAuthorizedPage from "./components/NotAuthorizedPage";

import { AuthContext, initialState, reducer } from "./AppContext";
import axios from "axios";
import { baseUrl } from "./config/const";
import Cookies from "universal-cookie";
import RoleRoutes from "./RoleRoutes";

import Email from "./email/email";
import Help from "./help/help";
import Account from "./account/account";
import RoutesManagement from "./routes-management/RoutesManagement";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const logout = async () => {
    const cookies = new Cookies();
    try {
      await axios.post(`${baseUrl}/auth/logout`);

      dispatch({
        type: "LOGOUT",
        payload: {
          isAuthenticated: false,
          id: "",
          username: "",
          email: "",
          role: "",
          accessToken: "",
          msg: "",
        },
      });

      cookies.remove("notouser");
    } catch (err) {
      console.log(err);
    }
  };

  const checkSession = async () => {
    try {
      await axios.get(`${baseUrl}`);
    } catch (err) {
      await logout();
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  if (!state.isAuthenticated) {
    return <SignIn state={state} dispatch={dispatch} />;
  }

  return (
    <div className="App">
      <AuthContext.Provider value={{ state, dispatch }}>
        <Router>
          <AdminDrawer>
            <Switch>
              <Route path="/dashboard" exact component={Dashboard} />

              <Route path="/account">
                <Account logout={logout} state={state} />
              </Route>

              <Route path="/email" exact component={Email} />
              <Route path="/help" exact component={Help} />

              <RoleRoutes state={state} />
            </Switch>
          </AdminDrawer>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
