import { useState } from "react";
import SalesCharts from "./SalesCharts";
import {
  Paper,
  Button,
  Toolbar,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";

const SalesAnalysis = () => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [chartPeriod, setChartPeriod] = useState("monthly");

  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  return (
    <Paper style={{ padding: 20 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginRight: 20, flexGrow: 1 }}>
          <Button
            variant={chartPeriod === "monthly" ? "contained" : "outlined"}
            color="primary"
            component="span"
            onClick={() => setChartPeriod("monthly")}
            style={{ width: "100%" }}
          >
            Bulanan
          </Button>
        </div>

        <div style={{ marginRight: matchSm ? 20 : null, flexGrow: 1 }}>
          <Button
            variant={chartPeriod === "weekly" ? "contained" : "outlined"}
            color="primary"
            component="span"
            onClick={() => setChartPeriod("weekly")}
            style={{ width: "100%" }}
          >
            Mingguan
          </Button>
        </div>

        <div style={{ marginRight: matchSm ? 20 : null, flexGrow: 1 }}>
          <Button
            variant={chartPeriod === "custom" ? "contained" : "outlined"}
            color="primary"
            component="span"
            onClick={() => setChartPeriod("custom")}
            style={{ width: "100%" }}
          >
            Custom Range
          </Button>
        </div>
        {chartPeriod === "weekly" && (
          <Toolbar
            style={{
              justifyContent: !matchSm ? "space-between" : null,
              paddingLeft: !matchSm ? 0 : null,
              paddingRight: !matchSm ? 0 : null,
              flexGrow: 1,
            }}
          >
            <Controls.Label label="Select date: " />
            <Controls.DateRangePicker
              name="date-range"
              setDates={setDateRanges}
              chartPeriod={chartPeriod}
              dateRanges={dateRanges}
            />
          </Toolbar>
        )}

        {chartPeriod === "monthly" && (
          <Toolbar
            style={{
              justifyContent: !matchSm ? "space-between" : null,
              paddingLeft: !matchSm ? 0 : null,
              paddingRight: !matchSm ? 0 : null,
            }}
          >
            <Controls.Label label="Select month: " />
            <Controls.MonthPicker
              name="month"
              setDates={setDateRanges}
              chartPeriod={chartPeriod}
              dateRanges={dateRanges}
            />
          </Toolbar>
        )}

        {chartPeriod === "custom" && (
          <Toolbar
            style={{
              justifyContent: !matchSm ? "space-between" : null,
              paddingLeft: !matchSm ? 0 : null,
              paddingRight: !matchSm ? 0 : null,
            }}
          >
            <Controls.Label label="Select range: " />
            <Controls.DateRangeCustomPicker
              name="date-range"
              setDates={setDateRanges}
              dateRanges={dateRanges}
            />
          </Toolbar>
        )}
      </div>
      <SalesCharts dateRanges={dateRanges} chartPeriod={chartPeriod} />
    </Paper>
  );
};

export default SalesAnalysis;
