import React,{useState,useEffect} from 'react'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Controls from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import { DeleteOutline } from '@material-ui/icons'
import Axios from 'axios'
import { Grid } from '@material-ui/core';
import { baseUrl } from '../../config/const';

export default function UpdateProduct({item,location}){
    const initialValues = {
        newImages : [],
        images : item.images === null ? [] : item.images,
        description: item.description,
        size : item.size === null ? [] : item.size,
        material: item.material,
        price1 : item.price3,
        price2 : item.price1,
    }

    const validate = (fieldValues = values) => {
        var temp = { ...errors }
        var fileName = ''
        if('newImages' in fieldValues){
            if(fieldValues.newImages.length !== 0){
                [...fieldValues.newImages].map((file) => {
                    fileName = file.name             
                })
                if(fileName && fileName.indexOf(' ') >= 0){
                    temp.newImages = "file name shouldn't have spaces in between"
                }
                else if(fieldValues.newImages.length > 5){
                    temp.newImages = "maximum images of 5"
                }else{
                    temp.newImages = ""
                }
            }
        }
        if('description' in fieldValues){
            temp.description = fieldValues.description ? "" : "description is required"
        }
        if('material' in fieldValues){
            temp.material = fieldValues.material ? "" : "material should be filled out"
        }
        if('size' in fieldValues){
            temp.size = fieldValues.size.length !== 0 ? "" : "more than 1 size should be checked"
        }
        if('price1' in fieldValues){
            temp.price1 = fieldValues.price1 ? "" : "Harga 1 Nett harus diisi"
        }
        if('price2' in fieldValues){
            temp.price2 = fieldValues.price2 ? "" : "Harga 2 harus diisi"
        }
        setErrors({
            ...temp
        })
        if(fieldValues === values){
            return Object.values(temp).every(x => x === "")
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleFileInputChange,
    } = useForm(initialValues, true, validate)

    const [openPopup,setOpenPopup] = useState(false)
    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})

    const formData = new FormData()
    for(const key of Object.keys(values.newImages)){
        formData.append('newImages',values.newImages[key])
    }

    const updateProductInfo = (e) => {
        e.preventDefault()
        // if(validate()){
            Axios.put(
                process.env.NODE_ENV === 'production' ? 
                `${baseUrl}/updateProductInfo/${item.sku}` : 
                `/updateProductInfo/${item.sku}`,{
                    description : values.description,
                    size : values.size,
                    material : values.material,
                    price1: values.price1,
                    price2 : values.price2
            })
            .then((response) => {
                setNotify({
                    isOpen : true,
                    message:response.data.msg,
                    type : 'success',
                    location : location
                })
            })
            .catch((err) => {
                setNotify({
                    isOpen : true,
                    message :err.response.data.msg,
                    type : 'error',
                    location : location
                })
            })
        // }
    }

    const updateProductImages = (e) => {
        e.preventDefault()
        // if(validate()){
            Axios.put(
                process.env.NODE_ENV === 'production' ? 
                `${baseUrl}/updateProductImages/${item.sku}` : 
                `/updateProductImages/${item.sku}`,formData)
            .then((response) => {
                setNotify({
                    isOpen:true,
                    message: response.data.msg,
                    type:'success',
                    location : location
                })
            })
            .catch((err) => {
                setNotify({
                    isOpen:true,
                    message : err.response.data.msg,
                    type:'error',
                    location : location
                })
            })
        // }  
    }

    //Adding New Size
    const [addNewSize,setAddNewSize] = useState({
        value : '',
        validate : true,
        message : ''
    })

    const [hideDelete,setHideDelete] = useState({
        isHidden:true,
        color: 'red'
    })

    const [openSizePopup,setOpenSizePopup] = useState(false)
    const deleteNewSize = (item) => {
        setValues({
            ...values,
            size : (values.size).filter((x) => x !== item)
            
        })
    }

    const handleNewSize = async (e) => {
        setAddNewSize({
            ...addNewSize,
            value : e.target.value
        })
    }

    useEffect(() => {
        if((values.size).includes(addNewSize.value)){
            setAddNewSize({
                ...addNewSize,
                validate : false,
                message : 'Tidak boleh sama',
            })
        }
        else if(addNewSize.value === '' ){
            setAddNewSize({
                ...addNewSize,
                validate : false,
                message : 'Tidak boleh kosong'
            })
        }
        else {
            setAddNewSize({
                ...addNewSize,
                validate : true,
                message : ''
            })
        }
    },[addNewSize.value])
    
    const submitNewSize = (event,newSize) => {
        event.preventDefault()
        setValues({
            ...values,
            size : [
                ...values.size,
                newSize
            ]
        })
        setAddNewSize({
            ...addNewSize,
            value : ''
        })
    }
    return (
        <>
            <Controls.ActionButton
            color="primary"
            onClick={() => setOpenPopup(true)}>
                <EditOutlinedIcon />
            </Controls.ActionButton>

            {/**Product Updates  */}
            <Controls.Popup
            title="Update Product"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}>
                <div className="title">SKU : {item.sku}</div>
                <Grid container>
                    <Grid item xs={12} sm={6} style={{padding:'5px'}}>
                        <Form encType="multipart/form-data">
                        <Controls.Label
                        label="Update Product Images" />
                        <Controls.InputFile 
                            name="newImages" 
                            onChange={handleFileInputChange}
                            error={errors.newImages} />
                            <div hidden={values.newImages.length === 0 ? false : true}>
                            {values.images && [...values.images].map((file,index) => (
                                <div key={index} style={{display:'flex',flexDirection:'row'}}>
                                    <img src={file} style={{width: '30%' }} />
                                </div>
                            ))}
                            </div>

                            {values.newImages && [...values.newImages].map((file,index) => (
                                <div key={index} style={{display:'flex',flexDirection:'row'}}>
                                    <img src={URL.createObjectURL(file)} style={{width: '30%' }} />
                                    <span>{file.name}</span>
                                </div>
                            ))}                            
                        <Controls.Button
                        onClick={updateProductImages}
                        text="Update Product Images" />
                        </Form>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{padding:'5px'}}>
                        <Form encType="multipart/form-data"> 

                            <Controls.Label
                            label="Description: " />
                            <Controls.TextArea
                            name="description"
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                            cols={5}
                            rows={5} />

                            <Controls.Label
                            label="Size: " />
                            {(values.size).map((item,index) => {
                            return (
                                <div key={index} style={{display:'inline-flex',position:'relative'}}>
                                    <span className="sizing" key={index}>{item}</span>
                                    <a hidden={hideDelete.isHidden} onClick={() => deleteNewSize(item)} style={{color:hideDelete.color}} className="xtag"><DeleteOutline /></a>
                                </div> 
                            )
                            })}
                            <Controls.Button
                        text="Tambah Size +"
                        onClick={() => setOpenSizePopup(!openSizePopup)} />
                        <Controls.Button
                        text="Delete"
                        icon={<DeleteOutline />}
                        color="secondary"
                        onClick={() => setHideDelete({
                            ...hideDelete,
                            isHidden:!hideDelete.isHidden
                        })} />  

                    
                        <Controls.Label
                        label="Material: " />
                        <Controls.Input
                        name="material"
                        value={values.material}
                        onChange={handleInputChange}
                        error={errors.material} /> 

                        <Grid container>
                            <Grid item xs={6}>
                                <Controls.Label
                                label="Harga Barcode: " />
                                <Controls.Input
                                name="price1"
                                value={values.price1}
                                onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <Controls.Label
                                label="Harga Nett: " />
                                <Controls.Input
                                name="price2"
                                value={values.price2}
                                onChange={handleInputChange} />
                            </Grid>
                        </Grid>

                        <div style={{display:'flex',justifyContent:'space-around'}}>
                            <Controls.Button
                            onClick={updateProductInfo}
                            text="Update Product Information" />
                        </div>
                        </Form>
                    </Grid>
                </Grid>
            </Controls.Popup>

            <Controls.Popup
            title="Add New Size"
            openPopup={openSizePopup}
            setOpenPopup={setOpenSizePopup}>

                <Form onSubmit={(event) => {
                    if(addNewSize.validate === true){
                        submitNewSize(event,addNewSize.value)
                    }else {
                        event.preventDefault()
                    }
                }}>
                    <Controls.Label
                        label="Add Sizes: " />
                        <Controls.Input
                        autoFocus
                        value={addNewSize.value}
                        onChange={handleNewSize}
                        error={addNewSize.message} />
                    <Controls.Button
                        text="Add New Size"
                        type="submit"/>
                </Form>
            </Controls.Popup>

            <Controls.Notification
                    notify={notify}
                    setNotify={setNotify} />
        </>
    )
}