import getGajianStaff from "../api/getGajianStaff";

async function fillEveryPeriodWithGajianData(dataByPeriods, toko, partai) {
  for (let { startDate, endDate } of dataByPeriods) {
    if (startDate || endDate) {
      const periodIndex = dataByPeriods.findIndex(
        (per) => startDate === per.startDate && endDate === per.endDate
      );
      dataByPeriods[periodIndex].data = await getGajianStaff(
        startDate,
        endDate,
        toko,
        partai
      );
    }
  }

  return dataByPeriods;
}

export default fillEveryPeriodWithGajianData;
