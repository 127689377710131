import { Paper, Button, Input, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import axios from "axios";
import { baseUrl } from "../../config/const";

const StockHarian = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [lastUpdated, setLastUpdated] = useState(null);

  const submitFile = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      const { data } = await axios.put(`${baseUrl}/stok`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setNotify({
        isOpen: true,
        message: data.msg,
        type: "success",
        location: "/products/stock-harian",
      });
    } catch (e) {
      setNotify({
        isOpen: true,
        message: e.response.data.msg,
        type: "error",
        location: "/products/stock-harian",
      });
    }
  };

  const getLastUpdated = async () => {
    try {
      const {
        data: { updatedat },
      } = await axios.get(`${baseUrl}/stok/date`);

      setLastUpdated(updatedat);
    } catch (e) {
      console.error(e);
    }
  };

  function selectFile(event) {
    setFile(event.target.files[0]);
  }

  useEffect(() => {
    getLastUpdated();
  }, []);

  return (
    <div>
      <h1>Update Stock Harian</h1>
      <Paper elevation={3} style={{ padding: 10, textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          <label htmlFor="btn-upload" style={{ margin: 0 }}>
            <Input
              id="btn-upload"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={selectFile}
            />
            <Button
              size="small"
              variant="outlined"
              color="primary"
              component="span"
              style={{ height: "100%" }}
            >
              UPLOAD FILE STOK (DBF)
            </Button>
          </label>
        </div>

        {file ? (
          <div
            className="file-name"
            style={{ marginTop: 10, marginBottom: 20, textAlign: "left" }}
          >
            {file.name}
          </div>
        ) : null}

        {file && (
          <Button
            style={{ width: 60 }}
            variant="contained"
            color="primary"
            onClick={submitFile}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              "SUBMIT"
            )}
          </Button>
        )}
        <div>
          {lastUpdated && (
            <h2 style={{ marginLeft: 0 }}>
              Last Updated: {new Date(lastUpdated).toLocaleString("en-GB")}
            </h2>
          )}
        </div>
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default StockHarian;
