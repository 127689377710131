import axios from "axios";
import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import { baseUrl } from "../../config/const";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import moment from "moment";

export default function UpdatePromo({ item, location }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const initialValues = {
    startdate: null,
    enddate: null,
  };
  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const updatePromo = async (param) => {
    await axios
      .put(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/updatePromotionDates/${param}`
          : `/updatePromotionDates/${param}`,
        {
          startdate: new Date(values.startdate).toLocaleDateString(),
          enddate: new Date(values.enddate).toLocaleDateString(),
        }
      )
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: location,
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: location,
        });
      });
  };
  return (
    <>
      <Controls.ActionButton onClick={() => setOpenPopup(true)}>
        <EditOutlined />
      </Controls.ActionButton>
      <Controls.Popup
        title={`Edit Promo of ${item.code}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <h2>Edit Promo {item.code} Description and Expiration date</h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "10px",
            }}
          >
            <p>Current Start Date: {moment(item.startdate).format("LL")}</p>
            <Controls.Label
              label="New Start Date: "
              style={{ fontWeight: "bold" }}
            />
            <Controls.DatePicker
              name="startdate"
              minDate={new Date()}
              selected={values.startdate}
              onChange={(date) => handleDatePicker("startdate", date)}
              error={errors.startdate}
            />
            <div>{moment(values.startdate).format("LL")}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "10px",
            }}
          >
            <p>Current End Date: {moment(item.enddate).format("LL")}</p>
            <Controls.Label
              label="New End Date: "
              style={{ fontWeight: "bold" }}
            />
            <Controls.DatePicker
              name="enddate"
              minDate={values.startdate}
              selected={values.enddate}
              onChange={(date) => handleDatePicker("enddate", date)}
              error={errors.enddate}
            />
            <div>{moment(values.enddate).format("LL")}</div>
          </div>
        </div>
        <br />
        <span style={{ display: "flex", justifyContent: "center" }}>
          **Kosongkan start date dan end date bila promo tidak ada jangka waktu
        </span>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Controls.Button
            style={{ margin: "auto" }}
            text="Ganti masa berlangsung promo"
            onClick={() => updatePromo(item.code)}
          />
        </div>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
