import { useState, useRef, useContext } from "react";
import { Box, Typography, Paper, Toolbar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import GajianTable from "./GajianTable";

import Controls from "../../../../../components/controls/Controls";
import { AuthContext } from "../../../../../AppContext";
import { useDateRangePicker } from "../../../utils/hooks/useDateRangePicker";
import useDataByPeriods from "../../../utils/hooks/useDataByPeriods";

const GajianStaff = ({ listToko }) => {
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const [toko, setToko] = useState("PA2M");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const ref = useRef();
  const { dateRanges, DateRangePicker } = useDateRangePicker();

  const { dataByPeriods } = useDataByPeriods({
    dateRanges,
    setNotify,
    toko,
    partai: true,
  });

  return (
    <>
      <Box>
        <Typography variant="h4">Gajian Staff</Typography>
        <Box style={{ marginBottom: 10 }} />
        <Paper style={{ padding: 10 }} elevation={2}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Toolbar style={{ alignItems: "center" }}>
                <Controls.Label label="Pilih toko: " />
                <Controls.Select
                  name="toko"
                  value={toko}
                  noneLabel={"All"}
                  onChange={(e) => {
                    setToko(e.target.value);
                  }}
                  otherStyle={{
                    marginBottom: 0,
                    marginLeft: 10,
                  }}
                  options={listToko.map(({ name, role }) => {
                    return {
                      id: name,
                      name: name,
                    };
                  })}
                />
              </Toolbar>
              <Toolbar style={{ padding: 0 }}>{DateRangePicker}</Toolbar>
              <Box style={{ marginRight: 10 }} />
            </Box>
            <Toolbar style={{ marginRight: 0, paddingRight: 0 }}>
              <Box>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      disabled={!dataByPeriods.length}
                      variant="outlined"
                      color="primary"
                    >
                      Print
                    </Button>
                  )}
                  content={() => ref.current}
                  documentTitle={
                    dataByPeriods.length
                      ? `staff_${dataByPeriods[0].startDate} - ${
                          dataByPeriods[dataByPeriods.length - 1].endDate
                        }`
                      : ""
                  }
                />
              </Box>
            </Toolbar>
          </Box>
          <Box style={{ marginBottom: 10 }} />
          <div ref={ref}>
            {dataByPeriods
              .filter((p) => p.data.length > 0)
              .map((periodData, index) => (
                <Box
                  key={`${periodData.startDate}-${index}`}
                  style={{ marginBottom: 40 }}
                >
                  <GajianTable periodData={periodData} toko={toko} />
                </Box>
              ))}
          </div>
        </Paper>
        <Controls.Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};

export default GajianStaff;
