import React,{useState} from 'react'
import Axios from 'axios'
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Controls from '../../components/controls/Controls';
import { baseUrl } from '../../config/const';

export default function UnarchiveProduct({param,location}){
    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})
    const [confirmPopup,setConfirmPopup] = useState({isOpen:false,title:'',subtitle:''})

    const unarchiveProduct = async(param) => {
        await Axios.post(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/unarchiveProduct/${param}` :
            `/unarchiveProduct/${param}` 
        )
        .then((response) => {
            setNotify({
                isOpen : true,
                message : response.data.msg,
                type : 'success',
                location : location
            })
        })
        .catch((err) => {
            setNotify({
                isOpen : true,
                message : err.response.data.msg,
                type :'error',
                location : location
            })
        })
    }

    return (
        <>
        <Controls.ActionButton
        onClick={() => 
            setConfirmPopup({
                isOpen : true,
                title :`Yakin unarchive produk ini?`,
                subtitle : 'Unarchived produk bisa ditemukan lagi di completed product list',
                onConfirm : () => {unarchiveProduct(param)}
            }
        )} >
            <UnarchiveIcon fontSize="medium" />
        </Controls.ActionButton>  
        
        <Controls.Notification
        notify={notify}
        setNotify={setNotify} />

        <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup} />
        </>
    )
}