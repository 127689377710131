import React from "react";
import Axios from "axios";
import { useForm, Form } from "../components/useForm";
import { baseUrl } from "../config/const";
import { Grid } from "@material-ui/core";
import Controls from "../components/controls/Controls";

export default function Account({ logout, state }) {
  const initialValues = {
    email: "",
    username: "",
    password: "",
    role: "-",
  };

  const roleOptions = [
    { id: "admin", name: "Admin" },
    { id: "user", name: "User" },
    { id: "toko", name: "Kasir Toko" },
  ];

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    const mediumRegex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    if ("email" in fieldValues) {
      if (fieldValues.email.length !== 0) {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValues.email) === false) {
          temp.email = "Tolong Masukkan alamat e-mail yang valid";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "email must be filled out";
      }
    }
    if ("username" in fieldValues) {
      temp.username =
        fieldValues.username.length !== 0 ? "" : "Username harus diisi";
    }
    if ("password" in fieldValues) {
      temp.password =
        fieldValues.password.length !== 0 ? "" : "Password harus diisi";
    }
    //   if ("password" in fieldValues) {
    //     if (mediumRegex.test(fieldValues.password) === false) {
    //       temp.password =
    //         "Password harus minimum terdiri dari 1 huruf besar, 1 huruf kecil, 1 angka, and total 8 karakter atau lebih";
    //     } else {
    //       temp.password = "";
    //     }
    //   }
    if ("role" in fieldValues) {
      temp.role =
        fieldValues.role === "-" || fieldValues.role.length === 0
          ? "Role harus diisi diisi"
          : "";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  /**
   * return results of useForm helper method
   * @param initialValues determines the initial value of each state
   * @param true boolean is allowing validating while onChange
   * @param validate is helper method of allowing element to validate while onChange
   */
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const createUser = async (e) => {
    e.preventDefault();
    if (validate()) {
      await Axios.post(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/auth/signup`
          : "/auth/signup",
        {
          username: values.username,
          email: values.email,
          password: values.password,
          role: values.role,
          creatorRole: state.role,
        }
      );
    }
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h3>Account Page</h3>
      <span>
        Current active user email :{" "}
        {state.email ? state.email : "tidak ada email"}
      </span>
      <br />
      <span>
        Current active user:{" "}
        {state.username ? state.username : "tidak ada username"}
      </span>
      <br />
      <button onClick={logout}>LOG OUT</button>

      <div
        style={{
          display: state.role === "admin" ? "block" : "none",
          width: "50vw",
          margin: "auto",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Form onSubmit={createUser}>
              <Controls.PageHeader
                title="Sign Up Page"
                subTitle="tempat untuk register account"
              />
              <Controls.Label label="Username: " />
              <Controls.Input
                name="username"
                value={values.username}
                onChange={handleInputChange}
                error={errors.username}
              />

              <Controls.Label label="Email: " />
              <Controls.Input
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
              />

              <Controls.Label label="Password: " />
              <Controls.Input
                name="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
              />

              <Controls.Label label="role" />
              <Controls.Select
                options={roleOptions}
                name="role"
                value={values.role}
                error={errors.role}
                onChange={handleInputChange}
              />

              <Controls.Button type="submit" text="Create User" />
            </Form>
          </Grid>
        </Grid>
      </div>
      {/* </div> */}
    </div>
  );
}
