import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Toolbar,
  useMediaQuery,
  useTheme,
  Divider,
  TextField,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/controls/button";
import Controls from "../../../components/controls/Controls";
import { baseUrl } from "../../../config/const";
import useTable from "../../../components/useTable";
import OperDialog from "./components/Dialog";
import { v4 as uuidv4 } from "uuid";

const categories = [
  {
    id: "",
    name: "All",
    regex: "",
  },
  {
    id: "P",
    name: "PPO",
    regex: "^P(?!PO)",
  },
  {
    id: "PPO",
    name: "Pria",
    regex: "^PPO",
  },
  {
    id: "W",
    name: "Wanita",
    regex: "^W(?!PO)",
  },
  {
    id: "WPO",
    name: "WPO",
    regex: "^WPO",
  },
  {
    id: "A",
    name: "Anak",
    regex: "^A",
  },
  {
    id: "S",
    name: "Sports",
    regex: "^S",
  },
  {
    id: "H",
    name: "Harian",
    regex: "^H",
  },
  {
    id: "K",
    name: "Aksesoris",
    regex: "^K",
  },
];

const DeadProducts = () => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [completeDialog, setCompleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [toko, setToko] = useState("PA2M");
  const now = new Date(Date.now());
  const [deadProducts, setDeadProducts] = useState([]);
  const [listToko, setListToko] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const initialValues = {
    segment: {
      id: "",
      name: "All",
      regex: "",
    },
    sku: "",
  };

  const [filteringContent, setFilteringContent] = useState(initialValues);

  const [selectedRow, setSelectedRow] = useState("");

  const handleCompleteDialog = (id) => {
    if (!id) {
      setSelectedRow("");
    } else {
      setSelectedRow(id);
    }

    setCompleteDialog(!completeDialog);
  };

  const getListKasir = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getProductDemandList = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/oper-stok/demand-check`, {
        params: {
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
          toko,
          category: filteringContent.segment.regex,
        },
      });

      setDeadProducts(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const headCells = [
    { id: "kode", label: "Kode", disableSorting: true },
    { id: "stok", label: "Stok Sekarang", disableSorting: true },
    {
      id: "p0_PR",
      label: `Rate (${formatDate(
        deadProducts.throwBackDate || new Date()
      )} - ${formatDate(dateRanges[0])})`,
      disableSorting: true,
    },
    {
      id: "p0_sold",
      label: `Terjual (${formatDate(
        deadProducts.throwBackDate || new Date()
      )} - ${formatDate(dateRanges[0])})`,
      disableSorting: true,
    },
    {
      id: "p0_PrAvg",
      label: `AVG (${formatDate(
        deadProducts.throwBackDate || new Date()
      )} - ${formatDate(dateRanges[0])})`,
      disableSorting: true,
    },
    {
      id: "p1_PR",
      label: `Rate (${formatDate(dateRanges[0])} - ${formatDate(
        dateRanges[1]
      )})`,
      disableSorting: true,
    },
    {
      id: "p1_sold",
      label: `Terjual (${formatDate(dateRanges[0])} - ${formatDate(
        dateRanges[1]
      )})`,
      disableSorting: true,
    },
    {
      id: "p1_PrAvg",
      label: `AVG (${formatDate(dateRanges[0])} - ${formatDate(
        dateRanges[1]
      )})`,
      disableSorting: true,
    },
    {
      id: "throwback",
      label: "Throwback Period",
    },
    { id: "actions", label: "Action", disableSorting: true },
  ];

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(deadProducts.deadStocks || [], headCells, {
    fn: (items) => {
      return items;
    },
  });

  const handleDialog = (sku, toko) => {
    setDialogOpen(!dialogOpen);

    if (!sku) {
      setSelectedProduct("");
    } else {
      setSelectedProduct(`${sku}-${toko}`);
    }
  };

  useEffect(() => {
    getListKasir();
  }, []);

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getProductDemandList();
    }
  }, [toko, dateRanges, filteringContent]);

  return (
    <Box>
      <Typography variant="h4">Cek Product Mati</Typography>
      <Box style={{ height: 30 }} />
      <Box
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: matchSm ? 20 : null,
        }}
      >
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Pilih toko: " />
          <Controls.Select
            name="toko"
            value={toko}
            noneLabel={"All"}
            onChange={(e) => {
              setToko(e.target.value);
            }}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            options={listToko.map(({ name, role }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>

        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Pilih Category : " />
          <Controls.Select
            name="category"
            noNone={true}
            value={filteringContent.segment.name || "All"}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            onChange={(e) => {
              setFilteringContent({
                ...filteringContent,
                segment: categories.find((c) => c.name === e.target.value),
              });
            }}
            options={categories.map(({ name }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>

        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Select date: " />
          <Box style={{ width: 10 }} />
          <Controls.DateRangeCustomPicker
            name="date-range"
            setDates={setDateRanges}
            dateRanges={dateRanges}
          />
        </Toolbar>

        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Cari SKU: " />
          <Controls.Input
            style={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            onChange={(e) => {
              setTimeout(() => {
                setFilteringContent({
                  ...filteringContent,
                  sku: e.target.value,
                });
              }, 1000);
            }}
            placeholder="CARI Kode ..."
          />
        </Toolbar>
      </Box>

      <Paper>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting()
              .sort((a, b) => {
                if (a.id > b.id) {
                  return 1;
                }

                if (a.id < b.id) {
                  return -1;
                }

                return 0;
              })
              .map((item, index) => (
                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{item.kode}</TableCell>
                  <TableCell>{item.stok}</TableCell>
                  <TableCell>{Number(item.p0_purchaseRate)}</TableCell>
                  <TableCell>{Number(item.p0_totalPembelian)}</TableCell>
                  <TableCell>
                    {Number(deadProducts.p0_purchaseRateAvg)}
                  </TableCell>
                  <TableCell>{Number(item.p1_purchaseRate)}</TableCell>
                  <TableCell>{Number(item.p1_totalPembelian)}</TableCell>
                  <TableCell>
                    {Number(deadProducts.p1_purchaseRateAvg)}
                  </TableCell>
                  <TableCell>
                    {`${deadProducts.throwBackDate || ""} - ${
                      deadProducts.startDate || ""
                    }` || ""}
                  </TableCell>
                  <TableCell>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        text={"Oper"}
                        onClick={() => {
                          handleDialog(item.kode, item.toko);
                        }}
                      />
                    </Box>
                  </TableCell>

                  <OperDialog
                    withoutActions={true}
                    open={
                      dialogOpen &&
                      selectedProduct === `${item.kode}-${item.toko}`
                    }
                    title={`Oper Product ${item.kode} Dari ${toko}`}
                    //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
                    confirmText="Oper"
                    handleClose={handleDialog}
                  >
                    <SuggestionModalContent
                      dateRanges={dateRanges}
                      toko={toko}
                      sku={item.kode}
                      totalSolds={item.totalPembelian}
                      purchaseRate={item.purchaseRate}
                      currentStocks={item.stok}
                      handleClose={handleDialog}
                      onConfirmAction={() => {
                        handleDialog();
                      }}
                      confirmText="Oper"
                    />
                  </OperDialog>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </Box>
  );
};

const SuggestionModalContent = ({
  dateRanges,
  toko,
  sku,
  totalSolds,
  currentStocks,
  purchaseRate,
  handleClose,
  onConfirmAction,
  confirmText,
}) => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [availableStok, setAvailableStok] = useState([]);
  const [bufferOper, setBufferOper] = useState([]);
  const [approvedOper, setApprovedOper] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [maxOper, setMaxOper] = useState(currentStocks);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const suggestionHeadCells = [
    { id: "toko", label: "Penerima", disableSorting: true },
    {
      id: "targetPurchaseRate",
      label: "Purchase Rate",
      disableSorting: true,
    },

    { id: "stok_origin", label: "Stok", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const bufferHeadCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "origin", label: "Penerima", disableSorting: true },
    { id: "stok_origin", label: "Stok Pemberi", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const approvedHeadCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "origin", label: "Pemberi", disableSorting: true },
    { id: "stok_origin", label: "Stok", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
  ];

  const moveProduct = async () => {
    try {
      if (!dialogOpen) {
        return;
      }
      const productArray = bufferOper.map((buf) => ({
        date: formatDate(new Date()),
        origin: toko,
        target: buf.toko,
        sku: buf.kode,
        qty: Number(buf.qty),
        originStock: Number(currentStocks),
      }));

      const { data } = await axios.post(`${baseUrl}/oper-stok/suggestions`, {
        productArray,
      });

      setNotify({
        isOpen: true,
        type: "success",
        message: data.msg,
        location: "",
      });

      setDialogOpen(false);
      setBufferOper([]);
      getApproved();
    } catch (err) {
      setNotify({
        isOpen: true,
        type: "error",
        message: err.response.data.msg,
        location: "",
      });
    }
  };

  const addStok = (item, qty) => {
    console.log(item);
    if (!item.qty || item.qty > item.originStock) {
      item.error = true;
      item.qty = qty;

      setAvailableStok([...availableStok]);
      return;
    }

    setBufferOper([
      ...bufferOper,
      { id: uuidv4(), qty: item.qty ? item.qty : 0, ...item },
    ]);

    item.error = false;
    item.qty = 0;
  };

  const {
    TblHead: TblHeadApproved,
    TblContainer: TblContainerApproved,
    TblPagination: TblPaginationApproved,
    styledTableCell: TableCellApproved,
    recordsAfterPagingAndSorting: approvedRecords,
  } = useTable(approvedOper, approvedHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const {
    TblHead: TblHeadBuffer,
    TblContainer: TblContainerBuffer,
    TblPagination: TblPaginationBuffer,
    styledTableCell: TableCellBuffer,
    recordsAfterPagingAndSorting: bufferRecords,
  } = useTable(bufferOper, bufferHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(availableStok, suggestionHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const getTarget = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/oper-stok/demand-check/target`,
        {
          params: {
            startDate: formatDate(dateRanges[0]),
            endDate: formatDate(dateRanges[1]),
            toko,
            sku,
          },
        }
      );

      setAvailableStok(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getApproved = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/oper-stok/records`, {
        params: {
          origin: toko,
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
          status: "approved",
          sku,
        },
      });

      setApprovedOper(data.data.movedProduct);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getApproved();
      getTarget();
    }
  }, []);

  return (
    <Box>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      <Box style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        <Box style={{ flex: 1, width: "100%" }}>
          <Typography variant="body1">Suggestions</Typography>
          <Box style={{ marginBottom: 10 }} />
          <Paper>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item, index) => (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCell>{item.toko}</TableCell>
                    <TableCell>{item.purchase_rate}</TableCell>
                    <TableCell>{item.stok}</TableCell>
                    <TableCell>
                      <TextField
                        error={Boolean(item.error)}
                        defaultValue={0}
                        autoComplete="off"
                        margin="dense"
                        id="qty"
                        type="number"
                        onChange={(e) => {
                          item.qty = e.target.value;
                        }}
                        InputProps={{
                          inputProps: { min: 1, max: item.originStock },
                        }}
                        FormHelperTextProps={{
                          style: {
                            position: "absolute",
                            right: 16,
                            color: "red",
                          },
                        }}
                        fullWidth
                        helperText={`max ${currentStocks}`}
                      />
                    </TableCell>

                    <TableCell>
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          text={"Add"}
                          onClick={() => {
                            addStok(item);
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
        </Box>
        <Box style={{ flex: 1, width: "100%" }}>
          <Typography variant="body1">Produk yang akan dipindah</Typography>
          <Box style={{ marginBottom: 10 }} />
          <Paper>
            <TblContainerBuffer>
              <TblHeadBuffer />
              <TableBody>
                {bufferRecords().map((item, index) => (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCellBuffer>{item.kode}</TableCellBuffer>
                    <TableCellBuffer>{item.toko}</TableCellBuffer>
                    <TableCellBuffer>{currentStocks}</TableCellBuffer>
                    <TableCellBuffer>{item.qty}</TableCellBuffer>
                    <TableCell>
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => {
                            const _bufferOper = [...bufferOper];

                            _bufferOper.splice(
                              _bufferOper.findIndex(
                                (buf) => buf.id === item.id
                              ),
                              1
                            );

                            setBufferOper(_bufferOper);
                          }}
                          text={"Delete"}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainerBuffer>
            <TblPaginationBuffer />
          </Paper>
          <DialogActions>
            <Button onClick={handleClose} text="Cancel" />
            <Button
              onClick={() => {
                handleDialog();
              }}
              disabled={bufferOper.length === 0}
              text="Oper"
            />
          </DialogActions>
        </Box>
      </Box>

      <Box style={{ marginBottom: 20 }} />
      <Typography variant="body1">Approved</Typography>
      <Box style={{ marginBottom: 10 }} />
      <Paper>
        <TblContainerApproved>
          <TblHeadApproved />
          <TableBody>
            {approvedRecords().map((item, index) => (
              <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                <TableCellApproved>{item.sku}</TableCellApproved>
                <TableCellApproved>{item.origin}</TableCellApproved>
                <TableCellApproved>{item.origin_stock}</TableCellApproved>
                <TableCellApproved>{item.qty}</TableCellApproved>
              </TableRow>
            ))}
          </TableBody>
        </TblContainerApproved>
        <TblPaginationApproved />
      </Paper>

      <OperDialog
        open={dialogOpen}
        title={`Apakah anda sudah yakin dengan list produk tersebut ? `}
        //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
        confirmText="Confirm"
        handleClose={handleDialog}
        onConfirmAction={moveProduct}
      />
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export default DeadProducts;
