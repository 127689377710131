import {
  Paper,
  TableBody,
  TableRow,
  Toolbar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState, Fragment } from "react";
import Controls from "../components/controls/Controls";
import useTable from "../components/useTable";
import { baseUrl } from "../config/const";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import { Search } from "@material-ui/icons";
import { Form, useForm } from "../components/useForm";
import Modal from "react-modal";
import { AuthContext } from "../AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zindex: 1000 },
};

export function CheckShipment({ item }) {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      <Controls.Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopup(true);
        }}
        size="small"
        style={{
          backgroundColor: "transparent",
          color: "black",
          position: "relative",
          zIndex: "1000",
        }}
        text="click for info"
        startIcon={<CheckCircleIcon style={{ color: "green" }} />}
      />

      <Controls.Popup
        title={`Shipping Info of Invoice ${item.id}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Controls.Label label="Tanggal Pengiriman: " />
        <h3>{item.dateshipped}</h3>

        <Controls.Label label="Kurir: " />
        <h3>{item.shippingcompany}</h3>

        <Controls.Label label="No. Resi: " />
        <h3>{item.waybillnumber}</h3>
      </Controls.Popup>
    </>
  );
}

export default function OfflineSales() {
  const { state, dispatch } = useContext(AuthContext);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  /**
   * menunggu API untuk mendapatkan offline sales record
   */
  const [salesRecords, setSalesRecords] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const initialValues = {
    resi: "",
    shippingcompany: "",
    etd: "",
    dateshipped: null,
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("resi" in fieldValues) {
      temp.resi = fieldValues.resi ? "" : "Nomor Resi harus diisi";
    }
    if ("shippingcompany" in fieldValues) {
      temp.shippingcompany = fieldValues.shippingcompany
        ? ""
        : "Kurir harus diisi";
    }
    if ("etd" in fieldValues) {
      temp.etd = fieldValues.etd ? "" : "Estimated Date Delivered harus diisi";
    }
    if ("dateshipped" in fieldValues) {
      temp.dateshipped =
        fieldValues.dateshipped !== null ? "" : "Tangga Pengiriman harus diisi";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange, handleDatePicker } =
    useForm(initialValues, true, validate);

  const getListKasir = async () => {
    try {
      const { data: dataKasir } = await axios.get(
        `${baseUrl}/getPoinPenjualan`
      );

      dispatch({
        type: "UPDATE_LIST",
        payload: {
          listKasir: dataKasir.listKasir,
        },
      });
    } catch (err) {
      setNotify({
        message: err.response.data.msg,
        isOpen: true,
        type: "error",
      });
    }
  };

  const headCells = [
    { id: "Klkode", label: "Kode Produk", disableSorting: true },
    { id: "Klfak", label: "Faktur", disableSorting: true },
    { id: "Klrp", label: "Nominal", disableSorting: true },
    { id: "Kltgklr", label: "Tanggal", disableSorting: true },
    { id: "Kasir", label: "Kasir", disableSorting: true },
    { id: "Klsal", label: "Klsal", disableSorting: true },
  ];

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    date: new Date(Date.now()),
    toko: "PA2M",
  });
  const [faktur, setFaktur] = useState("");
  const [filtered, setFiltered] = useState([]);

  const getAmount = () => {
    return filtered.reduce((a, c) => {
      return a + c.Klrp;
    }, 0);
  };

  const getTotalPs = () => {
    return filtered.reduce((a, c) => {
      return a + Number(c.Klqty);
    }, 0);
  };

  const getTotalPollux = () => {
    return filtered.reduce((a, c) => {
      if (c.Klkode.includes("WPO") || c.Klkode.includes("PPO")) {
        return a + Number(c.Klqty);
      }
      return a;
    }, 0);
  };

  const getOnlineSalesRecords = async (currentDate) => {
    setLoading(true);

    const fullYear = currentDate.getFullYear();
    const month =
      currentDate.getMonth() + 1 >= 10
        ? currentDate.getMonth() + 1
        : `0${currentDate.getMonth() + 1}`;
    const date =
      currentDate.getDate() >= 10
        ? currentDate.getDate()
        : `0${currentDate.getDate()}`;

    await axios
      .get(
        `${baseUrl}/getPjKel?startdate=${`${fullYear}-${month}-${date}`}&enddate=${`${fullYear}-${month}-${date}`}&toko=${
          filteringContent.toko === "-" ? "" : filteringContent.toko
        }`
      )
      .then((response) => {
        setSalesRecords(response.data.records);
        setFiltered(response.data.records);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setNotify({
          isOpen: true,
          type: "error",
          message: error.response.data.msg,
          location: "/sales/offline",
        });
      });
  };

  function handleFilter() {
    let filteredDisplay = salesRecords; //state.vouchersClaimed

    if (faktur) {
      filteredDisplay = filteredDisplay.filter((item) => {
        return item.Klfak.includes(faktur);
      });
    }

    setFiltered(filteredDisplay);
  }

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    getListKasir();
  }, []);

  useEffect(() => {
    getOnlineSalesRecords(filteringContent.date);
  }, [filteringContent]); //eslint-disable-line

  useEffect(() => {
    if (salesRecords.length) {
      handleFilter();
    }
  }, [faktur]); //eslint-disable-line

  return (
    <div className="online-sales">
      <h1>Offline Sales</h1>
      <Paper
        elevation={3}
        style={{ margin: "auto", minHeight: 325, position: "relative" }}
      >
        {isLoading && filteringContent.toko !== "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}
        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFaktur(e.target.value);
              }}
              placeholder="CARI Nomor Faktur..."
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Select date: " />
            <Controls.DatePicker
              name="enddate"
              minDate={values.startdate}
              selected={filteringContent.date}
              onChange={(date) => {
                handleDatePicker("enddate", date);

                setFilteringContent({
                  ...filteringContent,
                  date,
                });
              }}
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={filteringContent.toko}
              noneLabel={"All"}
              onChange={(e) => {
                handleInputChange(e);

                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value,
                });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
            />
          </Toolbar>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => {
              const date = new Date(item.Kltgklr);

              return (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell
                    style={{ cursor: "pointer" }}
                    //   onClick={() => {
                    //     handlePopUpOpen();
                    //     setInvoiceSelected(item.id);
                    //   }}
                  >
                    {item.Klkode}
                  </TableCell>
                  <TableCell>{item.Klfak}</TableCell>
                  <TableCell>Rp {item.Klrp}</TableCell>
                  <TableCell>{`${date.toLocaleDateString("en-GB")}`}</TableCell>
                  <TableCell>{item.Kasir}</TableCell>
                  <TableCell>{item.Klsal}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <div style={{ textAlign: "right", padding: 16 }}>
          <div>
            <strong>Total Ps </strong>: {getTotalPs()}
          </div>
          <div>
            <strong>Total Pollux </strong>: {getTotalPollux()}
          </div>
          <div>
            <strong>Total Amount</strong> :{" "}
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(getAmount())}
          </div>
        </div>
        <TblPagination />
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}

const dummySalesData = [
  {
    Klkode: "WPOA187CRD1",
    Klnama: "POLLUX A187 CREAM",
    Klfak: "23011000008",
    Kltgklr: "2022-01-11",
    Klgd: "ROY",
    Klqty: 1,
    Klrp: 200000,
    Komisi: 5000,
  },
  {
    Klkode: "SACADAB59",
    Klnama: "ARDILES ADARA ABU",
    Klfak: "23011000001",
    Kltgklr: "2022-01-11",
    Klgd: "IJN",
    Klqty: 1,
    Klrp: 150000,
    Komisi: 5000,
  },
  {
    Klkode: "AABL008MD66",
    Klnama: "MECER L-008 MERAH MUDA [A",
    Klfak: "23011000002",
    Kltgklr: "2022-01-14",
    Klgd: "ABM",
    Klqty: 1,
    Klrp: 300000,
    Komisi: 5000,
  },
  {
    Klkode: "WPOCN20H77",
    Klnama: "POLLUX CN20 HITAM",
    Klfak: "23011000003",
    Kltgklr: "2022-01-15",
    Klgd: "IJN",
    Klqty: 1,
    Klrp: 400000,
    Komisi: 5000,
  },
  {
    Klkode: "BBGAQ11C55",
    Klnama: "BURGUNDY AQ11 COKLAT",
    Klfak: "23011000009",
    Kltgklr: "2022-01-19",
    Klgd: "ATM",
    Klqty: 1,
    Klrp: 100000,
    Komisi: 5000,
  },
];
