import React, { useEffect, useState } from 'react'
import {FormControl,InputLabel,Select as MuiSelect,MenuItem,FormHelperText, Radio, RadioGroup, FormControlLabel} from '@material-ui/core'
import '../controls/controls.css'

export default function Select({name,value,onChange,error=null,options}){
    return (
        <RadioGroup
            aria-label="gender"
            name={name}
            value={value}
            onChange={onChange} >
                {options.map((item) => {
                    <FormControlLabel value={item.id || item.code} control={<Radio />} label={item.name} />
                })}
        </RadioGroup>
    )
}
