import React, { useEffect, useState, useContext } from "react";
// import { Form,useForm } from '../components/useForm'
import "../../styles/global.css";
import {
  Grid,
  Paper,
  Toolbar,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import Axios from "axios";
import useTable from "../../components/useTable";
import { Search } from "@material-ui/icons";
import DetailProduct from "./detail";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ProductDeletion from "../management/deletion";
import UpdateProduct from "../management/update";
import { baseUrl } from "../../config/const";
import ArchiveProduct from "../management/archiveProduct";
import { AuthContext } from "../../AppContext";
import { useForm } from "../../components/useForm";
import { getFormInfo } from "../management/helper/promoprice";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export default function CompleteProducts() {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const { state, dispatch } = useContext(AuthContext);
  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "brand", label: "Brand" },
    { id: "pname", label: "Product Name", disableSorting: true },
    { id: "color", label: "Color", disableSorting: true },
    // {id:'department',label:'Department',disableSorting:true},
    { id: "price1", label: "Harga Barcode" },
    // {id:'price2',label:'Price 2'},
    { id: "status", label: "Status" },
    { id: "actions", label: "Actions", disableSorting: true },
  ];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [detailItem, setDetailItem] = useState({});
  const [filterContent, setFilterContent] = useState({
    departments: [],
    categories: [],
  });
  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    search: "",
    department: "-",
    category: "-",
  });
  const [filtered, setFiltered] = useState([]);
  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });
  const initialValues = {
    department: "-",
    category: "-",
  };
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("enddate" in fieldValues) {
      temp.enddate = fieldValues.enddate ? "" : "Start Date shouldn't be empty";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  console.log(filtered);
  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  function handleFilter() {
    let filteredDisplay = state.completeProductList;

    if (filteringContent.search) {
      // console.log("search filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        return item.sku.includes(filteringContent.search);
      });
    }
    if (filteringContent.department) {
      // console.log("date filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (filteringContent.department === "-") {
          return true;
        }
        return item.department === filteringContent.department;
      });
    }
    if (filteringContent.category) {
      // console.log("toko filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (filteringContent.category === "-") {
          return true;
        }
        return item.category === filteringContent.category;
      });
    }
    setFiltered(filteredDisplay);
  }
  useEffect(() => {
    getFormInfo(setFilterContent);
  }, []);

  useEffect(() => {
    console.log("run");
    handleFilter();
  }, [filteringContent]); //eslint-disable-line

  /**
   * @returns records of all products
   */
  const getRecords = async () => {
    await Axios.get(`${baseUrl}/getCompleteProducts`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            completeProductList: response.data.records,
          },
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
        });
      });
  };
  /**
   *
   * @param {*} e indicates the search event
   */
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => x.sku.includes(target.value));
        }
      },
    });
  };
  useEffect(() => {
    getRecords();
  }, []);

  return (
    <div className="complete-product">
      <h1>Complete Product List</h1>
      <Paper elevation={2}>
        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              // inputprops={{
              //     startAdornment : (
              //         <InputAdornment position="start">
              //             <Search />
              //         </InputAdornment>
              //     )
              // }}
              onChange={(e) => {
                setFilteringContent({
                  ...filteringContent,
                  search: e.target.value,
                });
              }}
              placeholder="CARI KODE BARCODE"
            />
          </Toolbar>

          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="PILIH DEPARTMENT " />
            <Controls.Select
              name="department"
              value={values.department}
              onChange={(e) => {
                // console.log(e)
                handleInputChange(e);
                setFilteringContent({
                  ...filteringContent,
                  department: e.target.value,
                });
              }}
              options={filterContent.departments.map(({ name }) => {
                return {
                  id: name,
                  name: name,
                };
              })}
            />
          </Toolbar>

          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="PILIH CATEGORY " />
            <Controls.Select
              name="category"
              value={values.category}
              onChange={(e) => {
                // console.log(e)
                handleInputChange(e);
                setFilteringContent({
                  ...filteringContent,
                  category: e.target.value,
                });
              }}
              options={filterContent.categories.map(({ name }) => {
                return {
                  id: name,
                  name: name,
                };
              })}
            />
          </Toolbar>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.sku} hover role="checkbox" tabIndex={-1}>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenPopup(true);
                    setDetailItem(item);
                  }}
                >
                  {item.sku}
                </TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.pname}</TableCell>
                <TableCell>{item.color}</TableCell>
                {/* <TableCell>{item.department}</TableCell> */}
                <TableCell>Rp {item.price3}</TableCell>
                {/* <TableCell>Rp {item.price1}</TableCell> */}
                <TableCell style={{ color: "green", verticalAlign: "middle" }}>
                  <DoneAllIcon fontSize="small" />
                  {item.status}
                </TableCell>
                <TableCell>
                  <UpdateProduct
                    item={item}
                    location="/products/completeProducts"
                  />
                  <ArchiveProduct
                    param={item.sku}
                    location="/products/completeProducts"
                  />
                  <ProductDeletion
                    productStatus="complete"
                    param={item.sku}
                    location="/products/completeProducts"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Controls.Popup
        title="Product Detail"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <DetailProduct item={detailItem} />
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}
