import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import "../controls/controls.css";

export default function Select({
  name,
  value,
  onChange,
  error = null,
  options,
  otherStyle,
  noneLabel,
  defaultValue,
  noNone = false,
  ...other
}) {
  return (
    <div style={{ marginBottom: "20px", ...otherStyle }}>
      <FormControl
        size="small"
        variant="outlined"
        {...(error && { error: true })}
      >
        <MuiSelect
          name={name}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          {...other}
          style={{ paddingTop: 0, paddingBottom: 0, minWidth: 120 }}
        >
          {!noNone && (
            <MenuItem value="-">{noneLabel ? noneLabel : "None"}</MenuItem>
          )}
          {options.map((item) => (
            <MenuItem key={item.id || item.code} value={item.id || item.code}>
              {item.name}
            </MenuItem>
          ))}
        </MuiSelect>
        <span className="error">{error}</span>
      </FormControl>
    </div>
  );
}
