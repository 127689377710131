import {
  Paper,
  TableBody,
  TableRow,
  Toolbar,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Search } from "@material-ui/icons";

export default function KPIMember() {
  const { state } = useContext(AuthContext);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSales, setSelectedSales] = useState(null);

  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    category: "",
    toko: "PA2M",
    limit: "",
  });

  const [filtered, setFiltered] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const headCells = [
    { id: "name", label: "Nama", disableSorting: true },
    { id: "poin", label: "Poin", disableSorting: false },
    { id: "last_purchase", label: "Beli Terakhir", disableSorting: true },
    {
      id: "second_last_purchase",
      label: "Beli 2 Terakhir",
      disableSorting: true,
    },
    {
      id: "third_last_purchase",
      label: "Beli 3 Terakhir",
      disableSorting: true,
    },
    {
      id: "jumlah_pembelian",
      label: "Jumlah Pembelian",
      disableSorting: false,
    },
  ];

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });

  const getKPIStoreMember = async () => {
    setLoading(true);
    await axios
      .get(
        `${baseUrl}/getKPIStoreMembers?toko=${filteringContent.toko}&limit=${filteringContent.limit}&email=${search}&phone=${search}&name=${search}`
      )
      .then((response) => {
        setFiltered(response.data.data.storeMembers);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setNotify({
          isOpen: true,
          type: "error",
          message: error.response.data.msg,
          location: "/sales/analytics",
        });
      });
  };

  const formatDateLocale = (dateString) => {
    if (!dateString) {
      return "-";
    }
    return new Date(dateString).toLocaleString("id", {
      timeZone: "Asia/Jakarta",
      dateStyle: "full",
      timeStyle: "short",
    });
  };

  let timeout;

  useEffect(() => {
    getKPIStoreMember();
  }, [filteringContent, search]); //eslint-disable-line

  return (
    <div className="online-sales" style={{ marginBottom: 20 }}>
      <h1>Store Members</h1>
      <Paper
        elevation={3}
        style={{ margin: "auto", minHeight: 325, position: "relative" }}
      >
        {isLoading ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.1)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}

        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                clearTimeout(timeout);
                setTimeout(() => {
                  setSearch(e.target.value);
                }, 1000);
              }}
              placeholder="CARI email, nama, nomor hp..."
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={filteringContent.toko}
              noNone={true}
              onChange={(e) => {
                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value === "-" ? "" : e.target.value,
                });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
            />
          </Toolbar>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  onClick={() => {
                    setSelectedSales(item);
                    setModalOpen(true);
                  }}
                >
                  {item.name}
                </TableCell>
                <TableCell>{item.poin}</TableCell>
                <TableCell>{formatDateLocale(item.last_purchase)}</TableCell>
                <TableCell>
                  {formatDateLocale(item.second_last_purchase)}
                </TableCell>
                <TableCell>
                  {formatDateLocale(item.third_last_purchase)}
                </TableCell>
                <TableCell>{item.jumlah_pembelian}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <div style={{ textAlign: "right", padding: 16 }}>
          <strong>Total Member</strong> : {filtered.length}
        </div>
        <TblPagination />
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />
      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
      <Controls.Popup
        title={"Member Preview"}
        openPopup={modalOpen}
        onClose={setModalOpen}
        setOpenPopup={setModalOpen}
      >
        <Typography variant="h6">Email : {selectedSales?.email}</Typography>
        <Typography variant="h6">Phone : {selectedSales?.phone}</Typography>
      </Controls.Popup>
    </div>
  );
}
