import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../config/const";
import Controls from "../components/controls/Controls";
import { Typography, Paper, Toolbar, Box } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Staff = () => {
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const [toko, setToko] = useState("PA2M");
  const [payrollData, setPayrollData] = useState([]);
  const [listToko, setListToko] = useState([]);

  const getSPGPerformance = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/getSPGPerformanceByToko`, {
        params: {
          toko,
          startDate: dateRanges[0].toLocaleDateString("en-CA"),
          endDate: dateRanges[1].toLocaleDateString("en-CA"),
        },
      });

      setPayrollData(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      field: "staff",
      headerName: "Staff",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "pollux_wanita",
      headerName: "Plx Wanita",
      flex: 1,
      type: "number",
    },
    {
      field: "ps",
      headerName: "PS",
      flex: 1,
      type: "number",
    },
  ];

  const getListKasir = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSPGPerformance();
  }, [toko, dateRanges]);

  useEffect(() => {
    getListKasir();
  }, []);

  return (
    <>
      <Typography variant="h3">Staff</Typography>
      <Paper style={{ height: 600, padding: 20, marginTop: 20 }}>
        <Box style={{ display: "flex" }}>
          <Toolbar style={{ alignItems: "center" }}>
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={toko}
              noneLabel={"All"}
              onChange={(e) => {
                setToko(e.target.value);
              }}
              otherStyle={{
                marginBottom: 0,
                marginLeft: 10,
              }}
              options={listToko.map(({ name, role }) => {
                return {
                  id: name,
                  name: name,
                };
              })}
            />
          </Toolbar>
          <Toolbar>
            <Toolbar>
              <Controls.Label label="Select date: " />
              <Box style={{ width: 10 }} />
              <Controls.DateRangeCustomPicker
                name="date-range"
                setDates={setDateRanges}
                dateRanges={dateRanges}
              />
            </Toolbar>
          </Toolbar>
        </Box>
        <Box style={{ marginBottom: 10 }} />
        <DataGrid
          columns={columns}
          rows={payrollData}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection={false}
          disableSelectionOnClick
          style={{ minHeight: 450, height: "auto" }}
        />
      </Paper>
    </>
  );
};

export default Staff;
