import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import Controls from '../controls/Controls'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        }
    }
}))

export default function ConfirmPopup({confirmPopup,setConfirmPopup}){
    const classes = useStyles()
    return (
        <Dialog open={confirmPopup.isOpen} className={classes.dialog}>
        <DialogTitle className={classes.dialogTitle}>
            <IconButton disableRipple className={classes.titleIcon}>
                <NotListedLocationIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <Typography variant="h6">
                {confirmPopup.title}
            </Typography>
            <Typography variant="subtitle2">
                {confirmPopup.subtitle}
            </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
            <Controls.Button
                text="No"
                color="default"
                onClick={() => setConfirmPopup({ ...confirmPopup, isOpen: false })}
                 />
            <Controls.Button
                text="Yes"
                color="secondary"
                onClick={confirmPopup.onConfirm} />
        </DialogActions>
    </Dialog>
    )
}