import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Toolbar,
  TextField,
  Divider,
  DialogActions,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/controls/button";
import Controls from "../../../components/controls/Controls";
import { baseUrl } from "../../../config/const";
import OperDialog from "./components/Dialog";
import useTable from "../../../components/useTable";
import { v4 as uuidv4 } from "uuid";

const categories = [
  {
    id: "P",
    name: "Pria",
  },
  {
    id: "W",
    name: "Wanita",
  },
  {
    id: "A",
    name: "Anak",
  },
  {
    id: "S",
    name: "Sports",
  },
  {
    id: "H",
    name: "Harian",
  },
  {
    id: "K",
    name: "Aksesoris",
  },
];

const OperStokBySKU = () => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [toko, setToko] = useState("PA2M");
  const [availableStok, setAvailableStok] = useState([]);
  const [listToko, setListToko] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    segment: { id: "W", name: "Wanita" },
    sku: "",
  };

  const [filteringContent, setFilteringContent] = useState(initialValues);

  const handleDialog = (sku, toko) => {
    setDialogOpen(!dialogOpen);

    if (!sku) {
      setSelectedProduct("");
    } else {
      setSelectedProduct(`${sku}-${toko}`);
    }
  };

  const getListKasir = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAvailableStok = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${baseUrl}/oper-stok/suggestions-by-sku`,
        {
          params: {
            toko,
            sku: filteringContent.sku,
            segment: filteringContent.segment.id,
          },
        }
      );

      setAvailableStok(data.data.qualifiedTargetProducts);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const headCells = [
    { id: "receiver", label: "Penerima", disableSorting: true },
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "stok_receiver", label: "Stok", disableSorting: true },
    {
      id: "kemunculan",
      label: "Total Hari Terbeli",
    },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(availableStok, headCells, {
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    getListKasir();
  }, []);

  useEffect(() => {
    getAvailableStok();
  }, [toko, filteringContent]);

  return (
    <Box>
      <Typography variant="h4">Pengoperan Suggestions By SKU</Typography>
      <Box style={{ height: 30 }} />
      <Box
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: matchSm ? 20 : null,
        }}
      >
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Pilih toko: " />
          <Controls.Select
            name="toko"
            value={toko}
            noneLabel={"All"}
            onChange={(e) => {
              setToko(e.target.value);
            }}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            options={listToko.map(({ name, role }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Pilih Category : " />
          <Controls.Select
            name="category"
            noNone={true}
            value={filteringContent.segment.name}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            onChange={(e) => {
              setFilteringContent({
                ...filteringContent,
                segment: categories.find((c) => c.name === e.target.value),
              });
            }}
            options={categories.map(({ name }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>

        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Cari SKU: " />
          <Controls.Input
            style={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            onChange={(e) => {
              setTimeout(() => {
                setFilteringContent({
                  ...filteringContent,
                  sku: e.target.value,
                });
              }, 1000);
            }}
            placeholder="CARI Kode ..."
          />
        </Toolbar>
      </Box>

      <Paper style={{ minHeight: 350 }}>
        {isLoading && (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 1000,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                left: "50%",
                top: "50%",
              }}
            >
              <CircularProgress size={80} />
            </div>
          </Fragment>
        )}
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                <TableCell>{item.toko}</TableCell>
                <TableCell>{item.Klkode}</TableCell>
                <TableCell>{item.stok}</TableCell>
                <TableCell>{item.kemunculan}</TableCell>

                <TableCell>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      text={"Lihat Pilihan"}
                      onClick={() => {
                        handleDialog(item.Klkode, item.toko);
                      }}
                    />
                  </Box>
                </TableCell>
                <OperDialog
                  withoutActions={true}
                  open={
                    dialogOpen &&
                    selectedProduct === `${item.Klkode}-${item.toko}`
                  }
                  title={`Oper Product ${item.Klkode} Ke ${item.toko}`}
                  //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
                  confirmText="Oper"
                  handleClose={handleDialog}
                >
                  <SuggestionModalContent
                    toko={toko}
                    sku={item.Klkode}
                    segment={filteringContent.segment.id}
                    totalSolds={item.totalPembelian}
                    occurences={item.kemunculan}
                    purchaseRate={item.purchaseRate}
                    currentStocks={item.stok}
                    handleClose={handleDialog}
                    onConfirmAction={() => {
                      handleDialog();
                    }}
                    confirmText="Oper"
                  />
                </OperDialog>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </Box>
  );
};

const SuggestionModalContent = ({
  toko,
  sku,
  segment,
  totalSolds,
  currentStocks,
  purchaseRate,
  handleClose,
  occurences,
  onConfirmAction,
  confirmText,
}) => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [availableStok, setAvailableStok] = useState([]);
  const [bufferOper, setBufferOper] = useState([]);
  const [productRank, setProductRank] = useState([]);
  const [approvedOper, setApprovedOper] = useState([]);
  const [allPossibleSuggestions, setAllPossibleSuggestions] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRankLoading, setRankLoading] = useState(false);
  const [isApprovedLoading, setApprovedLoading] = useState(false);
  const [isPossibleSugLoading, setIsPossibleSugLoading] = useState(false);
  const [productQty, setProductQty] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const suggestionHeadCells = [
    { id: "origin", label: "Pemberi", disableSorting: true },
    {
      id: "kemunculan",
      label: "Total Hari Terbeli",
      disableSorting: true,
    },

    { id: "stok_origin", label: "Stok", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const bufferHeadCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "origin", label: "Pemberi", disableSorting: true },
    { id: "stok_origin", label: "Stok", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const approvedHeadCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "origin", label: "Pemberi", disableSorting: true },
    { id: "stok_origin", label: "Stok", disableSorting: true },
    {
      id: "qty_field",
      label: "Stok Yang Dioper",
      disableSorting: true,
    },
  ];

  const rankHeadCells = [
    { id: "rank", label: "Ranking", disableSorting: true },
    { id: "target", label: "Penerima", disableSorting: true },
    { id: "sku", label: "SKU", disableSorting: true },
    {
      id: "kemunculan",
      label: "Total Hari Terbeli",
      disableSorting: true,
    },
    {
      id: "total_pembelian",
      label: "Total Terjual",
      disableSorting: true,
    },
    { id: "stok_target", label: "Stok", disableSorting: true },
  ];

  const moveProduct = async () => {
    try {
      if (!dialogOpen || !isSending) {
        return;
      }

      const productArray = bufferOper.map((buf) => ({
        date: formatDate(new Date()),
        origin: buf.origin,
        target: buf.target,
        sku: buf.Klkode,
        qty: Number(buf.qty),
        originStock: Number(buf.originStock),
        approvedAt: new Date(),
      }));

      const { data } = await axios.post(`${baseUrl}/oper-stok/suggestions`, {
        productArray,
        noDate: 1,
      });

      setNotify({
        isOpen: true,
        type: "success",
        message: data.msg,
        location: "",
      });

      setDialogOpen(false);
      setBufferOper([]);
      getApproved();
      getAvailableStok();
      getAllPossibleSuggestions();

      setIsSending(false);
    } catch (err) {
      setNotify({
        isOpen: true,
        type: "error",
        message: err.response.data.msg,
        location: "",
      });
    }
  };

  const addStok = (item) => {
    if (!productQty[item.toko] || productQty[item.toko] > item.originStock) {
      item.error = true;

      setAvailableStok([...availableStok]);
      return;
    }

    setBufferOper([
      ...bufferOper,
      {
        id: uuidv4(),
        qty: productQty[item.toko],
        origin: item.toko,
        target: toko,
        originStock: item.stok,
        ...item,
      },
    ]);

    item.error = false;
  };

  const getApproved = async () => {
    try {
      setApprovedLoading(true);
      const { data } = await axios.get(`${baseUrl}/oper-stok/records`, {
        params: {
          target: toko,
          noDate: 1,
          status: "approved",
          search: sku,
        },
      });

      setApprovedOper(data.data.movedProduct);
      setApprovedLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllPossibleSuggestions = async () => {
    try {
      setIsPossibleSugLoading(true);
      const { data } = await axios.get(
        `${baseUrl}/oper-stok/suggestions-by-sku/all-possible-options`,
        {
          params: {
            toko,
            sku,
            segment,
          },
        }
      );

      setAllPossibleSuggestions(data.data.qualifiedSuggestions);
      setIsPossibleSugLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(availableStok, suggestionHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const {
    TblHead: TblHeadAllPS,
    TblContainer: TblContainerAllPS,
    TblPagination: TblPaginationHeadAllPS,
    styledTableCell: TableCellAllPS,
    recordsAfterPagingAndSorting: allPSRecords,
  } = useTable(allPossibleSuggestions, suggestionHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const {
    TblHead: TblHeadApproved,
    TblContainer: TblContainerApproved,
    TblPagination: TblPaginationApproved,
    styledTableCell: TableCellApproved,
    recordsAfterPagingAndSorting: approvedRecords,
  } = useTable(approvedOper, approvedHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const {
    TblHead: TblHeadRank,
    TblContainer: TblContainerRank,
    TblPagination: TblPaginationRank,
    styledTableCell: TableCellRank,
    recordsAfterPagingAndSorting: rankRecords,
  } = useTable(productRank, rankHeadCells, {
    fn: (items) => {
      return items;
    },
  });

  const getAvailableStok = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}/oper-stok/suggestions`, {
        params: {
          sku,
          toko,
          segment,
          noDate: 1,
        },
      });

      if (data.data.qualifiedTargetProducts.length) {
        setAvailableStok(data.data.qualifiedTargetProducts[0].availableOrigin);
      } else {
        setAvailableStok([]);
      }

      setLoading(false);

      setProductQty(
        data.data.qualifiedTargetProducts[0].availableOrigin.reduce((a, c) => {
          a[c.toko] = 1;

          return a;
        }, {})
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getProductRank = async () => {
    try {
      setRankLoading(true);
      const { data } = await axios.get(`${baseUrl}/oper-stok/target-ranks`, {
        params: {
          sku,
          toko,
          segment,
          noDate: 1,
        },
      });

      setProductRank(data.data.qualifiedTargetProducts);
      setRankLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAvailableStok();
    getProductRank();
    getApproved();
    getAllPossibleSuggestions();
  }, []);

  useEffect(() => {
    if (isSending) {
      moveProduct();
    }
  }, [isSending]);

  return (
    <Box>
      <Divider style={{ marginBottom: 10 }} />
      <Box
        style={{
          display: "flex",
          flexWrap: matchSm ? "nowrap" : "wrap",
          gap: matchSm ? 20 : null,
        }}
      >
        <Typography
          style={{ flex: `1 1 ${!matchSm ? "100%" : "1 1 calc(100% / 3)"}` }}
          variant="h6"
        >
          SKU: {sku}
        </Typography>
        <Typography
          style={{ flex: `1 1 ${!matchSm ? "100%" : "1 1 calc(100% / 3)"}` }}
          variant="h6"
        >
          Current Stocks: {currentStocks}
        </Typography>
        <Typography
          style={{ flex: `1 1 ${!matchSm ? "100%" : "1 1 calc(100% / 3)"}` }}
          variant="h6"
        >
          Receiver: {toko}
        </Typography>
        <Typography
          style={{ flex: `1 1 ${!matchSm ? "100%" : "1 1 calc(100% / 3)"}` }}
          variant="h6"
        >
          Total Solds: {totalSolds}
        </Typography>

        <Typography
          style={{ flex: `1 1 ${!matchSm ? "100%" : "1 1 calc(100% / 3)"}` }}
          variant="h6"
        >
          Occurences: {occurences}
        </Typography>
      </Box>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />

      <Box style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        <Box style={{ flex: 1, width: "100%" }}>
          <Typography variant="body1">Suggestions</Typography>
          <Box style={{ marginBottom: 10 }} />
          <Paper style={{ position: "relative" }}>
            {isLoading && (
              <Fragment>
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.05)",
                    height: "100%",
                    width: "100%",
                    top: 0,
                    left: 0,
                    borderRadius: 5,
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    left: "50%",
                    zIndex: 10000000000,
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  <CircularProgress size={50} />
                </div>
              </Fragment>
            )}
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().length ? (
                  <>
                    {recordsAfterPagingAndSorting().map((item, index) => (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                        <TableCell>{item.toko}</TableCell>
                        <TableCell>{item.kemunculan}</TableCell>
                        <TableCell>{item.updatedStok}</TableCell>
                        <TableCell>
                          <TextField
                            error={Boolean(item.error)}
                            defaultValue={0}
                            autoComplete="off"
                            margin="dense"
                            id="qty"
                            type="number"
                            value={productQty[item.toko]}
                            onChange={(e) => {
                              const _productQty = { ...productQty };
                              _productQty[item.toko] = e.target.value;

                              setProductQty(_productQty);
                            }}
                            InputProps={{
                              inputProps: { min: 1, max: item.updatedStok },
                            }}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                right: 16,
                                color: "red",
                              },
                            }}
                            fullWidth
                            helperText={`max ${item.updatedStok}`}
                          />
                        </TableCell>

                        <TableCell>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              text={"Oper"}
                              onClick={() => {
                                addStok(item);
                                handleDialog();
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : !isLoading ? (
                  <Box style={{ margin: 10, whiteSpace: "nowrap" }}>
                    <Typography style={{ color: "rgba(0,0,0,0.4)" }}>
                      Suggestion tidak tersedia
                    </Typography>
                  </Box>
                ) : null}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
        </Box>
      </Box>

      <Box style={{ marginBottom: 20 }} />

      <Box style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        <Box style={{ flex: 1, width: "100%" }}>
          <Typography variant="body1">All Possible Suggestions</Typography>
          <Box style={{ marginBottom: 10 }} />
          <Paper style={{ position: "relative" }}>
            {isPossibleSugLoading && (
              <Fragment>
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.05)",
                    height: "100%",
                    width: "100%",
                    top: 0,
                    left: 0,
                    borderRadius: 5,
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    left: "50%",
                    zIndex: 10000000000,
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  <CircularProgress size={50} />
                </div>
              </Fragment>
            )}

            <TblContainerAllPS>
              <TblHeadAllPS />
              <TableBody>
                {allPSRecords().length ? (
                  <>
                    {allPSRecords().map((item, index) => (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                        <TableCellAllPS>{item.toko}</TableCellAllPS>
                        <TableCellAllPS>{item.kemunculan}</TableCellAllPS>
                        <TableCellAllPS>{item.stok}</TableCellAllPS>
                        <TableCellAllPS>
                          <TextField
                            error={Boolean(item.error)}
                            defaultValue={0}
                            autoComplete="off"
                            margin="dense"
                            id="qty"
                            type="number"
                            value={productQty[item.toko]}
                            onChange={(e) => {
                              const _productQty = { ...productQty };
                              _productQty[item.toko] = e.target.value;

                              setProductQty(_productQty);
                            }}
                            InputProps={{
                              inputProps: { min: 1, max: item.stok },
                            }}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                right: 16,
                                color: "red",
                              },
                            }}
                            fullWidth
                            helperText={`max ${item.stok}`}
                          />
                        </TableCellAllPS>

                        <TableCellAllPS>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              text={"Oper"}
                              onClick={() => {
                                addStok(item);
                                handleDialog();
                              }}
                            />
                          </Box>
                        </TableCellAllPS>
                      </TableRow>
                    ))}
                  </>
                ) : !isLoading ? (
                  <Box style={{ margin: 10, whiteSpace: "nowrap" }}>
                    <Typography style={{ color: "rgba(0,0,0,0.4)" }}>
                      Suggestion tidak tersedia
                    </Typography>
                  </Box>
                ) : null}
              </TableBody>
            </TblContainerAllPS>
            <TblPaginationHeadAllPS />
          </Paper>
        </Box>
      </Box>

      <Box style={{ marginBottom: 20 }} />

      <Box style={{ flex: 1, width: "100%" }}>
        <Typography variant="body1">Approved</Typography>
        <Box style={{ marginBottom: 10 }} />
        <Paper style={{ position: "relative" }}>
          {isApprovedLoading && (
            <Fragment>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(0,0,0,0.05)",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  left: 0,
                  borderRadius: 5,
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  zIndex: 10,
                  top: "50%",
                  left: "50%",
                  zIndex: 10000000000,
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress size={50} />
              </div>
            </Fragment>
          )}
          <TblContainerApproved>
            <TblHeadApproved />
            <TableBody>
              {approvedRecords().map((item, index) => (
                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                  <TableCellApproved>{item.sku}</TableCellApproved>
                  <TableCellApproved>{item.origin}</TableCellApproved>
                  <TableCellApproved>{item.origin_stock}</TableCellApproved>
                  <TableCellApproved>{item.qty}</TableCellApproved>
                </TableRow>
              ))}
            </TableBody>
          </TblContainerApproved>
          <TblPaginationApproved />
        </Paper>
      </Box>

      <Box style={{ marginBottom: 20 }} />
      <Typography variant="body1">Target Product Rank</Typography>
      <Box style={{ marginBottom: 10 }} />
      <Paper style={{ position: "relative" }}>
        {isRankLoading && (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                zIndex: 10000000000,
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={50} />
            </div>
          </Fragment>
        )}
        <TblContainerRank>
          <TblHeadRank />
          <TableBody>
            {rankRecords().map((item, index) => (
              <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                <TableCellRank>{item.rank}</TableCellRank>
                <TableCellRank>{item.toko}</TableCellRank>
                <TableCellRank>{item.Klkode}</TableCellRank>
                <TableCellRank>{item.kemunculan}</TableCellRank>
                <TableCellRank>{item.totalPembelian}</TableCellRank>
                <TableCellRank>{item.stok}</TableCellRank>
              </TableRow>
            ))}
          </TableBody>
        </TblContainerRank>
        <TblPaginationRank />
      </Paper>

      <OperDialog
        open={dialogOpen}
        title={`Apakah anda ingin mengoper produk ini ? `}
        //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
        confirmText="Confirm"
        disabledBtn={isLoading}
        handleClose={handleDialog}
        onConfirmAction={() => {
          setIsSending(true);
        }}
      />
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default OperStokBySKU;
