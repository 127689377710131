import axios from "axios";
import { baseUrl } from "../../../config/const";

export async function getFormInfo(setFilteringKeys) {
  try {
    const { data } = await axios.get(`${baseUrl}/getFormInfo`);

    setFilteringKeys({
      departments: data.results.department,
      categories: data.results.category,
    });
  } catch (e) {
    console.error(e);
  }
}
