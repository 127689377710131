import {
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../components/controls/Controls";
import RoleAdmission from "../components/roleAdmission";
import { Form, useForm } from "../components/useForm";
import AuthService from "../services/auth.service";
import useTable from "../components/useTable";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { baseUrl } from "../config/const";
import LoyaltyIcon from "@material-ui/icons/LoyaltyOutlined";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { AuthContext } from "../AppContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function InsertOnlineSales() {
  const { state } = useContext(AuthContext);
  const history = useHistory();

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const initialValues = {
    type: "-",
    confirmed: "-",
    subtotal: "",
    shippingfee: "",
    shippingcompany: "",
    paymentmethod: "",
    salesproduct: [],
    customercontact: "",
    productsku: "",
    quantity: "",
    size: "",
    price: "",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("subtotal" in fieldValues) {
      temp.subtotal = fieldValues.subtotal ? "" : "Subtotal harus diisi";
    }
    if ("shippingfee" in fieldValues) {
      temp.shippingfee = fieldValues.shippingfee
        ? ""
        : "shippingfee harus diisi";
    }
    if ("shippingcompany" in fieldValues) {
      temp.shippingcompany = fieldValues.shippingcompany
        ? ""
        : "Jasa Pengiriman harus diisi";
    }
    if ("paymentmethod" in fieldValues) {
      temp.paymentmethod = fieldValues.paymentmethod
        ? ""
        : "Methode Pembayaran harus diisi";
    }
    if ("customercontact" in fieldValues) {
      temp.customercontact = fieldValues.customercontact
        ? ""
        : "Contact Customer tidak boleh kosong";
    }
    if ("type" in fieldValues) {
      temp.type =
        fieldValues.type !== "-" ? "" : "Media Penjualan has to be filled out";
    }
    if ("confirmed" in fieldValues) {
      temp.confirmed =
        fieldValues.confirmed !== "-"
          ? ""
          : "Order Confirmation Status should be chosen";
    }
    if ("paid" in fieldValues) {
      temp.paid =
        fieldValues.paid !== "-" ? "" : "Order Paid Status should be chosen";
    }
    if ("salesproduct" in fieldValues) {
      if (fieldValues.salesproduct.length === 0) {
        temp.salesproduct = "Product Sales tidak boleh kosong cannot be empty";
      } else {
        temp.salesproduct = "";
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "size", label: "Size", disableSorting: true },
    { id: "quantity", label: "Quantity", disableSorting: true },
    { id: "price", label: "Price", disableSorting: true },
    { id: "actions", label: "actions", disableSorting: true },
  ];

  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(values.salesproduct, headCells);

  const type = [
    { id: "shopee", name: "Shopee" },
    { id: "instagram", name: "Instagram" },
    { id: "whatsapp", name: "WhatsApp" },
  ];

  const boolean = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];

  const addProduct = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      salesproduct: [
        ...values.salesproduct,
        {
          productsku: values.productsku,
          size: values.size,
          quantity: values.quantity,
          price: values.price,
        },
      ],
      productsku: "",
      size: "",
      quantity: "",
      price: "",
    });
  };

  const deleteProduct = (itemsku) => {
    setValues({
      ...values,
      salesproduct: values.salesproduct.filter((x) => x.productsku !== itemsku),
    });
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const addNewSales = async (e) => {
    e.preventDefault();
    if (validate()) {
      await axios
        .post(
          process.env.NODE_ENV === "production"
            ? `${baseUrl}/generateOfficeSales`
            : "/generateOfficeSales",
          {
            type: values.type,
            salesproduct: values.salesproduct,
            customercontact: values.customercontact,
            subtotal: values.subtotal,
            shippingfee: values.shippingfee,
            shippingcompany: values.shippingcompany,
            paymentmethod: values.paymentmethod,
            confirmed: values.confirmed,
          }
        )
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: "/sales/online",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.msg,
            type: "error",
            location: "/sales/online",
          });
        });
    }
  };

  return (
    <div>
      <h1>Insert Online Sales HappyStore.id</h1>
      <Paper
        elevation={3}
        style={{
          margin: "auto",
          padding: "20px",
        }}
      >
        <Controls.PageHeader
          title="Masukkan Penjualan Baru"
          icon={<LoyaltyIcon fontSize="large" />}
        />
        <Form encType="multipart/form-data">
          <Grid container>
            <Grid item xs={12} sm={6} style={{ paddingRight: matchSm ? 5 : 0 }}>
              <Controls.Label label="Pilih Media Penjualan" />
              <Controls.Select
                name="type"
                value={values.type}
                onChange={handleInputChange}
                options={type}
                error={errors.type}
              />

              <Controls.Label label="Customer Info: " />
              <Controls.Input
                name="customercontact"
                value={values.customercontact}
                error={errors.customercontact}
                onChange={handleInputChange}
              />

              <Controls.Label label="Add Products: " />
              <Grid container>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: !matchSm ? "column" : null,
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    <Controls.Label
                      style={{
                        fontSize: "0.8em",
                        textAlign: "initial",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      label="SKU Produk: "
                    />
                    <Controls.Input
                      name="productsku"
                      value={values.productsku}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    <Controls.Label
                      style={{
                        fontSize: "0.8em",
                        textAlign: "initial",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      label="Size: "
                    />
                    <Controls.Input
                      name="size"
                      value={values.size}
                      error={errors.size}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    <Controls.Label
                      style={{
                        fontSize: "0.8em",
                        textAlign: "initial",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      label="Quantity: "
                    />
                    <Controls.Input
                      type="number"
                      name="quantity"
                      value={values.quantity}
                      error={errors.quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <Controls.Label
                      style={{
                        fontSize: "0.8em",
                        textAlign: "initial",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                      label="Price: "
                    />
                    <Controls.Input
                      type="number"
                      name="price"
                      value={values.price}
                      error={errors.price}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <Controls.Button
                  style={{
                    width: "auto",
                    height: "auto",
                    backgroundColor: "hsl(0, 74%, 40%)",
                    fontWeight: "bold",
                  }}
                  text="Tambah Produk"
                  onClick={addProduct}
                />
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {values.salesproduct.map((item, index) => (
                      <TableRow key={index} role="checkbox" tabIndex={-1}>
                        <TableCell>{item.productsku}</TableCell>
                        <TableCell>{item.size}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>
                          <Controls.ActionButton color="secondary">
                            <CloseIcon
                              onClick={() => deleteProduct(item.productsku)}
                              fontSize="small"
                            />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <span className="error">{errors.salesproduct}</span>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingLeft: matchSm ? 5 : 0 }}>
              <Controls.Label label="Order Konfirmasi: (produk sudah ada)" />
              <Controls.Select
                name="confirmed"
                value={values.confirmed}
                onChange={handleInputChange}
                options={boolean}
                error={errors.confirmed}
              />

              <Controls.Label label="SubTotal: " />
              <Controls.Input
                type="number"
                name="subtotal"
                value={values.subtotal}
                onChange={handleInputChange}
                error={errors.subtotal}
                placeholder="Sub Total sebelum terkena shippingfee"
              />

              <Controls.Label label="Shipping Fee: " />
              <Controls.Input
                type="number"
                name="shippingfee"
                value={values.shippingfee}
                onChange={handleInputChange}
                error={errors.shippingfee}
                placeholder="shippingfee yang Kita Tanggung"
              />

              <Controls.Label label="Shipping Company: " />
              <Controls.Input
                name="shippingcompany"
                value={values.shippingcompany}
                onChange={handleInputChange}
                error={errors.shippingcompany}
                placeholder="Jasa Pengiriman"
              />

              <Controls.Label label="Metode Pembayaran: " />
              <Controls.Input
                name="paymentmethod"
                value={values.paymentmethod}
                onChange={handleInputChange}
                error={errors.paymentmethod}
                placeholder="ex: OVO, Transfer BCA,,,"
              />
            </Grid>
          </Grid>

          <Controls.Button
            text="Tambah Penjualan"
            onClick={addNewSales}
            style={{
              width: "100%",
              backgroundColor: "hsl(0, 74%, 40%)",
              fontWeight: "bold",
            }}
          />
        </Form>
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
