import { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Toolbar,
  Box,
  Paper,
  Button,
  TableRow,
  TableBody,
} from "@material-ui/core";
import ReactToPrint from "react-to-print";

import { baseUrl } from "../../../../../config/const";
import Controls from "../../../../../components/controls/Controls";
import useTable from "../../../../../components/useTable";

const GajianKetua = ({ listToko, setNotify }) => {
  const [toko, setToko] = useState("PA2M");
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const [gajianKetua, setGajianKetua] = useState([]);

  const ref = useRef();

  const getGajianKetua = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/gajianKetua`, {
        params: {
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
          toko: toko === "-" ? "" : toko,
        },
      });

      setGajianKetua(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
      console.error(err);
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const numberize = (param) => {
    return Number(param);
  };

  const rpHeadCells = [
    {
      id: "staff",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Ketua",
      width: 100,
    },
    {
      id: "komisi_ketua",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Kms",
      type: "number",
      valueGetter: (params) => numberize(params.row.komisi_ketua),
    },
    {
      id: "pedoman",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Pdm",
      type: "number",
      valueGetter: (params) => numberize(params.row.pedoman),
    },
    {
      id: "bonus_pollux",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "B-Pl",
      type: "number",
      valueGetter: (params) => numberize(params.row.bonus_pollux),
    },
    {
      id: "bonus_pedoman",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "B-Po",
      type: "number",
      valueGetter: (params) => numberize(params.row.bonus_pedoman),
    },
    {
      id: "pedoman_total",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Ttl-Pdm",
      type: "number",
      valueGetter: (params) => numberize(params.row.pedoman_total),
    },
    {
      id: "total_gaji",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "Gaji Total (IDR)",
      type: "number",
      valueGetter: (params) => numberize(params.row.total_gaji),
    },
    {
      id: "70% Gaji",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "70%",
      type: "number",
      valueGetter: (params) => numberize(params.row["70%"]),
    },
    {
      id: "30% Gaji",
      disableSorting: true,
      sortable: false,
      flex: 1.5,
      label: "30%",
      type: "number",
      valueGetter: (params) => numberize(params.row["30%"]),
    },
  ];

  const psHeadCells = [
    {
      id: "staff",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Kt",
      width: 100,
    },
    {
      id: "pollux_wanita",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "WP",
      type: "number",
      valueGetter: (params) => numberize(params.row.pollux_wanita),
    },
    {
      id: "anak",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "A",
      type: "number",
      valueGetter: (params) => numberize(params.row.anak),
    },
    {
      id: "sports",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "S",
      type: "number",
      valueGetter: (params) => numberize(params.row.sports),
    },
    {
      id: "harian",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "H",
      type: "number",
      valueGetter: (params) => numberize(params.row.harian),
    },
    {
      id: "aksesoris_kaoskaki",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "K",
      type: "number",
      valueGetter: (params) => numberize(params.row.aksesoris_kaoskaki),
    },
    {
      id: "wanita",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "W",
      type: "number",
      valueGetter: (params) => numberize(params.row.wanita),
    },
    {
      id: "pria",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "P",
      type: "number",
      valueGetter: (params) => numberize(params.row.pria),
    },

    {
      id: "tas",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "C",
      type: "number",
      valueGetter: (params) => numberize(params.row.tas),
    },
    {
      id: "pollux_pria",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "PP",
      type: "number",
      valueGetter: (params) => numberize(params.row.pollux_pria),
    },
    {
      id: "total",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Ttl",
      type: "number",
      valueGetter: (params) => numberize(params.row.total),
    },
  ];

  const {
    TblHead: PsTblHead,
    TblContainer: PsTblContainer,
    styledTableCell: PsTableCell,
    recordsAfterPagingAndSorting: rsRecordsAfterPagingAndSorting,
  } = useTable(
    gajianKetua,
    psHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  const {
    TblHead: RpTblHead,
    TblContainer: RpTblContainer,
    styledTableCell: RpTableCell,
    recordsAfterPagingAndSorting: rpRecordsAfterPagingAndSorting,
  } = useTable(
    gajianKetua,
    rpHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getGajianKetua();
    }
  }, [dateRanges, toko]);

  return (
    <>
      <Box>
        <Typography variant="h4">Gajian Ketua</Typography>
        <Box style={{ marginBottom: 10 }} />
        <Paper style={{ padding: 10 }} elevation={2}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box style={{ display: "flex", width: "100%" }}>
              <Toolbar style={{ alignItems: "center" }}>
                <Controls.Label label="Pilih toko: " />
                <Controls.Select
                  name="toko"
                  value={toko}
                  noneLabel={"All"}
                  onChange={(e) => {
                    setToko(e.target.value);
                  }}
                  otherStyle={{
                    marginBottom: 0,
                    marginLeft: 10,
                  }}
                  options={listToko.map(({ name, role }) => {
                    return {
                      id: name,
                      name: name,
                    };
                  })}
                />
              </Toolbar>
              <Toolbar style={{ padding: 0 }}>
                <Controls.Label label="Select date: " />
                <Box style={{ width: 10 }} />
                <Controls.DateRangeCustomPicker
                  name="date-range"
                  setDates={setDateRanges}
                  dateRanges={dateRanges}
                />
              </Toolbar>

              <Toolbar style={{ marginLeft: "auto", paddingRight: 0 }}>
                <Box>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outlined" color="primary">
                        Print
                      </Button>
                    )}
                    content={() => ref.current}
                    documentTitle={`ketua_${toko}_${
                      dateRanges[0]
                        ? dateRanges[0].toLocaleDateString("en-CA")
                        : ""
                    } - ${
                      dateRanges[1]
                        ? dateRanges[1].toLocaleDateString("en-CA")
                        : ""
                    }`}
                  />
                </Box>
              </Toolbar>
            </Box>
          </Box>
          <Box style={{ marginBottom: 10 }} />
          <div ref={ref}>
            <Paper style={{ padding: 5 }}>
              {dateRanges[0] && dateRanges[1] ? (
                <Typography
                  style={{
                    marginLeft: "auto",
                    textAlign: "right",
                    fontSize: 20,
                  }}
                >
                  Ketua {toko} | {formatDate(dateRanges[0])} -{" "}
                  {formatDate(dateRanges[1])}
                </Typography>
              ) : null}
              <Box style={{ height: 10 }} />
              <PsTblContainer>
                <PsTblHead />
                <TableBody>
                  {rsRecordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                    >
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.staff}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.pollux_wanita}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.anak}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.sports}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.harian}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.aksesoris_kaoskaki}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.wanita}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.pria}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.tas}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.pollux_pria}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.total}
                      </PsTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </PsTblContainer>
              <Box style={{ height: 10 }} />
              <RpTblContainer>
                <RpTblHead />
                <TableBody>
                  {rpRecordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                    >
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.staff}
                      </PsTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.komisi_ketua)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.pedoman)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.bonus_pollux)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.bonus_pedoman)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.pedoman_total)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.total_gaji)
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item["70%"])
                        )}
                      </RpTableCell>
                      <RpTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item["30%"])
                        )}
                      </RpTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </RpTblContainer>
            </Paper>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default GajianKetua;
