import React from 'react'
import '../controls/controls.css'

export default function InputFile(props){
    const {name,value,error=null,onChange} = props
    return (
        <div style={{marginBottom: "20px"}}>
        <input
        type="file" 
        name={name}
        value={value}
        onChange={onChange}
        multiple
        />
        <span className="error">{error}</span>
        </div>

    )
}