import React, { useContext, useEffect, useState, Fragment } from "react";
import Controls from "../../components/controls/Controls";
import {
  useTheme,
  useMediaQuery,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { Paper, Toolbar } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  select: {
    "& .Mui-selected": {
      backgroundColor: "#f0e051",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#f0e051",
    },
  },
});
const UserCharts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [chartData, setChartData] = useState(null);
  const [chartDataAll, setChartDataAll] = useState(null);

  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { state } = useContext(AuthContext);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [filteringContent, setFilteringContent] = useState({
    toko: ["PA2M"],
    segmentation: "",
  });

  const getGroupedSales = () => {
    const groupedData = {
      wanita: [],
      pria: [],
      anak: [],
      harian: [],
      pollux: [],
      sports: [],
      aksesoris: [],
    };
    chartData.forEach((data) => {
      if (filteringContent.segmentation) {
        if (data.wanita && filteringContent.segmentation === "Wanita") {
          groupedData.wanita.push(data);
        }
        if (data.pria && filteringContent.segmentation === "Pria") {
          groupedData.pria.push(data);
        }
        if (data.anak && filteringContent.segmentation === "Anak") {
          groupedData.anak.push(data);
        }
        if (data.harian && filteringContent.segmentation === "Harian") {
          groupedData.harian.push(data);
        }
        if (data.pollux && filteringContent.segmentation === "Pollux") {
          groupedData.pollux.push(data);
        }
        if (data.aksesoris && filteringContent.segmentation === "Aksesoris") {
          groupedData.aksesoris.push(data);
        }
        if (data.sneakers && filteringContent.segmentation === "Sports") {
          groupedData.sports.push(data);
        }
      } else {
        if (data.wanita) {
          groupedData.wanita.push(data);
        }
        if (data.pria) {
          groupedData.pria.push(data);
        }
        if (data.anak) {
          groupedData.anak.push(data);
        }
        if (data.harian) {
          groupedData.harian.push(data);
        }
        if (data.pollux) {
          groupedData.pollux.push(data);
        }
        if (data.aksesoris) {
          groupedData.aksesoris.push(data);
        }
        if (data.sneakers) {
          groupedData.sports.push(data);
        }
      }
    });

    const displayed = Object.keys(groupedData).map((data) => {
      return {
        name: data,
        total: groupedData[data].reduce((prev, curr) => {
          return ++prev;
        }, 0),
      };
    });

    const reorderedDisplayed = displayed.sort((a, b) => {
      return new Date(a.name).getTime() - new Date(b.name).getTime();
    });

    setDisplayedData([...reorderedDisplayed]);
  };

  const getUsersAnalyticsData = async (toko = [], segmentation = "") => {
    setLoading(true);
    if (toko === []) {
      toko = "";
    }

    try {
      const {
        data: { userAnalyticsData },
      } = await axios.get(
        `${baseUrl}/getUserAnalytics?toko=${toko.join(",")}&segmentation=${
          segmentation === "Sports" ? "sneakers" : segmentation
        }`
      );

      if (!toko) {
        setChartDataAll(userAnalyticsData);
      } else {
        setChartData(userAnalyticsData);
      }
      setLoading(false);
    } catch (error) {
      // setNotify({
      //   isOpen: true,
      //   type: "error",
      //   message: "Gagal mengambil data penjualan!",
      //   location: "/sales/analytics",
      // });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chartData) {
      getGroupedSales();
      if (filteringContent.toko === "-" && chartDataAll.productsSales.length) {
        setLoading(false);
      }
    }
  }, [chartData]); //eslint-disable-line

  useEffect(() => {
    if (chartDataAll) {
      if (filteringContent.toko === "-") {
        setChartData(chartDataAll);
      }
    }
  }, [chartDataAll]);

  useEffect(() => {
    getUsersAnalyticsData(filteringContent.toko, filteringContent.segmentation);
  }, []); //eslint-disable-line

  return (
    <div style={{ marginTop: 40 }} className="online-sales">
      <h1>Grafik Langganan Toko</h1>

      <Paper elevation={3} style={{ margin: "auto", position: "relative" }}>
        {isLoading && filteringContent.toko === "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} />
            </div>
          </Fragment>
        ) : null}
        {isLoading && filteringContent.toko !== "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}
        <Box display="flex" justifyContent="space-between">
          <div
            style={{
              display: "flex",
              flexDirection: !matchSm ? "column" : null,
            }}
          >
            <Toolbar
              style={{ justifyContent: !matchSm ? "space-between" : null }}
            >
              <Controls.Label label="Pilih toko: " />
              <Controls.Select
                name="toko"
                value={filteringContent.toko}
                noneLabel="All"
                MenuProps={{
                  classes: { paper: classes.select },
                }}
                multiple={true}
                onChange={(e) => {
                  setFilteringContent({
                    ...filteringContent,
                    toko: e.target.value,
                  });

                  if (e.target.value !== []) {
                    getUsersAnalyticsData(
                      e.target.value,
                      filteringContent.segmentation
                    );
                  } else {
                    setChartData(chartDataAll);
                  }
                }}
                options={state.listKasir.map(({ id, username }) => {
                  return {
                    id: username,
                    name: username,
                  };
                })}
              />
            </Toolbar>
            <Toolbar
              style={{ justifyContent: !matchSm ? "space-between" : null }}
            >
              <Controls.Label label="Pilih Segmentation : " />
              <Controls.Select
                name="segmentation"
                value={filteringContent.segmentation}
                onChange={(e) => {
                  // setLoading(true);
                  setFilteringContent({
                    ...filteringContent,
                    segmentation: e.target.value === "-" ? "" : e.target.value,
                  });
                  getUsersAnalyticsData(
                    filteringContent.toko,
                    e.target.value === "-" ? "" : e.target.value
                  );
                }}
                options={categories.map(({ name }) => {
                  return {
                    id: name,
                    name: name,
                  };
                })}
              />
            </Toolbar>
          </div>
        </Box>
        <Chart displayedData={displayedData} />
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

function YtickFormatter(value) {
  return value;
}

function XtickFormatter(value) {
  return value;
}

function tooltipFormatter(value, name) {
  return [`${value} Member`, "Total"];
}

function labelFormatter(value) {
  return value;
}

function Chart({ displayedData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={displayedData}>
        <Line
          animationDuration={500}
          type="monotone"
          dataKey="total"
          stroke="#B80000"
        />
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="name" tickFormatter={XtickFormatter} />
        <YAxis tickFormatter={YtickFormatter} />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
      </LineChart>
    </ResponsiveContainer>
  );
}

const categories = [
  {
    id: "p",
    name: "Pria",
  },
  {
    id: "w",
    name: "Wanita",
  },
  {
    id: "a",
    name: "Anak",
  },
  {
    id: "s",
    name: "Sports",
  },
  {
    id: "h",
    name: "Harian",
  },
  {
    id: "k",
    name: "Aksesoris",
  },
  {
    id: "po",
    name: "Pollux",
  },
];

export default UserCharts;
