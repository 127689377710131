import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../components/controls/Controls";
import useTable from "../components/useTable";
import { baseUrl } from "../config/const";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/CancelRounded";
import { Search } from "@material-ui/icons";
import { Form, useForm } from "../components/useForm";
import authService from "../services/auth.service";
import Modal from "react-modal";
import SalesDetails from "./SalesDetails";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../AppContext";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zindex: 1000 },
};

export function CheckShipment({ item }) {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      <Controls.Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopup(true);
        }}
        size="small"
        style={{
          backgroundColor: "transparent",
          color: "black",
          position: "relative",
          zIndex: "1000",
        }}
        text="click for info"
        startIcon={<CheckCircleIcon style={{ color: "green" }} />}
      />

      <Controls.Popup
        title={`Shipping Info of Invoice ${item.id}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={() => {}}
      >
        <Controls.Label label="Tanggal Pengiriman: " />
        <h3>{new Date(item.dateshipped).toLocaleDateString()}</h3>

        <Controls.Label label="Kurir: " />
        <h3>{item.shippingcompany}</h3>

        <Controls.Label label="No. Resi: " />
        <h3>{item.waybillnumber}</h3>
      </Controls.Popup>
    </>
  );
}

export default function OnlineSales() {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const history = useHistory();
  const { state } = useContext(AuthContext);

  const [salesRecords, setSalesRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [invoiceSelected, setInvoiceSelected] = useState("");
  const [productSales, setProductSales] = useState([]);
  const [transactionInfo, setTransactionInfo] = useState({});

  const [completeShippingModal, setCompleteShippingModal] = useState({
    isOpen: false,
    item: null,
  });

  const [confirmOrderDeliveredPopup, setConfirmOrderDeliveredPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [currentActiveUser, setCurrentActiveUser] = useState({
    username: "",
    role: "",
    email: "",
  });

  function handlePopUpOpen() {
    setOpenPopup(!openPopup);
  }

  const initialValues = {
    resi: "",
    shippingcompany: "",
    etd: "",
    dateshipped: null,
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("resi" in fieldValues) {
      temp.resi = fieldValues.resi ? "" : "Nomor Resi harus diisi";
    }
    if ("shippingcompany" in fieldValues) {
      temp.shippingcompany = fieldValues.shippingcompany
        ? ""
        : "Kurir harus diisi";
    }
    if ("etd" in fieldValues) {
      temp.etd = fieldValues.etd ? "" : "Estimated Date Delivered harus diisi";
    }
    if ("dateshipped" in fieldValues) {
      temp.dateshipped =
        fieldValues.dateshipped !== null ? "" : "Tangga Pengiriman harus diisi";
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
  } = useForm(initialValues, true, validate);

  const getOnlineSalesRecords = async () => {
    await axios
      .get(`${baseUrl}/getTransactions`)
      .then((response) => {
        setSalesRecords(response.data.records);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          type: "error",
          message: error.response.data.msg,
          location: "/sales/online",
        });
      });
  };

  const headCells = [
    { id: "invoiceid", label: "Invoice", disableSorting: true },
    { id: "date", label: "Date" },
    { id: "amount", label: "Amount", disableSorting: true },
    { id: "type", label: "Tempat Belanja", disableSorting: true },
    { id: "confirmed", label: "Confirmed", disableSorting: true },
    { id: "shipped", label: "Shipped", disableSorting: true },
    { id: "delivered", label: "Delivered", disableSorting: true },
    { id: "completed", label: "Completed", disableSorting: true },
  ];

  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(salesRecords, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => x.id.includes(target.value));
        }
      },
    });
  };

  const confirmTransactionProducts = async (invoiceid) => {
    await axios
      .post(`${baseUrl}/confirmTransactionProducts`, {
        invoiceid: invoiceid,
      })
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/sales/online",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/sales/online",
        });
      });
  };

  async function getProductSales(invoiceid, setTransactionStatus) {
    try {
      const { data } = await axios.get(
        `${baseUrl}/getProductSales/${invoiceid}`
      );

      setTransactionStatus(data.records);
    } catch (e) {
      console.error(e);
    }
  }

  async function getTransactionInfo(invoiceid, setTransactionInfo) {
    try {
      const { data } = await axios.get(
        `${baseUrl}/getTransactionInfo/${invoiceid}`
      );
      setTransactionInfo(data.records[0]);
    } catch (e) {
      console.error(e);
    }
  }

  const completeShipping = async (item) => {
    if (validate()) {
      await axios
        .post(`${baseUrl}/confirmShipping/${item?.id}`, {
          waybillNumber: values.resi,
          dateshipped: new Date(values.dateshipped).toLocaleDateString(),
          shippingcompany: values.shippingcompany,
          etd: values.etd,
          customeremail: item?.customercontact,
        })
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: "/sales/online",
          });
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: err.response.data.msg,
            type: "error",
            location: "sales/online",
          });
        });
    }
  };

  const setOrderDelivered = async (item) => {
    await axios
      .put(`${baseUrl}/setOrderDelivered/${item?.id}`)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/sales/online",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/sales/online",
        });
      });
  };

  useEffect(() => {
    getOnlineSalesRecords();
  }, []);

  useEffect(() => {
    if (openPopup) {
      getProductSales(invoiceSelected, setProductSales);
      getTransactionInfo(invoiceSelected, setTransactionInfo);
    }
  }, [openPopup]);

  return (
    <div className="online-sales">
      <Controls.Popup
        title={"Online Sales Details"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        invoice={invoiceSelected}
        onClose={() => {}}
      >
        {productSales.length && JSON.stringify(transactionInfo) !== "{}" ? (
          <SalesDetails
            invoice={invoiceSelected}
            cart={productSales}
            shippingFee={transactionInfo.shippingfee}
            shippingAddress={{
              address: transactionInfo.address1,
              city: transactionInfo.city,
              state: transactionInfo.state,
              zipcode: transactionInfo.zipcode,
              firstname: transactionInfo.firstname,
              lastname: transactionInfo.lastname,
              phone: transactionInfo.phone,
            }}
            totalPrice={transactionInfo.total}
            subtotalPrice={transactionInfo.subtotal}
          />
        ) : null}
      </Controls.Popup>

      <h1>Online Sales HappyStore.id</h1>
      <Paper elevation={3} style={{ margin: "auto" }}>
        <Toolbar style={{ justifyContent: !matchSm ? "space-between" : null }}>
          <Controls.Label label="Search: " />
          <Controls.Input
            inputprops={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            placeholder="CARI Nomor Invoice..."
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handlePopUpOpen();
                    setInvoiceSelected(item.id);
                  }}
                >
                  {item.id}
                </TableCell>
                <TableCell>
                  {item.date.substr(0, item.date.indexOf("T"))}
                </TableCell>
                <TableCell>Rp {item.subtotal}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>
                  {String(item.confirmed) === "true" ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <div>
                      <Controls.Button
                        // disabled={currentActiveUser.role === 'admin' && true}
                        size="small"
                        text="confirmed ?"
                        startIcon={<CancelIcon style={{ color: "red" }} />}
                        onClick={() =>
                          setConfirmPopup({
                            ...confirmPopup,
                            isOpen: true,
                            title: `Confirm Products of ${item.id}?`,
                            subtitle:
                              "Tidak bisa mengganti status konfirmasi transaksi lagi",
                            onConfirm: () => {
                              confirmTransactionProducts(item.id);
                            },
                          })
                        }
                        style={{
                          backgroundColor: "hsla(41, 96%, 50%, 1)",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {String(item.shipped) === "true" ? (
                    <div>
                      <CheckShipment item={item} />
                    </div>
                  ) : (
                    <div>
                      <Controls.Button
                        disabled={String(item.confirmed) === "false" && true}
                        size="small"
                        startIcon={<CancelIcon style={{ color: "red" }} />}
                        text="shipped ?"
                        style={{
                          backgroundColor: "hsla(41, 96%, 50%, 1)",
                          fontWeight: "bold",
                          color: "white",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          setCompleteShippingModal({
                            isOpen: true,
                            item: item,
                          })
                        }
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {String(item.delivered) === "true" ? (
                    <div>
                      <CheckCircleIcon style={{ color: "green" }} />
                    </div>
                  ) : (
                    <div>
                      <Controls.Button
                        disabled={String(item.shipped) === "false" && true}
                        size="small"
                        startIcon={<CancelIcon style={{ color: "red" }} />}
                        text="delivered?"
                        style={{
                          backgroundColor: "hsla(41, 96%, 50%, 1)",
                          fontWeight: "bold",
                          color: "white",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          setConfirmOrderDeliveredPopup({
                            ...confirmOrderDeliveredPopup,
                            isOpen: true,
                            title: `Apakah order ${item.id} sudah terkirim?`,
                            subtitle:
                              "Tidak bisa mengganti status pengiriman lagi",
                            onConfirm: () => {
                              setOrderDelivered(item);
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {/* //  <CancelIcon style={{color:'red'}} /> */}
                </TableCell>
                <TableCell>
                  {String(item.completed) === "true" ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />

      <Controls.ConfirmPopup
        confirmPopup={confirmOrderDeliveredPopup}
        setConfirmPopup={setConfirmOrderDeliveredPopup}
      />

      <Modal isOpen={completeShippingModal.isOpen} style={modalStyles}>
        <div className="title">
          Complete Shipping Info of Invoice {completeShippingModal.item?.id}
        </div>
        <Form encType="multipart/form-data">
          <div style={{ padding: "10px" }}>
            <Controls.Label label="No Resi:" />
            <Controls.Input
              name="resi"
              value={values.resi}
              onChange={handleInputChange}
              error={errors.resi}
            />

            <Controls.Label label="Shipping Date" />
            <Controls.DatePicker
              name="dateshipped"
              selected={values.dateshipped}
              onChange={(date) => handleDatePicker("dateshipped", date)}
              error={errors.dateshipped}
            />

            <Controls.Label label="Kurir: " />
            <Controls.Input
              name="shippingcompany"
              values={values.shippingcompany}
              onChange={handleInputChange}
              error={errors.shippingcompany}
            />

            <Controls.Label label="Estimated Date Delivered" />
            <Controls.Input
              name="etd"
              value={values.etd}
              placeholder="ex: 2-3 hari"
              onChange={handleInputChange}
              error={errors.etd}
            />
          </div>

          <div style={{ marginLeft: "25%" }}>
            <Controls.Button
              color="primary"
              text="Pesanan sudah dikirm"
              onClick={() => completeShipping(completeShippingModal.item)}
            />
            <Controls.Button
              color="secondary"
              text="Cancel"
              onClick={() => setCompleteShippingModal(!completeShippingModal)}
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
}
