import React, { useState, useEffect, useContext } from "react";
import { Form, useForm } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import Axios from "axios";
import "../../styles/global.css";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Grid,
  InputAdornment,
  Toolbar,
  makeStyles,
  Paper,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginBottom: "40px",
  },
  newButton: {},
  pageContentTitle: {
    marginBottom: "10px",
  },
}));

function Brand() {
  const { state, dispatch } = useContext(AuthContext);
  const headCells = [
    { id: "id", label: "id", minwidth: 80 },
    { id: "name", label: "name", minwidth: 120 },
    { id: "supplier", label: "supplier", minwidth: 120, disableSorting: true },
    { id: "actions", label: "actions", disableSorting: true },
  ];

  const [suppliers, setSuppliers] = useState([]);
  //fn here is the parameter for the funciton, and the items carry the records
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const initialValues = {
    name: "",
    supplier: "-",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "brand must be filled out";
    }
    if ("supplier" in fieldValues) {
      temp.supplier =
        fieldValues.supplier !== "-" ? "" : "Supplier has to be filled out";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  /**
   * return results of useForm helper method
   * @param initialValues determines the initial value of each state
   * @param true boolean is allowing validating while onChange
   * @param validate is helper method of allowing element to validate while onChange
   */
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const getBrand = async () => {
    await Axios.get(`${baseUrl}/getBrand`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            brandList: response.data.brand,
          },
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
        });
      });
  };
  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.brandList, headCells, filterFn);

  const getSuppliers = async () => {
    await Axios.get(`${baseUrl}/getSupplier`)
      .then((response) => {
        setSuppliers(response.data.suppliers);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getBrand();
    getSuppliers();
  }, []);

  const addNewBrand = async (e) => {
    e.preventDefault();
    if (validate()) {
      await Axios.post(`${baseUrl}/addBrand`, {
        name: values.name,
        supplier: values.supplier,
      })
        .then((response) => {
          resetForm();
          setNotify({
            message: response.data.msg,
            isOpen: true,
            type: "success",
            location: "/products/controlProducts",
          });
        })
        .catch((err) => {
          setNotify({
            message: err.response.data.msg,
            isOpen: true,
            type: "error",
            location: "/products/controlProducts",
          });
        });
    }
  };
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };

  const deleteItem = async (id) => {
    await Axios.delete(`${baseUrl}/remove/brand/${id}`)
      .then((response) => {
        setNotify({
          message: response.data.msg,
          isOpen: true,
          type: "success",
          location: "/products/controlProducts",
        });
        setConfirmPopup({
          ...confirmPopup,
          isOpen: false,
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
          location: "/products/controlProducts",
        });
      });
  };

  const classes = useStyles();
  return (
    <div className="components">
      <div className="formContainer">
        <h3 className={classes.pageContentTitle}>Brand List</h3>
        <Paper className={classes.pageContent}>
          <Toolbar>
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="Search any Brand Here"
              onChange={handleSearch}
            />
            <Controls.Button
              text="Add New Brand"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </Toolbar>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record) => (
                <TableRow key={record.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{record.id}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell>{record.supplier}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmPopup({
                          isOpen: true,
                          title: "Are you sure to delete this item?",
                          subtitle: "You can't undo the operation",
                          onConfirm: () => {
                            deleteItem(record.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      </div>

      <Controls.Popup
        title="add or edit brand"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form encType="multipart/form-data" onSubmit={addNewBrand}>
          <Grid container>
            <Grid item xs>
              <div style={{ padding: "10px" }}>
                <Controls.Label label="Brand" />
                <Controls.Input
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  placeholder="enter brand name"
                />
                <Controls.Label label="Supplier" />
                <Controls.Select
                  name="supplier"
                  value={values.supplier}
                  onChange={handleInputChange}
                  options={suppliers}
                  error={errors.supplier}
                />
                <Controls.Button
                  color="primary"
                  text="Add New Brand"
                  type="submit"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Controls.Popup>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}

function Category() {
  const { state, dispatch } = useContext(AuthContext);
  const headCells = [
    { id: "id", label: "id", minwidth: 80 },
    { id: "name", label: "name", minwidth: 120 },
    { id: "actions", label: "actions", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const initialValues = {
    name: "",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name =
        fieldValues.name.length !== 0 ? "" : "company name must be filled out";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  /**
   * return results of useForm helper method
   * @param initialValues determines the initial value of each state
   * @param true boolean is allowing validating while onChange
   * @param validate is helper method of allowing element to validate while onChange
   */
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleFileInputChange,
    checkboxSelected,
    handleCheckBoxInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.categoryList, headCells, filterFn);

  const getCategory = async () => {
    await Axios.get(`${baseUrl}/getCategory`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            categoryList: response.data.category,
          },
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const addNewCategory = async (e) => {
    e.preventDefault();
    if (validate()) {
      const addCategory = await Axios.post(`${baseUrl}/addCategory`, {
        name: values.name,
      })
        .then((response) => {
          resetForm();
          setNotify({
            message: response.data.msg,
            isOpen: true,
            type: "success",
            location: "/products/controlProducts",
          });
        })
        .catch((err) => {
          setNotify({
            message: err.response.data.msg,
            isOpen: true,
            type: "error",
            location: "/products/controlProducts",
          });
        });
    }
  };
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };
  const deleteItem = async (id) => {
    await Axios.delete(`${baseUrl}/remove/category/${id}`)
      .then((response) => {
        setNotify({
          message: response.data.msg,
          isOpen: true,
          type: "success",
          location: "/products/controlProducts",
        });
        setConfirmPopup({
          ...confirmPopup,
          isOpen: false,
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
          location: "/products/controlProducts",
        });
      });
  };
  const classes = useStyles();

  return (
    <div className="components">
      <div className="formContainer">
        <h3 className={classes.pageContentTitle}>Category List</h3>
        <Paper className={classes.pageContent}>
          <Toolbar>
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="search category here..."
            />
            <Controls.Button
              text="Add New Category"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </Toolbar>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record) => (
                <TableRow key={record.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{record.id}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmPopup({
                          isOpen: true,
                          title: "Are you sure to delete this item?",
                          subtitle: "You can't undo the operation",
                          onConfirm: () => {
                            deleteItem(record.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      </div>
      <Controls.Popup
        title="add or edit category"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form encType="multipart/form-data" onSubmit={addNewCategory}>
          <Grid container>
            <Grid item xs>
              <div style={{ padding: "10px" }}>
                <Controls.Label label="Category" />
                <Controls.Input
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  placeholder="enter category name"
                />
              </div>
              <Controls.Button
                color="primary"
                text="Add New Category"
                type="submit"
              />
            </Grid>
          </Grid>
        </Form>
      </Controls.Popup>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}

function Color() {
  const { state, dispatch } = useContext(AuthContext);
  const headCells = [
    { id: "id", label: "id", minwidth: 80 },
    { id: "name", label: "name", minwidth: 120 },
    { id: "actions", label: "actions", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [openModal, setOpenModal] = useState(false);

  const initialValues = {
    name: "",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name =
        fieldValues.name.length !== 0 ? "" : "company name must be filled out";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  /**
   * return results of useForm helper method
   * @param initialValues determines the initial value of each state
   * @param true boolean is allowing validating while onChange
   * @param validate is helper method of allowing element to validate while onChange
   */
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleFileInputChange,
    checkboxSelected,
    handleCheckBoxInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.colorList, headCells, filterFn);

  const getColor = async () => {
    const colors = await Axios.get(`${baseUrl}/getColor`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            colorList: response.data.color,
          },
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getColor();
  }, []);

  const addNewColor = async (e) => {
    e.preventDefault();
    if (validate()) {
      const addColor = await Axios.post(`${baseUrl}/addColor`, {
        name: values.name,
      })
        .then((response) => {
          resetForm();
          setNotify({
            message: response.data.msg,
            isOpen: true,
            type: "success",
            location: "/products/controlProducts",
          });
        })
        .catch((err) => {
          setNotify({
            message: err.response.data.msg,
            isOpen: true,
            type: "error",
            location: "/products/controlProducts",
          });
        });
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };
  const deleteItem = async (id) => {
    await Axios.delete(`${baseUrl}/remove/color/${id}`)
      .then((response) => {
        setNotify({
          message: response.data.msg,
          isOpen: true,
          type: "success",
          location: "/products/controlProducts",
        });
        setConfirmPopup({
          ...confirmPopup,
          isOpen: false,
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
          location: "/products/controlProducts",
        });
      });
  };
  const classes = useStyles();

  return (
    <div className="components">
      <div className="formContainer">
        <h3 className={classes.pageContentTitle}>Color List</h3>
        <Paper className={classes.pageContent}>
          <Toolbar>
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="search color here..."
            />
            <Controls.Button
              text="Add New Color"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record) => (
                <TableRow key={record.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{record.id}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmPopup({
                          isOpen: true,
                          title: "Are you sure to delete this item?",
                          subtitle: "You can't undo the operation",
                          onConfirm: () => {
                            deleteItem(record.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      </div>

      <Controls.Popup
        title="add new color"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form encType="multipart/form-data" onSubmit={addNewColor}>
          <Grid container>
            <Grid item xs>
              <div style={{ padding: "10px" }}>
                <Controls.Label label="Color" />
                <Controls.Input
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  placeholder="enter color name"
                />
                <Controls.Button
                  color="primary"
                  text="Add New Color"
                  type="submit"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}

function Department() {
  const { state, dispatch } = useContext(AuthContext);

  const headCells = [
    { id: "id", label: "id", minwidth: 80 },
    { id: "name", label: "name", minwidth: 120 },
    { id: "actions", label: "actions", disableSorting: true },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const initialValues = {
    name: "",
  };

  /**
   * validation function for each element
   * @return error of each element whether it passes the validation process
   */
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name =
        fieldValues.name.length !== 0 ? "" : "company name must be filled out";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  /**
   * return results of useForm helper method
   * @param initialValues determines the initial value of each state
   * @param true boolean is allowing validating while onChange
   * @param validate is helper method of allowing element to validate while onChange
   */
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleFileInputChange,
    checkboxSelected,
    handleCheckBoxInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.departmentList, headCells, filterFn);

  const getDepartment = async () => {
    await Axios.get(`${baseUrl}/getDepartment`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            departmentList: response.data.department,
          },
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
        });
      });
  };

  const addNewDepartment = async (e) => {
    e.preventDefault();
    if (validate()) {
      const addDepartment = await Axios.post(`${baseUrl}/addDepartment`, {
        name: values.name,
      })
        .then((response) => {
          resetForm();
          setNotify({
            message: response.data.msg,
            isOpen: true,
            type: "success",
            location: "/products/controlProducts",
          });
        })
        .catch((err) => {
          setNotify({
            message: err.response.data.msg,
            isOpen: true,
            type: "error",
            location: "/products/controlProducts",
          });
        });
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.name.toLowerCase().includes(target.value)
          );
        }
      },
    });
  };
  const deleteItem = async (id) => {
    await Axios.delete(`${baseUrl}/remove/department/${id}`)
      .then((response) => {
        setNotify({
          message: response.data.msg,
          isOpen: true,
          type: "success",
          location: "/products/controlProducts",
        });
        setConfirmPopup({
          ...confirmPopup,
          isOpen: false,
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
          location: "/products/controlProducts",
        });
      });
  };
  useEffect(() => {
    getDepartment();
  }, []);

  const classes = useStyles();

  return (
    <div className="components">
      <div className="formContainer">
        <h3 className={classes.pageContentTitle}>Department List</h3>
        <Paper className={classes.pageContent}>
          <Toolbar>
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="search department here..."
            />
            <Controls.Button
              text="Add New Department"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
              }}
            />
          </Toolbar>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record) => (
                <TableRow key={record.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{record.id}</TableCell>
                  <TableCell>{record.name}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmPopup({
                          isOpen: true,
                          title: "Are you sure to delete this item?",
                          subtitle: "You can't undo the operation",
                          onConfirm: () => {
                            deleteItem(record.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      </div>
      <Controls.Popup
        title="add or edit department"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form encType="multipart/form-data" onSubmit={addNewDepartment}>
          <Grid container>
            <Grid item xs>
              <div style={{ padding: "10px" }}>
                <Controls.Label label="Department" />
                <Controls.Input
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  placeholder="enter department name"
                />
              </div>
            </Grid>
          </Grid>
          <Controls.Button
            color="primary"
            text="Add New Department"
            type="submit"
          />
        </Form>
      </Controls.Popup>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}

export default function ControlProduct() {
  const classes = useStyles();
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.root}>
      <h1>Components</h1>
      <Grid
        container
        style={{ display: !matchSm ? "block" : null }}
        spacing={3}
      >
        <Grid item xs={12} sm={6}>
          <Brand />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Color />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Department />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Category />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
                    <Occasion />
                </Grid> */}
      </Grid>
    </div>
  );
}
