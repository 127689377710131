import { useState } from "react";

import { Paper, Box, Divider } from "@material-ui/core";

import { useDateRangePicker } from "../../utils/hooks/useDateRangePicker";
import useDataByPeriods from "../../utils/hooks/useDataByPeriods";
import GajianSplitRange from "./components/GajianSplitRange";
import GajianFullRange from "./components/GajianFullRange";

const GajianStaff = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const { dateRanges, DateRangePicker } = useDateRangePicker();

  const { dataByPeriods } = useDataByPeriods({
    dateRanges,
    setNotify,
  });

  return (
    <Paper style={{ padding: 10 }}>
      <GajianSplitRange
        dataByPeriods={dataByPeriods}
        DateRangePicker={DateRangePicker}
      />
      <Box style={{ height: 80 }} />
      <Divider />
      <Box style={{ height: 70 }} />
      <GajianFullRange
        dataByPeriods={dataByPeriods}
        DateRangePicker={DateRangePicker}
      />
    </Paper>
  );
};

export default GajianStaff;
