import { Paper, Box } from "@material-ui/core";
import KPIMember from "./KPIMember";
import UserCharts from "./UserCharts";

const MemberAnalysis = () => {
  return (
    <Paper style={{ padding: 20 }}>
      <UserCharts />
      <Box style={{ height: 20 }} />
      <KPIMember />
    </Paper>
  );
};

export default MemberAnalysis;
