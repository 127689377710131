import { useContext, useEffect, useState } from "react";
import {
  Paper,
  Toolbar,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Controls from "../components/controls/Controls";
import { Search } from "@material-ui/icons";
import axios from "axios";
import { AuthContext } from "../AppContext";
import useTable from "../components/useTable";
import { TableBody, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const kategoriProduk = [
  { key: "W", name: "Wanita" },
  { key: "P", name: "Pria" },
  { key: "A", name: "Anak" },
  { key: "PO", name: "Pollux" },
  { key: "H", name: "Harian" },
  { key: "S", name: "Sneakers" },
  { key: "K", name: "Aksesoris" },
];

const Wishlist = () => {
  const { state } = useContext(AuthContext);
  const history = useHistory();

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [wishlists, setWishlists] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [search, setSearch] = useState("");

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    { id: "size", label: "Invoice", disableSorting: true },
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "date", label: "Date" },
  ];

  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(wishlists, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setSearch(target.value.toLowerCase());
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.sku.toLowerCase().includes(target.value.toLowerCase())
          );
        }
      },
    });
  };

  const getWishlist = async () => {
    const { data } = await axios.get(
      `https://cashier-happystore.xyz.notoputrooffice.com/getCustomerWishlist/${selectedStore}`
    );

    setWishlists(data.wishlist);
  };

  useEffect(() => {
    getWishlist();
  }, [selectedStore]);

  return (
    <div>
      <h1>Wishlist</h1>
      <Paper elevation={3} style={{ margin: "auto" }}>
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Toolbar
            style={{
              justifyContent: !matchSm ? "space-between" : null,
              flexGrow: 1,
              flexShrink: 1,
            }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              style={{ margin: 0, marginLeft: 10 }}
              placeholder="CARI Sku ..."
            />
          </Toolbar>
          <Toolbar
            style={{
              minHeight: 0,
              marginBottom: !matchSm ? 20 : undefined,
              justifyContent: !matchSm ? "space-between" : null,
              flexGrow: 1,
              flexShrink: 1,
            }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={selectedStore}
              onChange={(e) => {
                setSelectedStore(e.target.value);
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
              otherStyle={{
                margin: 0,
                marginLeft: 10,
              }}
              style={{ minWidth: 120, padding: 0 }}
            />
          </Toolbar>

          <Toolbar
            style={{
              minHeight: 0,
              marginBottom: !matchSm ? 20 : undefined,
              justifyContent: !matchSm ? "space-between" : null,
              flexGrow: 1,
              flexShrink: 1,
            }}
          >
            <Controls.Label label="Filter kategori: " />
            <Controls.Select
              name="toko"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setFilterFn({
                  fn: (items) => {
                    if (e.target.value === "-") {
                      return items;
                    } else {
                      return items.filter((item) => {
                        return (
                          item.sku[0].toLowerCase() ===
                            e.target.value.toLowerCase() &&
                          item.sku.toLowerCase().includes(search)
                        );
                      });
                    }
                  },
                });
              }}
              options={kategoriProduk.map((kategori) => {
                return {
                  id: kategori.key,
                  name: kategori.name,
                };
              })}
              otherStyle={{
                margin: 0,
                marginLeft: 10,
              }}
              style={{ minWidth: 120, padding: 0 }}
            />
          </Toolbar>
        </div>

        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ cursor: "pointer" }}
              >
                <TableCell>{item.sku}</TableCell>
                <TableCell>{item.size}</TableCell>
                <TableCell>
                  {new Date(item.daterequested).toLocaleDateString("id", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "2-digit",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
};

export default Wishlist;
