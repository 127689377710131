import React, { useEffect, useState, useContext } from "react";
import Controls from "../components/controls/Controls";
import useTable from "../components/useTable";
import Axios from "axios";
import {
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import { ComputerSharp, Search } from "@material-ui/icons";
import { baseUrl } from "../config/const";
import { useForm } from "../components/useForm";
import moment from "moment";
import DeletePromo from "./management/deletePromo";
import UpdatePromo from "./management/updatePromo";
import { AuthContext } from "../AppContext";
import Vouchers from "./voucher";

import { useTheme, useMediaQuery } from "@material-ui/core";

export function TambahPromo() {
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const initialValues = {
    code: "",
    type: "-",
    name: "",
    label: "",
    startdate: null,
    enddate: null,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("code" in fieldValues) {
      temp.code = fieldValues.code ? "" : "Kode promo harus diisi";
    }
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Nama promo harus diisi";
    }
    if ("label" in fieldValues) {
      temp.label = fieldValues.label ? "" : "Label promo harus diisi";
    }
    if ("type" in fieldValues) {
      temp.type = fieldValues.type !== "-" ? "" : "Tipe promo harus dipilih";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);
  const typeOptions = [
    { id: "DISC", name: "Discount" },
    { id: "OB", name: "Obral" },
    { id: "NORMAL", name: "Harga Normal" },
  ];
  const addNewPromotion = async (req, res) => {
    await Axios.post(`${baseUrl}/addNewPromotion`, {
      code: values.code,
      type: values.type,
      name: values.name,
      label: values.label,
      startdate: values.startdate
        ? new Date(values.startdate).toLocaleDateString()
        : null,
      enddate: values.enddate
        ? new Date(values.enddate).toLocaleDateString()
        : null,
    })
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.msg,
          type: "success",
          location: "/marketing/promotion",
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };
  return (
    <div>
      <Controls.Button
        onClick={() => setOpenPopup(!openPopup)}
        style={{ backgroundColor: "rgb(177, 27, 27)" }}
        text="Tambah Promo Baru"
      />

      <Controls.Popup
        title="Tambah promo baru"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Controls.Label label="Masukkan Kode Promo" />

        <Controls.Input
          name="code"
          value={values.code}
          onChange={handleInputChange}
          error={errors.code}
        />

        <Controls.Label label="Jenis Promosi" />

        <Controls.Select
          name="type"
          value={values.type}
          onChange={handleInputChange}
          options={typeOptions}
          error={errors.type}
        />

        <Controls.Label label="Masukkan Nama Promo" />
        <Controls.Input
          name="name"
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />

        <Controls.Label label="Masukkan Label Promo" />
        <Controls.Input
          placeholder="ex: untuk promo 20%, tulis 20%"
          name="label"
          value={values.label}
          onChange={handleInputChange}
          error={errors.label}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "10px",
            }}
          >
            <Controls.Label label="Start Date: " />
            <Controls.DatePicker
              name="startdate"
              selected={values.startdate}
              onChange={(date) => handleDatePicker("startdate", date)}
            />
            <div>{moment(values.startdate).format("LL")}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingInline: "10px",
            }}
          >
            <Controls.Label label="End Date: " />
            <Controls.DatePicker
              name="enddate"
              minDate={values.startdate}
              selected={values.enddate}
              onChange={(date) => handleDatePicker("enddate", date)}
            />
            <div>{moment(values.enddate).format("LL")}</div>
          </div>
        </div>
        <br />
        <span style={{ display: "flex", justifyContent: "center" }}>
          **Kosongkan start date dan end date bila promo tidak ada jangka waktu
        </span>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Controls.Button text="Tambah Promo" onClick={addNewPromotion} />
        </div>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export function CheckProducts({ code }) {
  const [openPopup, setOpenPopup] = useState(false);
  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "color", label: "Color", disableSorting: true },
    { id: "price3", label: "Harga Barcode", disableSorting: true },
    { id: "price4", label: "Harga Promo", disableSorting: true },
  ];
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => x.sku.includes(target.value));
        }
      },
    });
  };
  const getPromoProducts = async () => {
    await Axios.get(`${baseUrl}/getPromoProducts/${code}`)
      .then((response) => {
        setRecords(response.data.records);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };
  return (
    <div>
      <Controls.Button
        text="Lihat Produk"
        style={{
          backgroundColor: "lightblue",
          backgroundColor: "rgb(177, 27, 27)",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpenPopup(!openPopup);
          getPromoProducts();
        }}
      />
      <Controls.Popup
        title={`List Produk Promo ${code}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Paper elevation={1}>
          <Toolbar>
            <Controls.Label label="Cari: " />
            <Controls.Input
              onChange={handleSearch}
              placeholder="CARI KODE BARCODE"
            />
          </Toolbar>
          <TblContainer>
            <TblHead />
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.sku} hover role="checkbox" tabIndex={-1}>
                <TableCell>{item.sku}</TableCell>
                <TableCell>{item.color}</TableCell>
                {/* <TableCell>{item.category}</TableCell> */}
                <TableCell>{item.price3}</TableCell>
                <TableCell>{item.price4}</TableCell>
              </TableRow>
            ))}
          </TblContainer>
          <TblPagination />
        </Paper>
      </Controls.Popup>
    </div>
  );
}

export default function Promotions() {
  const { state, dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const headCells = [
    { id: "lihat", label: "", disableSorting: true },
    { id: "code", label: "Code", disableSorting: true },
    { id: "name", label: "Name", disableSorting: true },
    { id: "startdate", label: "Start Date", disableSorting: true },
    { id: "enddate", label: "End Date", disableSorting: true },
    { id: "type", label: "Type", disableSorting: true },
    { id: "actions", label: "actions", disableSorting: true },
  ];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.promotionList, headCells, filterFn);

  const getAllPromotions = async (req, res) => {
    await Axios.get(`${baseUrl}/getAllPromotions`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            promotionList: response.data.records,
          },
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };

  useEffect(() => {
    getAllPromotions();
  }, []);
  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            paddingRight: matchSm ? 5 : 0,
            marginBottom: !matchSm ? 10 : 0,
          }}
        >
          <Paper style={{ margin: "auto" }} elevation={3}>
            <h1>List Promo</h1>

            <Toolbar>
              <TambahPromo />
            </Toolbar>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.code} hover role="checkbox" tabIndex={-1}>
                    <TableCell>
                      <CheckProducts code={item.code} />
                    </TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {item.startdate === null
                        ? null
                        : new Date(item.startdate).toDateString()}
                    </TableCell>
                    <TableCell>
                      {item.enddate === null
                        ? null
                        : new Date(item.enddate).toDateString()}
                    </TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>
                      <UpdatePromo
                        item={item}
                        location="/marketing/promotion"
                      />
                      <DeletePromo
                        param={item.code}
                        location="/marketing/promotion"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingLeft: matchSm ? 5 : 0 }}>
          <Vouchers />
        </Grid>
      </Grid>

      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
