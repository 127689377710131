import React, { useState, useEffect, useContext } from "react";
import "../../styles/global.css";
import {
  Paper,
  Toolbar,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import Axios from "axios";
import useTable from "../../components/useTable";
import { Search } from "@material-ui/icons";
import DetailProduct from "./detail";
import WarningIcon from "@material-ui/icons/Warning";
import ProductCompletion from "../management/completion";
import ProductDeletion from "../management/deletion";
import { baseUrl } from "../../config/const";
import ArchiveProduct from "../management/archiveProduct";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveProduct from "../management/unarchiveProduct";
import { AuthContext } from "../../AppContext";

export default function ArchivedProducts() {
  const { state, dispatch } = useContext(AuthContext);

  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "brand", label: "Brand" },
    { id: "pname", label: "Product Name", disableSorting: true },
    { id: "color", label: "Color", disableSorting: true },
    { id: "department", label: "Department", disableSorting: true },
    { id: "category", label: "Category", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "actions", label: "Actions", disableSorting: true },
  ];
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [detailItem, setDetailItem] = useState({});

  /**
   * return results of useForm helper method
   * @param records are the data from db
   * @param headCells header of the table
   * @param filterFn filtering function that carries the records
   */
  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(state.archivedProductList, headCells, filterFn);

  /**
   * @returns records of all products
   */
  const getArchivedProducts = async () => {
    await Axios.get(`${baseUrl}/getArchivedProducts`)
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            archivedProductList: response.data.records,
          },
        });
      })
      .catch((err) => {
        setNotify({
          message: "error",
          isOpen: true,
          type: "error",
        });
      });
  };
  /**
   *
   * @param {*} e indicates the search event
   */
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((x) => x.sku.includes(target.value));
        }
      },
    });
  };
  useEffect(() => {
    getArchivedProducts();
  }, []);

  return (
    <div className="archived-product">
      <h1>Archived Product List</h1>
      <Paper elevation={2}>
        <Toolbar>
          <Controls.Label label="Search: " />
          <Controls.Input
            inputprops={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            placeholder="CARI KODE BARCODE..."
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.sku} hover role="checkbox" tabIndex={-1}>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenPopup(true);
                    setDetailItem(item);
                  }}
                >
                  {item.sku}
                </TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.pname}</TableCell>
                <TableCell>{item.color}</TableCell>
                <TableCell>{item.department}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell style={{ color: "#F9D71C" }}>
                  <ArchiveIcon fontSize="small" />
                  {item.status}
                </TableCell>
                <TableCell>
                  <UnarchiveProduct
                    param={item.sku}
                    location="/products/archivedProducts"
                  />
                  <ProductDeletion
                    param={item.sku}
                    location="/products/archivedProducts"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Controls.Popup
        title="Product Detail"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <DetailProduct item={detailItem} />
      </Controls.Popup>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
    </div>
  );
}
