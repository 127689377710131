import React, { useContext, useEffect, useState, Fragment } from "react";
import Controls from "../../components/controls/Controls";
import {
  useTheme,
  useMediaQuery,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { Paper, Toolbar } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";
import { useForm } from "../../components/useForm";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SalesCharts = ({ dateRanges, chartPeriod }) => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [chartData, setChartData] = useState(null);
  const [chartDataAll, setChartDataAll] = useState(null);

  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { state } = useContext(AuthContext);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [filteringContent, setFilteringContent] = useState({
    toko: "PA2M",
    segmentation: "",
  });

  const initialValues = {
    segmentation: "",
    toko: "PA2M",
  };

  const validate = () => false;

  const { values, handleInputChange } = useForm(initialValues, validate);

  const getGroupedSales = () => {
    const groupedData = {};
    chartData.productsSales.forEach((data) => {
      const date = new Date(data.Kltgklr).toLocaleDateString("en-CA");

      if (groupedData[date] === undefined) {
        groupedData[date] = [];
      }

      groupedData[date].push(data);
    });

    const displayed = Object.keys(groupedData).map((data) => {
      return {
        name: data,
        total: groupedData[data].reduce((prev, curr) => {
          return ++prev;
        }, 0),
      };
    });

    const reorderedDisplayed = displayed.sort((a, b) => {
      return new Date(a.name).getTime() - new Date(b.name).getTime();
    });

    console.log(reorderedDisplayed);

    setDisplayedData([...reorderedDisplayed]);
  };

  const getSalesRecords = async (toko = "", segmentation = "") => {
    if (!dateRanges[1]) {
      return;
    }
    setLoading(true);
    if (toko === "-") {
      toko = "";
    }

    try {
      const monthlyDate = new Date(
        dateRanges[1].getFullYear(),
        dateRanges[1].getMonth(),
        dateRanges[1].getDate()
      ).toLocaleDateString("en-CA");
      const {
        data: { data },
      } = await axios.get(
        `${baseUrl}/getKPIPenjualan?toko=${toko}&startdate=${dateRanges[0].toLocaleDateString(
          "en-CA"
        )}&enddate=${dateRanges[1].toLocaleDateString(
          "en-CA"
        )}&segmentation=${segmentation}&period=${
          dateRanges[1].getDate() < 30 && dateRanges[1].getDate() !== 28
            ? chartPeriod
            : "custom"
        }`
      );

      if (!toko) {
        setChartDataAll(data);
      } else {
        setChartData(data);
      }
      setLoading(false);
    } catch (error) {
      setNotify({
        isOpen: true,
        type: "error",
        message: "Gagal mengambil data penjualan!",
        location: "/sales/analytics",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chartData) {
      getGroupedSales();
      if (filteringContent.toko === "-" && chartDataAll.productsSales.length) {
        setLoading(false);
      }
    }
  }, [chartData]); //eslint-disable-line

  useEffect(() => {
    if (filteringContent.toko === "-") {
      setLoading(false);
    }

    getSalesRecords(filteringContent.toko, filteringContent.segmentation);
  }, [dateRanges]); //eslint-disable-line

  useEffect(() => {
    if (chartDataAll) {
      if (filteringContent.toko === "-") {
        setChartData(chartDataAll);
      }
    }
  }, [chartDataAll]);

  useEffect(() => {
    getSalesRecords("-", filteringContent.segmentation);
  }, []); //eslint-disable-line

  return (
    <div className="online-sales">
      <h1>Grafik Penjualan Berdasarkan Segment</h1>

      <Paper elevation={3} style={{ margin: "auto", position: "relative" }}>
        {isLoading && filteringContent.toko === "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} />
            </div>
          </Fragment>
        ) : null}
        {isLoading && filteringContent.toko !== "All" ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.05)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}
        <Box display="flex" justifyContent="space-between">
          <div
            style={{
              display: "flex",
              flexDirection: !matchSm ? "column" : null,
            }}
          >
            <Toolbar
              style={{
                justifyContent: !matchSm ? "space-between" : null,
              }}
            >
              <Controls.Label label="Pilih toko: " />
              <Controls.Select
                name="toko"
                value={filteringContent.toko}
                noneLabel={"All"}
                onChange={(e) => {
                  setLoading(true);
                  setFilteringContent({
                    ...filteringContent,
                    toko: e.target.value,
                  });
                  if (e.target.value !== "-") {
                    getSalesRecords(
                      e.target.value,
                      filteringContent.segmentation
                    );
                  } else {
                    setChartData(chartDataAll);
                  }
                }}
                options={state.listKasir
                  .map(({ username, role }) => {
                    return {
                      id: username,
                      name: username,
                      role,
                    };
                  })
                  .filter((opt) => opt.role === "toko")}
              />
            </Toolbar>
            <Toolbar
              style={{ justifyContent: !matchSm ? "space-between" : null }}
            >
              <Controls.Label label="Pilih Segmentation : " />
              <Controls.Select
                name="segmentation"
                value={values.segmentation}
                onChange={(e) => {
                  handleInputChange(e);
                  // setLoading(true);
                  setFilteringContent({
                    ...filteringContent,
                    segmentation:
                      e.target.value === "Aksesoris"
                        ? "k"
                        : e.target.value === "-"
                        ? ""
                        : e.target.value[0],
                  });
                  getSalesRecords(
                    filteringContent.toko,
                    e.target.value === "Aksesoris"
                      ? "k"
                      : e.target.value === "-"
                      ? ""
                      : e.target.value[0]
                  );
                }}
                options={categories.map(({ name }) => {
                  return {
                    id: name,
                    name: name,
                  };
                })}
              />
            </Toolbar>
          </div>
          {chartData !== null ? (
            <SimpleCard
              counts={chartData.qty}
              percentage={chartData.performancePercentage}
              period={chartPeriod}
              countsCompared={chartData.previousQty}
              comparedDates={chartData.comparedDateRanges}
            />
          ) : null}
        </Box>
        <Chart displayedData={displayedData} />
        {chartData !== null ? (
          <div style={{ textAlign: "right", padding: 16 }}>
            <strong>Total Omzet</strong> : Rp{" "}
            {new Intl.NumberFormat(["ban", "id"]).format(
              parseInt(chartData.totalOmzet)
            )}
          </div>
        ) : null}
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

function YtickFormatter(value) {
  return value;
}

function XtickFormatter(value) {
  const dateArr = new Date(value).toDateString().split(" ");
  value = `${dateArr[2]} ${dateArr[1]}`;

  return value;
}

function tooltipFormatter(value, name) {
  value = new Intl.NumberFormat(["ban", "id"]).format(parseInt(value));

  return [`${value} Products`, "Total"];
}

function labelFormatter(value) {
  return `${new Date(value).toDateString(value)}`;
}

function Chart({ displayedData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={displayedData}>
        <Line
          animationDuration={500}
          type="monotone"
          dataKey="total"
          stroke="#B80000"
        />
        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
        <XAxis dataKey="name" tickFormatter={XtickFormatter} />
        <YAxis tickFormatter={YtickFormatter} />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
      </LineChart>
    </ResponsiveContainer>
  );
}

const categories = [
  {
    id: "p",
    name: "Pria",
  },
  {
    id: "w",
    name: "Wanita",
  },
  {
    id: "a",
    name: "Anak",
  },
  {
    id: "s",
    name: "Sports",
  },
  {
    id: "h",
    name: "Harian",
  },
  {
    id: "k",
    name: "Aksesoris",
  },
];

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    margin: 24,
  },
  title: {
    fontSize: 14,
  },
});

function SimpleCard({
  counts = 0,
  countsCompared = 0,
  percentage = 0,
  period,
  comparedDates = null,
}) {
  const classes = useStyles();

  switch (period) {
    case "monthly":
      period = "bulan";
      break;
    case "weekly":
      period = "minggu";
      break;
    default:
      period = "periode";
  }

  const dateDiff =
    period === "periode"
      ? (new Date(comparedDates.end).getTime() -
          new Date(comparedDates.start).getTime() +
          86400000) /
        86400000
      : null;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary">
          +{counts} Products | +{countsCompared} ({comparedDates.start} -{" "}
          {comparedDates.end})
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          style={{ color: percentage > 0 ? "#4caf50" : "#b71c1c" }}
        >
          {percentage >= 0 ? "+" : null}
          {Math.floor(percentage)}% dari {period} sebelumnya
          {dateDiff ? ` (${dateDiff} hari)` : null}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SalesCharts;
