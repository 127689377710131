import { Paper, Toolbar, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { baseUrl } from "../../config/const";
import { AuthContext } from "../../AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useTheme, Box, Card, CardContent, CardMedia } from "@material-ui/core";
import { useHistory } from "react-router-dom/";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function KPISales() {
  const { state, dispatch } = useContext(AuthContext);
  const [allStorePerformance, setAllStorePerformance] = useState([]);

  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const history = useHistory();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const getListKasir = async () => {
    try {
      const { data: dataKasir } = await axios.get(
        `${baseUrl}/getPoinPenjualan`
      );

      dispatch({
        type: "UPDATE_LIST",
        payload: {
          listKasir: dataKasir.listKasir,
        },
      });
    } catch (err) {
      console.error(err);
      setNotify({
        isOpen: true,
        type: "error",
        message: err.response.data.msg,
        location: "",
      });
    }
  };

  const columns = [
    {
      field: "toko",
      headerName: "Toko",
      width: 100,
      sortable: false,
    },
    {
      field: "omzet",
      headerName: "Omzet",
      width: matchSm ? 500 : 350,
      sortable: false,
      renderCell: (params) => (
        <Card
          style={{
            padding: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              maxWidth: 500,
              // flexDirection: matchSm ? "row" : "column",
            }}
          >
            <Box
              style={{
                flex: 1,
                maxWidth: matchSm ? null : "75%",
              }}
            >
              <CardMedia>
                <CircularProgressbar
                  value={Math.floor(params.row.omzetTargetPercentage)}
                  text={`${Math.floor(params.row.omzetTargetPercentage)}%`}
                  styles={buildStyles({
                    pathColor: "#b80000",
                    textColor: "#b80000",
                  })}
                />
              </CardMedia>
            </Box>
            <CardContent
              style={{
                display: "flex",
                flex: 3,
                flexWrap: "wrap",
                gap: 10,
                paddingBottom: 0,
                paddingTop: 0,
                paddingRight: 0,
              }}
            >
              <Box style={{ textAlign: "left", flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  style={{ fontWeight: "bold" }}
                >
                  Target
                </Typography>
                <Typography variant="body2">
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(params.row.omzet_target)
                  )}
                </Typography>
              </Box>
              <Box style={{ textAlign: "left", flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  style={{ fontWeight: "bold" }}
                >
                  Current
                </Typography>
                <Typography variant="body2">
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(params.row.currentOmzet)
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Card>
      ),
    },
    {
      field: "omzetScore",
      headerName: "Score (Omzet)",
      width: 180,
      sortable: true,
      type: "number",
      valueGetter: (params) => Math.floor(params.row.omzetScore),
    },
    {
      field: "pollux",
      headerName: "Pollux",
      width: matchSm ? 500 : 350,
      sortable: false,
      renderCell: (params) => (
        <Card
          style={{
            padding: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              maxWidth: 500,
              // flexDirection: matchSm ? "row" : "column",
            }}
          >
            <Box
              style={{
                flex: 1,
                maxWidth: matchSm ? null : "75%",
              }}
            >
              <CardMedia>
                <CircularProgressbar
                  value={Math.floor(params.row.polluxTargetPercentage)}
                  text={`${Math.floor(params.row.polluxTargetPercentage)}%`}
                  styles={buildStyles({
                    pathColor: "#b80000",
                    textColor: "#b80000",
                  })}
                />
              </CardMedia>
            </Box>
            <CardContent
              style={{
                display: "flex",
                flex: 3,
                flexWrap: "wrap",
                gap: 10,
                paddingBottom: 0,
                paddingTop: 0,
                paddingRight: 0,
              }}
            >
              <Box style={{ textAlign: "left", flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  style={{ fontWeight: "bold" }}
                >
                  Target
                </Typography>
                <Typography variant="body2">
                  {params.row.polluxPairsTarget}
                </Typography>
              </Box>
              <Box style={{ textAlign: "left", flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  style={{ fontWeight: "bold" }}
                >
                  Sold
                </Typography>
                <Typography variant="body2">
                  {params.row.totalPolluxSold}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Card>
      ),
    },
    {
      field: "polluxScore",
      headerName: "Score (Pol)",
      width: 150,
      sortable: true,
      type: "number",
      align: !matchSm ? "left" : "right",
      headerAlign: !matchSm ? "left" : "right",
      valueGetter: (params) => Math.floor(params.row.polluxScore),
    },
  ];

  const getAllStorePerformance = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/getAllStorePerformances`, {
        params: {
          period: new Date(
            dateRanges[0].getFullYear(),
            dateRanges[0].getMonth(),
            2
          )
            .toISOString()
            .split("T")[0],
        },
      });

      setAllStorePerformance(data);
    } catch (err) {
      setNotify({
        isOpen: true,
        type: "error",
        message: err.response.data.msg,
        location: "",
      });
      setAllStorePerformance([]);
    }
  };

  useEffect(() => {
    getListKasir();
  }, []); //eslint-disable-line

  useEffect(() => {
    getAllStorePerformance();
  }, [dateRanges]); //eslint-disable-line

  return (
    <div className="online-sales">
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        KPI Sales
      </Typography>
      <Paper
        style={{
          margin: "auto",
          minHeight: 325,
          position: "relative",
          padding: 20,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          {/* <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={filteringContent.toko}
              noneLabel={"All"}
              onChange={(e) => {
                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value,
                });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
            />
          </Toolbar> */}
          <Toolbar style={{ justifyContent: "space-between" }}>
            <Controls.Label label="Pilih period: " />

            <Controls.MonthPicker
              name="month"
              setDates={setDateRanges}
              chartPeriod="monthly"
              dateRanges={dateRanges}
            />
          </Toolbar>
        </div>
        <Box style={{ height: 800 }}>
          <DataGrid
            rowHeight={150}
            rows={allStorePerformance}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
