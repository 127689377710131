import React, { useEffect, useState, useContext } from "react";
import Controls from "../components/controls/Controls";
import { Form, useForm } from "../components/useForm";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import Axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Grid, Paper } from "@material-ui/core";
import { baseUrl } from "../config/const";
import BannerList from "./bannerList";
import { AuthContext } from "../AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export default function UploadBanner() {
  const { state, dispatch } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useState("");
  const [completeProducts, setCompleteProducts] = useState([]);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const initialValues = {
    images: [],
    code: "",
    title: "",
    description: "",
    type: "-",
    sku: "",
    startdate: null,
    enddate: null,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    var fileName = "";
    if ("images" in fieldValues) {
      if (fieldValues.images.length !== 0) {
        [...fieldValues.images].map((file) => {
          fileName = file.name;
        });
        if (fileName && fileName.indexOf(" ") >= 0) {
          temp.images = "File name shouldn't have spaces in between";
        } else if (fieldValues.images.length > 4) {
          temp.images = "Maximum of 4 banners/thumbnails in one upload";
        } else if (fieldValues.images.length === 0) {
          temp.images = "Banner/Thumbnails can't be null";
        } else {
          temp.images = "";
        }
      }
    }
    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : "Title shouldn't be empty";
    }
    if ("description" in fieldValues) {
      temp.description = fieldValues.description
        ? ""
        : "Description shouldn't be empty";
    }
    if ("code" in fieldValues) {
      temp.code = fieldValues.code ? "" : "Code shouldn't be empty";
    }
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("enddate" in fieldValues) {
      temp.enddate = fieldValues.enddate ? "" : "Start Date shouldn't be empty";
    }
    if ("type" in fieldValues) {
      temp.type = fieldValues.type !== "-" ? "" : "Tipe banner harus dipilih";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDatePicker,
    handleFileInputChange,
  } = useForm(initialValues, true, validate);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const formData = new FormData();
  for (const key of Object.keys(values.images)) {
    formData.append("images", values.images[key]);
  }
  formData.append("code", values.code);
  formData.append("title", values.title);
  formData.append("description", values.description);
  // formData.append('startdate',values.startdate)
  formData.append("startdate", new Date(values.startdate).toLocaleDateString());

  formData.append("enddate", new Date(values.enddate).toLocaleDateString());
  formData.append("type", values.type);
  formData.append("sku", values.sku);

  const uploadBanner = (e) => {
    e.preventDefault();
    if (validate()) {
      Axios.post(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/uploadBanner`
          : `/uploadBanner`,
        formData
      )
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: "/marketing/banner",
          });
          getBanners();
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: err.response.data.msg,
            type: "error",
            location: "/marketing/banner",
          });
        });
    }
  };

  const getCompleteProducts = async () => {
    await Axios.get(
      process.env.NODE_ENV === "production"
        ? `${baseUrl}/getCompleteProducts`
        : `/getCompleteProducts`
    )
      .then((response) => {
        setCompleteProducts(response.data.records);
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
        });
      });
  };

  const getBanners = () => {
    Axios.get(
      process.env.NODE_ENV === "production"
        ? `${baseUrl}/getBanners`
        : `/getBanners`
    )
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            bannerList: response.data.records,
          },
        });
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/",
        });
      });
  };

  const typeOptions = [
    { id: "banner", name: "Banner" },
    { id: "gallery", name: "Gallery" },
    { id: "thumbnail", name: "Thumbnail" },
  ];

  // useEffect(() => {
  //     getCompleteProducts()
  // },[])

  // const [searchedResults,setSearchedResults] = useState(completeProducts)
  // const handleSearch = (searched) => {
  //     // let target = e.target
  //     setSearchedResults(completeProducts => completeProducts.filter((x) => x.sku.includes(searched)))
  //     return searchedResults
  // }
  // useEffect(() => {
  //     if(!searchParams){
  //         setCompleteProducts([])
  //     }else{
  //         setCompleteProducts(handleSearch())
  //     }},[searchParams])

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      <h1>Manage Banner</h1>
      <Grid style={{ flexDirection: !matchSm ? "column" : null }} container>
        <Grid style={{ maxWidth: matchSm ? "38%" : null }} item xs>
          <Paper elevation={2} style={{ padding: "20px" }}>
            <Controls.PageHeader
              title="Upload Banner and Thumbnails"
              subTitle="Banner and Thumbnails for HappyStore.id"
              icon={<WallpaperIcon fontSize="large" />}
            />

            <Form encType="multipart/form-data" onSubmit={uploadBanner}>
              <Grid container>
                <Grid item md={12}>
                  <Controls.Label label="Banner/Thumbails: " />
                  <Controls.InputFile
                    name="images"
                    onChange={handleFileInputChange}
                    error={errors.images}
                  />
                  {values.images &&
                    [...values.images].map((file, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <ul>
                          <li key={index}>{file.name}</li>
                        </ul>
                      </div>
                    ))}

                  <Controls.Label label="Tipe: " />
                  <Controls.Select
                    name="type"
                    value={values.type}
                    onChange={handleInputChange}
                    error={errors.type}
                    options={typeOptions}
                  />

                  <Controls.Label label="Kode Promosi: " />
                  <Controls.Input
                    name="code"
                    value={values.code}
                    onChange={handleInputChange}
                    error={errors.code}
                    placeholder="ex: 22710"
                  />

                  <Controls.Label label="SKU Produk: (bila perlu)" />
                  <Controls.Input
                    name="sku"
                    value={values.sku}
                    onChange={handleInputChange}
                    error={errors.sku}
                  />

                  {/* <input
                            value={searchParams}
                            onChange={e => {
                                setSearchParams(e.target.value)
                                handleSearch(searchParams)
                            }} />

                            <div style={{width:'50%',backgroundColor:'white'}}>
                            <ul>
                                {completeProducts.map((item) => (
                                    <li style={{width:'100%',height:'auto',backgroundColor:'lightgray',padding:'5px',margin:'10px'}} key={item.sku}>{item.sku}</li>
                                ))}
                            </ul>
                            </div> */}

                  <Controls.Label label="Title: " />
                  <Controls.Input
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                    error={errors.title}
                    placeholder="ex: Promo 17 Agustus Merdeka"
                  />

                  <Controls.Label label="Description: " />
                  <Controls.TextArea
                    name="description"
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "48%",
                      }}
                    >
                      <Controls.Label label="Start Date: " />
                      <Controls.DatePicker
                        name="startdate"
                        minDate={new Date()}
                        selected={values.startdate}
                        onChange={(date) => handleDatePicker("startdate", date)}
                        error={errors.startdate}
                      />
                      <div>{moment(values.startdate).format("LL")}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "48%",
                      }}
                    >
                      <Controls.Label label="End Date: " />
                      <Controls.DatePicker
                        name="enddate"
                        minDate={values.startdate}
                        selected={values.enddate}
                        onChange={(date) => handleDatePicker("enddate", date)}
                        error={errors.enddate}
                      />
                      <div>{moment(values.enddate).format("LL")}</div>
                    </div>
                  </div>
                  <Controls.Button
                    text="Submit"
                    type="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(177, 27, 27)",
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Grid>
        <Grid style={{ maxWidth: matchSm ? "60%" : null }} item xs>
          <Paper evelvation={2}>
            <BannerList records={state.bannerList} getBanners={getBanners} />
          </Paper>
        </Grid>
      </Grid>

      <Controls.Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
