import { useContext, useEffect, useState } from "react";
import {
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  TextField,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import TeamTokoDialog from "./TeamTokoDialog";
import { AuthContext } from "../AppContext";
import { baseUrl } from "../config/const";
import axios from "axios";
import Controls from "../components/controls/Controls";

const roles = [
  { value: "ketua", label: "Ketua" },
  { value: "staff", label: "Staff" },
  { value: "penggantiketua", label: "Pengganti Ketua" },
  { value: "trainee", label: "Trainee" },
];

const active = [
  { value: true, label: "true" },
  { value: false, label: "false" },
];

const jenistidur = [
  { value: "luar", label: "Luar" },
  { value: "dalam", label: "Dalam" },
];

const CardListItem = ({
  getMembers,
  member,
  underlineRow,
  alreadyPresence,
  listToko,
}) => {
  const [memberModal, setMemberModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cutiModal, setCutiModal] = useState(false);
  const [disableCutiModal, setDisableCutiModal] = useState(false);

  const handleMemberModal = () => {
    setMemberModal(!memberModal);
  };

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleCutiModal = (member) => {
    console.log(member);
    if (!member.cuti) {
      if (cutiModal === true) {
        setCutiFields({ alasan: "" });
      }
      setCutiModal(!cutiModal);
    } else {
      setDisableCutiModal(!disableCutiModal);
    }
  };

  const [editMemberFields, setEditMemberFields] = useState({
    username: "",
    role: "",
    toko: "",
    password: "",
    jenistidur: "",
    active: false,
  });

  const [cutiFields, setCutiFields] = useState({ alasan: "" });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const editMember = async (staff_username, staff_toko) => {
    const { username, password, role, toko, active, jenistidur } =
      editMemberFields;
    try {
      const { data } = await axios.put(
        `${baseUrl}/staff?_username=${staff_username}`,
        {
          username,
          password,
          role,
          toko: toko ? toko : staff_toko,
          active,
          jenistidur,
        }
      );

      console.log(data);
      handleMemberModal();
      setEditMemberFields({
        username: "",
        role: "",
        toko: "",
        password: "",
        active: false,
      });
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const cutiMember = async ({ staff, tanggal, alasan, toko, cuti, role }) => {
    try {
      const { data } = await axios.post(`${baseUrl}/insertAbsensi`, {
        staff,
        tanggal,
        alasan,
        toko,
        role,
      });

      console.log(data);
      handleCutiModal({ cuti });
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const disableCutiStaff = async ({ username: staff, cuti }) => {
    console.log("cuti", cuti);
    try {
      await axios.put(`${baseUrl}/disableStaffCuti`, {
        staff,
      });

      handleCutiModal({ staff, cuti });
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const deleteMember = async (username) => {
    try {
      const { data } = await axios.delete(`${baseUrl}/staff`, {
        data: { username },
      });

      setNotify({
        message: data.msg,
        isOpen: true,
        type: "success",
        location: "/hrd",
      });

      handleDeleteModal();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  return (
    <ListItem style={{ marginBottom: 10 }}>
      <ListItemText
        primary={member.username}
        style={{
          textDecoration: underlineRow ? "underline" : null,
          textDecorationColor: "red",
        }}
      />

      <ListItemSecondaryAction>
        <IconButton
          onClick={() => {
            setEditMemberFields({
              username: member.username,
              role: member.role,
              toko: member.toko,
              password: member.password,
            });

            handleMemberModal();
          }}
        >
          <Edit />
        </IconButton>
        {/* <Button
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          style={{
            backgroundColor: member.cuti ? "rgba(0, 0, 0, 0.12)" : "#b80000",
            marginRight: 10,
          }}
          onClick={handleCutiModal}
          disabled={member.cuti}
        >
          CUTI
        </Button> */}

        <FormControlLabel
          value="cuti"
          control={
            <Switch
              disabled={alreadyPresence}
              name="toggle-cuti"
              color="primary"
              checked={member.cuti}
              onChange={() => {
                handleCutiModal(member);
              }}
            />
          }
          label="Cuti"
          labelPlacement="start"
        />
      </ListItemSecondaryAction>

      <TeamTokoDialog
        open={cutiModal}
        handleClose={handleCutiModal}
        title={`Apakah staff ${member.username} akan mengambil cuti?`}
        onConfirmAction={() => {
          cutiMember({
            staff: member.username,
            tanggal: new Date().toISOString().split("T")[0],
            alasan: cutiFields.alasan,
            toko: member.toko,
            cuti: member.cuti,
            role: member.role,
          });
        }}
        confirmText="Cuti"
      >
        <TextField
          defaultValue={cutiFields.alasan}
          autoComplete="off"
          margin="dense"
          id="name"
          label="Alasan"
          type="text"
          fullWidth
          onChange={(e) =>
            setCutiFields({
              ...cutiFields,
              alasan: e.target.value,
            })
          }
        />
      </TeamTokoDialog>

      <TeamTokoDialog
        open={disableCutiModal}
        handleClose={() => {
          console.log("closed");
          handleCutiModal(member);
        }}
        title={`Apakah anda ingin melepaskan status cuti untuk ${member.username}?`}
        onConfirmAction={() => {
          disableCutiStaff(member);
        }}
        confirmText="Lepas Cuti"
      ></TeamTokoDialog>
      <TeamTokoDialog
        open={deleteModal}
        handleClose={handleDeleteModal}
        title={`Apakah anda ingin hapus ${member.username} dari ${member.toko}?`}
        onConfirmAction={() => {
          deleteMember(member.username);
        }}
        confirmText="Delete"
      ></TeamTokoDialog>
      <TeamTokoDialog
        open={memberModal}
        handleClose={handleMemberModal}
        title="Edit data anggota"
        onConfirmAction={() => {
          editMember(member.username, member.toko);
        }}
        confirmText="Edit"
      >
        <Typography>
          {`Last updated at
          ${
            member.updatedat
              ? new Date(member.updatedat).toLocaleString("en-GB")
              : " - never"
          } `}
        </Typography>
        <TextField
          defaultValue={member.username}
          autoComplete="off"
          margin="dense"
          id="name"
          label="ID Nama"
          type="text"
          fullWidth
          onChange={(e) =>
            setEditMemberFields({
              ...editMemberFields,
              username: e.target.value,
            })
          }
        />
        <TextField
          defaultValue={member.jenistidur}
          autoComplete="off"
          margin="dense"
          id="jenistidur"
          label="Jenis Tidur"
          fullWidth
          select
          onChange={(e) =>
            setEditMemberFields({
              ...editMemberFields,
              jenistidur: e.target.value,
            })
          }
        >
          {jenistidur.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          defaultValue={member.role}
          margin="dense"
          id="role"
          label="Role"
          fullWidth
          select
          onChange={(e) =>
            setEditMemberFields({
              ...editMemberFields,
              role: e.target.value,
            })
          }
        >
          {roles.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          defaultValue={member.toko}
          autoComplete="off"
          margin="dense"
          id="toko"
          label="Toko"
          type="text"
          select
          fullWidth
          onChange={(e) =>
            setEditMemberFields({
              ...editMemberFields,
              toko: e.target.value,
            })
          }
        >
          {listToko.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          defaultValue={member.active}
          margin="dense"
          id="active"
          label="Active"
          fullWidth
          select
          onChange={(e) =>
            setEditMemberFields({
              ...editMemberFields,
              active: e.target.value,
            })
          }
        >
          {active.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </TeamTokoDialog>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </ListItem>
  );
};

const TeamCard = ({
  toko = "TOKO",
  teamMembers = [1, 2, 3, 5],
  getMembers,
  cardStyle,
  listToko,
}) => {
  const [presensiModal, setPresensiModal] = useState(false);
  const [alreadyPresence, setAlreadyPresence] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const handlePresensiModal = () => {
    setPresensiModal(!presensiModal);
  };

  const sendPresensi = async (toko) => {
    try {
      await axios.post(`${baseUrl}/insertPresensi`, {
        toko,
        tanggal: new Date().toISOString().split("T")[0],
      });

      handlePresensiModal();
      checkPresensi(toko);
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const checkPresensi = async (toko) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/checkPresensi`, {
        params: { toko, tanggal: new Date().toISOString().split("T")[0] },
      });

      if (!data.presensi) {
        for (let staff of teamMembers) {
          if (staff.cuti) {
            await axios.put(`${baseUrl}/disableStaffCuti`, {
              staff: staff.username,
              tanggal: new Date().toLocaleDateString("en-CA"),
            });
          }
        }
      }

      setAlreadyPresence(data.presensi);
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  useEffect(() => {
    checkPresensi(toko);
  }, []);

  return (
    <Card>
      <CardContent style={{ position: "relative", ...cardStyle }}>
        {/* <Button
          variant="contained"
          disableElevation
          color="primary"
          style={{
            position: "absolute",
            right: 12,
            backgroundColor: alreadyPresence
              ? "rgba(0, 0, 0, 0.12)"
              : "#0FB350",
          }}
          onClick={handlePresensiModal}
          disabled={alreadyPresence}
        >
          Presensi
        </Button> */}
        <Typography variant="h4">{toko}</Typography>
        <Divider />
        <List dense>
          {teamMembers
            .filter((member) => member.active === true)
            .sort((a, b) => {
              if (b.role.toLowerCase() === "ketua") {
                return 1;
              } else if (a.role.toLowerCase() === "ketua") {
                return -1;
              } else if (a.username > b.username) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((member, index) => (
              <CardListItem
                alreadyPresence={alreadyPresence}
                getMembers={getMembers}
                member={member}
                key={member.id}
                underlineRow={
                  member.role.toLowerCase() === "ketua" ||
                  member.role.toLowerCase() === "penggantiketua"
                }
                listToko={listToko}
              />
            ))}
        </List>
      </CardContent>
      <TeamTokoDialog
        open={presensiModal}
        handleClose={() => {
          handlePresensiModal();
        }}
        title={`Apakah anda ingin mengisi data presensi untuk toko ${toko}?`}
        onConfirmAction={() => {
          sendPresensi(toko);
        }}
        confirmText="kirim"
      ></TeamTokoDialog>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </Card>
  );
};

export default TeamCard;
