import React,{useState} from 'react'
import CloseIcon from '@material-ui/icons/Close';
import Controls from '../../components/controls/Controls'
import Axios from 'axios'
import { baseUrl } from '../../config/const';

export default function ProductDeletion({productStatus,param,location}){
    const [confirmPopup,setConfirmPopup] = useState({isOpen:false,title:'',subtitle:''})
    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})

    /**
     * 
     * @param {*} sku indicates the param of the item
     */
        const deleteItem = async (param) => {
            await Axios.delete(
                process.env.NODE_ENV === 'production' ? 
                    `${baseUrl}/deleteProduct/${param}` :
                    `/deleteProduct/${param}` 
            )
            .then((response) => {
                setNotify({
                    message: response.data.msg,
                    isOpen: true,
                    type : 'success',
                    location : location
                })
                setConfirmPopup({
                    ...confirmPopup,
                    isOpen: false
                })
            })
            .catch((err) => {
                setNotify({
                    message:err.response.data.msg,
                    isOpen:true,
                    type : 'error',
                    location:location
                })
            })
        }
    return (
        <>
            <Controls.ActionButton 
            color="secondary"
            onClick={() => {
                setConfirmPopup({
                    isOpen:true,
                    title :`Are you sure to delete ${param}?`,
                    subtitle :"You can't undo the operation",
                    onConfirm : () => { deleteItem(param)}
                })
            }}>
                <CloseIcon fontSize="medium"/>
            </Controls.ActionButton>   

            <Controls.Notification
                notify={notify}
                setNotify={setNotify} />
            <Controls.ConfirmPopup
                confirmPopup={confirmPopup}
                setConfirmPopup={setConfirmPopup} />
        </>
    )
}