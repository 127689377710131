import { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Box,
  TextField,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { AddCircleRounded } from "@material-ui/icons";
import axios from "axios";
import TeamCard from "./TeamCard";
import TeamTokoDialog from "./TeamTokoDialog";
import { baseUrl } from "../config/const";
import { AuthContext } from "../AppContext";
import Controls from "../components/controls/Controls";

const roles = [
  { value: "ketua", label: "Ketua" },
  { value: "staff", label: "Staff" },
  { value: "penggantiketua", label: "Pengganti Ketua" },
  { value: "trainee", label: "Trainee" },
];

const jenistidur = [
  { value: "luar", label: "Luar" },
  { value: "dalam", label: "Dalam" },
];

const TeamToko = () => {
  const [teams, setTeams] = useState({});
  const [members, setMembers] = useState([]);
  const [memberModal, setMemberModal] = useState(false);
  const [listToko, setListToko] = useState([]);
  const { state } = useContext(AuthContext);
  const history = useHistory();

  const matchSm = useMediaQuery("(min-width:1000px)");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [newMemberFields, setNewMemberFields] = useState({
    username: "",
    role: "",
    toko: "",
    jenistidur: "",
    password: "",
  });

  const getMembers = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/staff`);
      setMembers(data);
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const groupMembersToTeams = (members = []) => {
    return members.reduce((a, c) => {
      if (!Array.isArray(a[c.toko])) {
        a[c.toko] = [];
      }
      a[c.toko].push(c);

      return a;
    }, {});
  };

  const handleMemberModal = () => {
    setMemberModal(!memberModal);
  };

  const registerNewMember = async () => {
    const { username, password, role, toko, jenistidur } = newMemberFields;
    try {
      await axios.post(`${baseUrl}/staff`, {
        username,
        password,
        role,
        toko,
        jenistidur,
      });

      handleMemberModal();
      setNewMemberFields({
        username: "",
        role: "",
        toko: "",
        password: "",
        jenistidur: "",
      });
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const getListToko = async () => {
    try {
      const {
        data: { data: dataKasir },
      } = await axios.get(`${baseUrl}/getAlltoko`);

      setListToko(dataKasir);
    } catch (err) {
      setNotify({
        message: err.response.data.msg,
        isOpen: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (members.length) {
      setTeams(groupMembersToTeams(members));
    }
  }, [members]);

  useEffect(() => {
    getListToko();
    getMembers();
  }, []);

  return (
    <Fragment>
      <Box position="relative">
        {matchSm && (
          <Typography
            style={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {new Date().toLocaleDateString("id", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
        )}
        <Typography variant="h4" style={{ marginBottom: 10 }}>
          HRD Happy
        </Typography>
        <IconButton
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
          onClick={handleMemberModal}
        >
          <AddCircleRounded />
        </IconButton>
      </Box>

      {!matchSm && (
        <Typography>
          {new Date().toLocaleDateString("id", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
      )}
      <Box style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        {Object.keys(teams)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }

            if (a > b) {
              return 1;
            }

            return 0;
          })
          .map((team, index) => (
            <TeamCard
              cardStyle={{
                minWidth: 400,
              }}
              toko={team}
              teamMembers={teams[team]}
              getMembers={getMembers}
              listToko={listToko}
            />
          ))}
        {/* {fillEmptyGridSpaceMd(Object.keys(teams).length)} */}
      </Box>
      <TeamTokoDialog
        open={memberModal}
        handleClose={handleMemberModal}
        onConfirmAction={registerNewMember}
        title={"Tambah anggota baru"}
        confirmText="Daftar"
      >
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="ID Nama"
          type="text"
          fullWidth
          onChange={(e) =>
            setNewMemberFields({ ...newMemberFields, username: e.target.value })
          }
        />
        <TextField
          margin="dense"
          id="jenistidur"
          label="Jenis Tidur"
          fullWidth
          select
          onChange={(e) =>
            setNewMemberFields({
              ...newMemberFields,
              jenistidur: e.target.value,
            })
          }
        >
          {jenistidur.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          id="role"
          label="Role"
          fullWidth
          select
          onChange={(e) =>
            setNewMemberFields({ ...newMemberFields, role: e.target.value })
          }
        >
          {roles.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          autoComplete="off"
          margin="dense"
          id="toko"
          label="Toko"
          type="text"
          select
          fullWidth
          onChange={(e) =>
            setNewMemberFields({ ...newMemberFields, toko: e.target.value })
          }
        >
          {listToko.map(({ id, name }) => (
            <MenuItem key={id} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </TeamTokoDialog>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </Fragment>
  );
};

export default TeamToko;
