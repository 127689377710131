import { makeStyles } from '@material-ui/core'
import { SignalCellularNull } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

/**
 * @param {any} initialValues contains initial values of each state
 * @param {boolean} validateOnChange marks when the element starts controlling value onChange
 * @param {*} validate function starts when param validateOnChange === true
 * @returns values, setValues, errors, setErrors, handleInputChange, handleFileInputChange,resetForm
 */

export function useForm(initialValues,validateOnChange = false, validate){
    const [values,setValues] = useState(initialValues)
    const [errors,setErrors] = useState({})
    
    const handleInputChange = (e) => {
        const {name,value} = e.target
        setValues({
            ...values,
            [name] : value,
        })
        if(validateOnChange === true){
            validate({[name]:value})
        }
    }

    const handleFileInputChange = (e) => {
        const {name,files} = e.target
            setValues({
                ...values,
                [name]:files
            })
        if(validateOnChange === true){
            validate({[name] : files})
        }
    }

    const handleDatePicker = (name,date) => {
        setValues({
            ...values,
            [name]:date
        })
        if(validateOnChange === true){
            validate({[name] : date})
        }    
    }

    const [checkboxSelected,setCheckboxSelected] = useState([])
    // for(let i = 0;i<initialVales)
    const handleCheckBoxInputChange = (option,event) => {
        let {name,value} = event.target
        
        const currentIndex = checkboxSelected.indexOf(option)
        value = [...checkboxSelected]
        if(currentIndex === -1){
            value.push(option)
        }else {
            value.splice(currentIndex,1)
        }
        setCheckboxSelected(value)
        setValues({
            ...values,
            [name] : value
        })
        if(validateOnChange === true){
            validate({[name] : value})
        }
    }  


    const handleChecked = (option) => {
        if(checkboxSelected.indexOf(option) === -1){
            return false
        }else {
            return true
        }
    }

    const resetForm = () => {
        setValues(initialValues)
        setErrors({})
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleFileInputChange,
        handleDatePicker,
        checkboxSelected,
        handleChecked,
        handleCheckBoxInputChange,
        resetForm,
    }
}

const useStyles = makeStyles(theme => ({
    root:{
        '& .MuiFormControl-root': {
            width : '100%',
            margin : theme.spacing(0.5),
            paddingRight : theme.spacing(0.5),
            padingLeft : theme.spacing(0.5),
        }
    }
}))

export function Form(props){
    const classes = useStyles()
    const {children, ...other} = props
    return (
        /**{...other} itu yang bawa onSubmit punya element */
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}
