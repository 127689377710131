import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../config/const";
import Controls from "../components/controls/Controls";
import {
  Typography,
  Paper,
  Toolbar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AbsensiPabrik = () => {
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);
  const [absenPabrik, setAbsenPabrik] = useState([]);
  const [toko, setToko] = useState("PADM");
  const [listToko, setListToko] = useState([]);

  const columns = [
    {
      field: "staff",
      headerName: "Staff",
      width: 120,
    },
    {
      field: "type",
      headerName: "golongan",
      width: 175,
    },
    {
      field: "tanggal",
      headerName: "Tanggal",
      width: 175,
      valueGetter: (params) =>
        new Date(params.row.tanggal).toLocaleDateString("en-CA"),
    },
    {
      field: "alasan",
      headerName: "Alasan",
      width: 175,
    },
  ];

  const getAbsensi = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/getAbsensiPabrik`, {
        params: {
          startDate: dateRanges[0].toLocaleDateString("en-CA"),
          endDate: dateRanges[1].toLocaleDateString("en-CA"),
        },
      });
      console.log(absenPabrik);
      setAbsenPabrik(data.map((item, index) => ({ id: index, ...item })));
    } catch (error) {
      console.error(error);
    }
  };

  const getListKasir = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAbsensi();
  }, [dateRanges]);

  useEffect(() => {
    getListKasir();
  }, []);

  return (
    <>
      <Typography variant="h3">Absensi Staff Pabrik</Typography>
      <Paper style={{ height: 600, padding: 20, marginTop: 20 }}>
        <Box style={{ display: "flex" }}>
          <Toolbar>
            <Controls.Label label="Select date: " />
            <Box style={{ width: 10 }} />
            <Controls.DateRangeCustomPicker
              name="date-range"
              setDates={setDateRanges}
              dateRanges={dateRanges}
            />
          </Toolbar>
        </Box>
        <Box style={{ marginBottom: 10 }} />
        <DataGrid
          columns={columns}
          rows={absenPabrik}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          disableSelectionOnClick
          style={{ minHeight: 450, height: "auto" }}
        />
      </Paper>
    </>
  );
};

export default AbsensiPabrik;
