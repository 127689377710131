import React, { useState } from 'react'
import Axios from 'axios'
import Controls from '../../components/controls/Controls'
import ArchiveIcon from '@material-ui/icons/Archive';
import { baseUrl } from '../../config/const';

export default function ArchiveProduct({param,location}){
    const [confirmPopup,setConfirmPopup] = useState({isOpen:false,title:'',subtitle:''})
    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})
    
    const archiveProduct = async(param) => {
        await Axios.post(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/archiveProduct/${param}` :
            `/archiveProduct/${param}` 
        )
        .then((response) => {
            setNotify({
                isOpen : true,
                message : response.data.msg,
                type : 'success',
                location : location
            })
        })
        .catch((err) => {
            setNotify({
                isOpen : true,
                message : err.response.data.msg,
                type : 'error',
                location : location
            })
        })
    }
    return (
        <>
            <Controls.ActionButton
                onClick={() => 
                    setConfirmPopup({
                        isOpen : true,
                        title :`Yakin archive produk ini?`,
                        subtitle : 'Anda bisa unarchive nanti',
                        onConfirm : () => {archiveProduct(param)}
                    }
                )}>
                <ArchiveIcon fontSize="medium" style={{color:'green'}} />
            </Controls.ActionButton>
            <Controls.Notification
            notify={notify}
            setNotify={setNotify} />

            <Controls.ConfirmPopup 
            confirmPopup={confirmPopup}
            setConfirmPopup={setConfirmPopup} />
        </>
    )
}