import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";

const TeamTokoDialog = ({
  open,
  handleClose,
  children,
  dialogText,
  title,
  onConfirmAction,
  confirmText,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onConfirmAction}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamTokoDialog;
