import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory, useLocation } from "react-router-dom";
import { Collapse, IconButton } from "@material-ui/core";

import { AccountCircle } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { useMediaQuery } from "@material-ui/core";
import { AuthContext } from "../AppContext";

import { baseUrl } from "../config/const";

import axios from "axios";

const drawerWidth = 210;

export default function AdminDrawer({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const matchSm = useMediaQuery("(min-width:1000px)");
  const { state } = useContext(AuthContext);
  const [topMenuItems, setTopMenuItems] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexGrow: 1,
    },
    active: {
      backgroundColor: "hsla(41, 96%, 48%, 0.15)",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      // backgroundColor :'#26BCD7',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      overflow: "scroll",
    },
    drawerPaper: {
      width: drawerWidth,
      scrollbarWidth: "none",
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      width: !matchSm ? "100vw" : null,
      flexGrow: 1,
      padding: theme.spacing(1),
      marginTop: 1,
    },
  }));

  const classes = useStyles();

  const handleDrawerToggle = () => {
    console.log("run");
    setMobileOpen(!mobileOpen);
  };

  const getFeRoutes = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${baseUrl}/fe-routes`, {
        params: { role: state.role },
      });

      setTopMenuItems(data);
    } catch (err) {
      console.error(err);
    }
  };

  const bottomMenuItems = [
    {
      text: "Email",
      Icon: <MailOutlineIcon />,
      path: "/email",
    },
    {
      text: "Help",
      Icon: <HelpOutlineIcon />,
      path: "/help",
    },
  ];

  const hasChildren = (item) => {
    const { items: children } = item;
    if (children === undefined) {
      return false;
    }
    if (children.constructor !== Array) {
      return false;
    }
    if (children.length === 0) {
      return false;
    }
    return true;
  };

  const TopMenuItem = ({ item }) => {
    const Component = hasChildren(item) ? Multilevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    return (
      <ListItem
        button
        onClick={() => {
          history.push(item.path);
          handleDrawerToggle();
        }}
        className={location.pathname === item.path ? classes.active : null}
      >
        {/* <ListItemIcon>{item.Icon}</ListItemIcon> */}
        <ListItemText primary={item.text} />
      </ListItem>
    );
  };

  const Multilevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen(!open);
    };
    return (
      <div>
        <ListItem
          button
          // onClick={() => history.push(item.path)}
          onClick={handleClick}
        >
          {/* <ListItemIcon>{item.Icon}</ListItemIcon> */}
          <ListItemText primary={item.text} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {children.map((child, key) => (
              <TopMenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </div>
    );
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getFeRoutes();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            style={{
              marginRight: 2,
              display: matchSm ? "none" : "block",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap>
            ADMIN HAPPY
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => history.push("/account")}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile screen */}
      <Drawer
        className={classes.drawer}
        variant="temporary"
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        style={{
          display: matchSm ? "none" : "block",
        }}
        disableScrollLock={true}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {topMenuItems.map((item, index) => (
              <TopMenuItem key={index} item={item} />
            ))}
          </List>

          <Divider />

          <List>
            {bottomMenuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                className={
                  location.pathname === item.path ? classes.active : null
                }
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      {/* Drawer for desktop screen */}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{
          display: matchSm ? "block" : "none",
        }}
        open
        disableScrollLock={true}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {topMenuItems.map((item, index) => (
              <TopMenuItem key={index} item={item} />
            ))}
          </List>

          <Divider />

          <List>
            {bottomMenuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  history.push(item.path);
                }}
                className={
                  location.pathname === item.path ? classes.active : null
                }
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}
