import React, { useContext, useEffect, useState } from 'react'
import Axios from 'axios'
import '../styles/global.css'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Grid, Toolbar, InputAdornment } from '@material-ui/core'
import Modal from 'react-modal'
import Controls from '../components/controls/Controls'
import {Form,useForm} from '../components/useForm'
import { baseUrl } from '../config/const'
import useTable from '../components/useTable'
import { Search } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import {EditOutlined,DeleteOutline} from '@material-ui/icons';
import axios from 'axios'
import { AuthContext } from '../AppContext'


const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
    }
    ,overlay:{zindex : 1000},

  };

const useTableStyles = makeStyles({
    root: {
        width:'100%',
    },
    container : {
        maxHeight : 780,
    },
})

const headCells = [
    {id: 'name',label:'Name',disableSorting: true},
    {id: 'phone',label:'Phone',disableSorting: true},
    {id: 'email',label:'Email',disableSorting: true},
    {id: 'address',label:'Address',disableSorting: true},
    {id: 'contact',label:'Contact',disableSorting: true},
    {id: 'actions',label:'Actions',disableSorting: true}
]

export function EditSupplier({item}){
    const [openPopup,setOpenPopup] = useState(false)
    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})

    const initialValues = {
        name : item.name,
        phone : item.phone,
        email : item.email,
        address : item.address,
        contact : item.contact,
    }

    /**
     * validation function for each element
     * @return error of each element whether it passes the validation process
     */
     const validate = (fieldValues = values) => {
        var temp = { ...errors }
        if('name' in fieldValues){
            temp.name = fieldValues.name.length !== 0 ? "" : "company name must be filled out"
        }
        if('phone' in fieldValues){
            temp.phone = fieldValues.phone ? "" : "phone number must be filled out"
        }
        if('email' in fieldValues){
            if(fieldValues.email.length !== 0){
                if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValues.email) === false){
                    temp.email = 'invalid email format'
                }else {
                    temp.email = ''
                }
            }else {
                temp.email = 'email must be filled out'
            }
        }
        if('address' in fieldValues){
            temp.address = fieldValues.address ? "" : "business address is required"
        }
        if('contact' in fieldValues){
            temp.contact = fieldValues.contact ? "" : "contact person is required"
        }
        setErrors({
            ...temp
        })
        if(fieldValues === values){
            return Object.values(temp).every(x => x === "")
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleFileInputChange,
    } = useForm(initialValues, true, validate)

    const editSupplier = async (param) => {
        if(validate()){
            await axios.put(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/editSupplier/${param}` : 
            `/editSupplier/${param}`,{
                name: values.name,
                phone : values.phone,
                email : values.email,
                address : values.address,
                contact : values.contact
            })
            .then((response) => {
                setNotify({
                    isOpen : true,
                    message : response.data.msg,
                    type : 'success',
                    location : "/supplier/management"
                })
            })
            .catch((err) => {
                setNotify({
                    isOpen : true,
                    message : err.response.data.msg,
                    type : 'error',
                    location : "/supplier/management"
                })
            })
        }
    }

    return (
        <>
        <Controls.ActionButton
        onClick={() => {
            setOpenPopup(true)
        }} >
            <EditOutlined fontSize="medium" />
        </Controls.ActionButton>
        <Controls.Popup
        title="Edit Supplier Info"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup} >
            <Controls.PageHeader
            title="Edit Supplier Info" />
                <Form encType="multipart/form-data" >
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                        <div style={{padding:'10px'}}>
                        <Controls.Label
                            label="Company Name"/>
                        <Controls.Input 
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            placeholder="enter company name of the supplier"
                        />
                        <Controls.Label
                            label="Phone Number"/>
                        <Controls.Input 
                            name="phone"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            placeholder="enter phone number of the supplier"
                        />
                        <Controls.Label
                            label="Email"/>
                        <Controls.Input 
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            placeholder="enter email address"
                        />
                        </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div style={{padding:'10px'}}>
                            <Controls.Label
                            label="Business Address"/>
                        <Controls.Input 
                            name="address"
                            value={values.address}
                            onChange={handleInputChange}
                            error={errors.address}
                            placeholder="enter business address"
                        />                        
                        <Controls.Label
                            label="Contact Person"/>
                        <Controls.Input 
                            name="contact"
                            value={values.contact}
                            onChange={handleInputChange}
                            error={errors.contact}
                            placeholder="enter contact person name"
                        />  
                            </div>
                        </Grid>

                    </Grid>
                    <Controls.Button
                        color="primary"
                        text="Edit Supplier"
                        onClick={() => editSupplier(item.id)} />
                   
                </Form>
        </Controls.Popup>
        <Controls.Notification
        notify={notify}
        setNotify={setNotify} />
        </>
    )
}

export default function SupplierManagement(){
    const {state, dispatch} = useContext(AuthContext);
    const tableStyles = useTableStyles()
    const [suppliers,setSuppliers] = useState([])
    const [message,setMessage] = useState('')
    const [openModal,setOpenModal] = useState(false)

    const [notify,setNotify] = useState({isOpen:false,message:'',type:'',location:''})
    const [filterFn,setFilterFn] = useState({fn: items => {return items}})
    const [openPopup,setOpenPopup] = useState(false)
    const [confirmPopup,setConfirmPopup] = useState({isOpen:false,title:'',subtitle:''})

    const initialValues = {
        name : '',
        phone : '',
        email : '',
        address : '',
        contact : '',
        query : ''
    }

    /**
     * validation function for each element
     * @return error of each element whether it passes the validation process
     */
    const validate = (fieldValues = values) => {
        var temp = { ...errors }
        if('name' in fieldValues){
            temp.name = fieldValues.name.length !== 0 ? "" : "company name must be filled out"
        }
        if('phone' in fieldValues){
            temp.phone = fieldValues.phone ? "" : "phone number must be filled out"
        }
        if('email' in fieldValues){
            if(fieldValues.email.length !== 0){
                if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValues.email) === false){
                    temp.email = 'invalid email format'
                }else {
                    temp.email = ''
                }
            }else {
                temp.email = 'email must be filled out'
            }
        }
        if('address' in fieldValues){
            temp.address = fieldValues.address ? "" : "business address is required"
        }
        if('contact' in fieldValues){
            temp.contact = fieldValues.contact ? "" : "contact person is required"
        }
        setErrors({
            ...temp
        })
        if(fieldValues === values){
            return Object.values(temp).every(x => x === "")
        }
    }
        /**
     * return results of useForm helper method
     * @param initialValues determines the initial value of each state
     * @param true boolean is allowing validating while onChange
     * @param validate is helper method of allowing element to validate while onChange
     */
         const {
            values,
            setValues,
            errors,
            setErrors,
            handleInputChange,
            handleFileInputChange,
            checkboxSelected,
            handleCheckBoxInputChange,
            resetForm,
        } = useForm(initialValues,true,validate)
    /**
     * return results of uthe data from db
     * @param seForm helper method
     * @param records are headCells header of the table
     * @param filterFn filtering function that carries the records
     */
     const {
        TblHead,
        TblContainer,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(state.supplierList,headCells,filterFn)
    const getSuppliers = async () => {
        await Axios.get(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/getSupplier` : 
            `/getSupplier`)
        .then((response) => {
            dispatch({
                type: "UPDATE_LIST",
                payload: {
                    supplierList: response.data.suppliers
                }
            })
        })
        .catch((err) => {
            setNotify({
                isOpen : true,
                type : 'error',
                message : err.response.data.msg,
                location : "/supplier/management"
            })
        })
    }

    const openAddNewSupplier = async(e) => {
        setOpenModal(!openModal)
    }

    /**
     * 
     * @param {*} e indicates the search event
     */
     const handleSearch = (e) => {
        let target = e.target
        setFilterFn({
            fn: items => {
                if(target.value === ''){
                    return items
                }else {
                    return items.filter(x => x.name.includes(target.value))
                }
            }
        })
    }
    useEffect(() => {
        getSuppliers()
    },[])

    const addNewSupplier = async (e) => {
        e.preventDefault()
        if(validate()){
            Axios.post(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/addSupplier` : 
            `/addSupplier`,{
                name: values.name,
                phone : values.phone,
                email : values.email,
                address : values.address,
                contact : values.contact
            })
            .then((response) => {
                setNotify({
                    isOpen : true,
                    message : response.data.msg,
                    type : 'success',
                    location : "/supplier/management"
                })
                resetForm()
            })
            .catch((err) => {
                setNotify({
                    isOpen : true,
                    message : err.response.data.msg,
                    type : 'error',
                    location : "/supplier/management"
                })
            })
        }
    }

    const deleteSupplier = async (param) => {
        await Axios.delete(process.env.NODE_ENV === 'production' ? 
            `${baseUrl}/deleteSupplier/${param}` :
            `/deleteSupplier/${param}`
        )
        .then((response) => {
            setNotify({
                isOpen : true,
                message : response.data.msg,
                type : 'success',
                location : "/supplier/management"
            })
        })
        .catch((err) => {
            setNotify({
                isOpen : true,
                message : err.response.data.msg,
                type : 'error',
                location : "/supplier/management"
            })
        })
    }
    return (
        <div className="supplier-management">
            <h1>Supplier Management</h1>
            {/**List of suppliers */}            
                <Paper elevation={2}>
            <Toolbar>
                <Controls.Label
                label="Search: " /> 
                <Controls.Input 
                inputprops={{
                    startAdornment : (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    )
                }}
            
                onChange={handleSearch}
                placeholder="CARI KODE BARCODE..." />

                <Controls.Button
                className="add-supplier-btn"
                text="Tambah Supplier Baru"
                onClick={openAddNewSupplier} 
                />
                
                </Toolbar>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                        <TableRow 
                        key={item.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.address}</TableCell>
                            <TableCell>{item.contact}</TableCell>
                            <TableCell>
                                <EditSupplier item={item} />
                                <Controls.ActionButton
                                color="secondary"
                                onClick={() => {
                                    setConfirmPopup({
                                        isOpen : true,
                                        title : `Are you sure to delete supplier ${item.name}?`,
                                        subtitle :"You can't undo the operation",
                                        onConfirm : () => {deleteSupplier(item.id)}
                                    })
                                }} >
                                    <CloseIcon />
                                </Controls.ActionButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            <TblPagination />
            </Paper>
            
            {/*Modal for adding new supplier */}
            <Modal isOpen={openModal} style={modalStyles} contentLabel="Add New Supplier">
                <div className="title">Add New Supplier</div>
                <Form encType="multipart/form-data" onSubmit={addNewSupplier}>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                        <div style={{padding:'10px'}}>
                        <Controls.Label
                            label="Company Name"/>
                        <Controls.Input 
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            placeholder="enter company name of the supplier"
                        />
                        <Controls.Label
                            label="Phone Number"/>
                        <Controls.Input 
                            name="phone"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            placeholder="enter phone number of the supplier"
                        />
                        <Controls.Label
                            label="Email"/>
                        <Controls.Input 
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            placeholder="enter email address"
                        />
                        </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div style={{padding:'10px'}}>
                            <Controls.Label
                            label="Business Address"/>
                        <Controls.Input 
                            name="address"
                            value={values.address}
                            onChange={handleInputChange}
                            error={errors.address}
                            placeholder="enter business address"
                        />                        
                        <Controls.Label
                            label="Contact Person"/>
                        <Controls.Input 
                            name="contact"
                            value={values.contact}
                            onChange={handleInputChange}
                            error={errors.contact}
                            placeholder="enter contact person name"
                        />  
                            </div>
                        </Grid>

                    </Grid>
                    <Controls.Button
                        color="primary"
                        text="Add New Supplier"
                        type="submit"
                        />
                        <Controls.Button
                        color="secondary"
                        text="Cancel"
                        onClick={() => setOpenModal(!openModal)} 
                    />                    
                </Form>
            </Modal>
            
            <Controls.Notification
            notify={notify}
            setNotify={setNotify} />

            <Controls.ConfirmPopup
            confirmPopup={confirmPopup}
            setConfirmPopup={setConfirmPopup} />
        </div>
    )
}