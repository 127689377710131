import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Toolbar,
  useMediaQuery,
  useTheme,
  TableFooter,
} from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/controls/button";
import Controls from "../../../components/controls/Controls";
import { baseUrl } from "../../../config/const";
import useTable from "../../../components/useTable";
import OperDialog from "./components/Dialog";

const MovedList = () => {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [completeDialog, setCompleteDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [toko, setToko] = useState("PA2M");
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);
  const [approvedOper, setApprovedOper] = useState([]);
  const [listToko, setListToko] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const [globalScore, setGlobalScore] = useState("");

  const initialValues = {
    segment: "",
    sku: "",
  };

  const [filteringContent, setFilteringContent] = useState(initialValues);

  const [selectedRow, setSelectedRow] = useState("");

  const handleCompleteDialog = (id) => {
    if (!id) {
      setSelectedRow("");
    } else {
      setSelectedRow(id);
    }

    setCompleteDialog(!completeDialog);
  };

  const handleCancelDialog = (id) => {
    if (!id) {
      setSelectedRow("");
    } else {
      setSelectedRow(id);
    }

    setCancelDialog(!cancelDialog);
  };

  const getListKasir = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getOperRecords = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/oper-stok/records`, {
        params: {
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
          target: toko,
          search: filteringContent.sku,
        },
      });

      setApprovedOper(data.data.movedProduct);
      setGlobalScore(data.data.globalScore);
    } catch (err) {
      console.error(err);
    }
  };

  const completeOper = async (id) => {
    try {
      const { data } = await axios.put(`${baseUrl}/oper-stok/records/${id}`, {
        status: "completed",
      });

      getOperRecords();
      handleCompleteDialog();
    } catch (err) {
      console.error(err);
    }
  };

  const cancelOper = async (id) => {
    try {
      const { data } = await axios.put(`${baseUrl}/oper-stok/records/${id}`, {
        status: "cancelled",
        updatedAt: new Date(),
      });

      getOperRecords();
      handleCancelDialog();
    } catch (err) {
      console.error(err);
    }
  };

  const headCells = [
    { id: "penerima", label: "Penerima", disableSorting: true },
    { id: "pemberi", label: "Pemberi", disableSorting: true },
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "qty", label: "Qty", disableSorting: true },
    { id: "status", label: "Status", disableSorting: true },
    { id: "approved_at", label: "Disetujui Pada", disableSorting: true },
    { id: "sent_at", label: "Dikirim Pada", disableSorting: true },
    { id: "completed_at", label: "Diterima Pada", disableSorting: true },
    { id: "rejected_at", label: "Ditolak Pada", disableSorting: true },
    { id: "rejection reason", label: "Alasan Menolak", disableSorting: true },
    {
      id: "date_difference",
      label: "Total Hari Analisis",
      disableSorting: true,
    },
    { id: "score", label: "Score", disableSorting: true },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(approvedOper, headCells, {
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    getListKasir();
  }, []);

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getOperRecords();
    }
  }, [toko, dateRanges, filteringContent]);

  return (
    <Box>
      <Typography variant="h4">Pengoperan Yang Disetujui</Typography>
      <Box style={{ height: 30 }} />
      <Box
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: matchSm ? 20 : null,
        }}
      >
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Pilih toko: " />
          <Controls.Select
            name="toko"
            value={toko}
            noneLabel={"All"}
            onChange={(e) => {
              setToko(e.target.value);
            }}
            otherStyle={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            options={listToko.map(({ name, role }) => {
              return {
                id: name,
                name: name,
              };
            })}
          />
        </Toolbar>
        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Select date: " />
          <Box style={{ width: 10 }} />
          <Controls.DateRangeCustomPicker
            name="date-range"
            setDates={setDateRanges}
            dateRanges={dateRanges}
          />
        </Toolbar>

        <Toolbar
          style={{
            alignItems: "center",
            padding: 0,
            display: "flex",
            width: !matchSm ? "100%" : null,
            justifyContent: !matchSm ? "space-between" : null,
          }}
        >
          <Controls.Label label="Cari SKU: " />
          <Controls.Input
            style={{
              marginBottom: 0,
              marginLeft: 10,
            }}
            onChange={(e) => {
              setTimeout(() => {
                setFilteringContent({
                  ...filteringContent,
                  sku: e.target.value,
                });
              }, 1000);
            }}
            placeholder="CARI Kode ..."
          />
        </Toolbar>
      </Box>

      <Paper>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting()
              .sort((a, b) => {
                if (a.id > b.id) {
                  return 1;
                }

                if (a.id < b.id) {
                  return -1;
                }

                return 0;
              })
              .map((item, index) => (
                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                  <TableCell>{item.target}</TableCell>
                  <TableCell>{item.origin}</TableCell>
                  <TableCell>{item.sku}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>
                    {item.date ? formatDate(item.approved_at) : null}
                  </TableCell>
                  <TableCell>
                    {item.sent_at ? formatDate(item.sent_at) : null}
                  </TableCell>
                  <TableCell>
                    {item.completed_at ? formatDate(item.completed_at) : null}
                  </TableCell>

                  <TableCell>
                    {item.cancelled_at ? formatDate(item.cancelled_at) : null}
                  </TableCell>
                  <TableCell>{item.rejection_reason}</TableCell>
                  <TableCell>{item.date_difference}</TableCell>
                  <TableCell>{item.score}</TableCell>

                  <TableCell>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        text={"Cancel"}
                        disabled={item.status !== "approved"}
                        onClick={() => handleCancelDialog(item.id)}
                      />
                    </Box>
                  </TableCell>
                  <OperDialog
                    open={completeDialog && selectedRow === item.id}
                    title={`Informasi tentang pengoperan ini`}
                    //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
                    handleClose={handleCompleteDialog}
                    withoutActions={true}
                  />
                  <OperDialog
                    open={cancelDialog && selectedRow === item.id}
                    title={`Cancel pengoperan produk ini?`}
                    //dialogText="Silahkan masukkan jumlah stok yang akan di oper"
                    confirmText="Cancel"
                    handleClose={handleCancelDialog}
                    cancelText="Kembali"
                    onConfirmAction={() => {
                      cancelOper(item.id);
                    }}
                  />
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <div style={{ textAlign: "right", padding: 16 }}>
          <div>
            <strong>Global Score </strong>: {globalScore}
          </div>
        </div>
        <TblPagination />
      </Paper>
    </Box>
  );
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export default MovedList;
