import axios from "axios";
import { useContext, useEffect, useState, useRef, Fragment } from "react";
import {
  Paper,
  Box,
  Toolbar,
  TableBody,
  TableRow,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Controls from "../../../../components/controls/Controls";
import { useHistory } from "react-router-dom";
import useDataByPeriods from "../../utils/hooks/useDataByPeriods";
import useTable from "../../../../components/useTable";
import ReactToPrint from "react-to-print";

import { baseUrl } from "../../../../config/const";
import { AuthContext } from "../../../../AppContext";
import { set } from "date-fns";

const GajianCost = () => {
  const { state } = useContext(AuthContext);
  const [toko, setToko] = useState("PA2M");
  const history = useHistory();
  const [listToko, setListToko] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const ref = useRef();
  const [appendedCost, setAppendedCost] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [gajianKetua, setGajianKetua] = useState([]);
  const [gajianTrainee, setGajianTrainee] = useState([]);

  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const [dateRangesKetua, setDateRangesKetua] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);
  const [omzet, setOmzet] = useState(0);

  const { dataByPeriods } = useDataByPeriods({
    dateRanges,
    setNotify,
    toko,
  });

  const sumStaffCost = (dataByPeriods) => {
    const staffCost = [];

    dataByPeriods.forEach((dataPerPeriod) => {
      dataPerPeriod.data.forEach((data) => {
        const staffs = staffCost.find((_staff) => _staff.staff === data.staff);

        if (staffs && data.staff === staffs.staff) {
          staffs.komisi += data.komisi_total;
          staffs.pedoman += Math.round(data.pedoman_in_toko_only) || 0;
          staffs.total +=
            data.komisi_total + (Math.round(data.pedoman_in_toko_only) || 0);
        } else {
          staffCost.push({
            staff: data.staff,
            komisi: data.komisi_total,
            pedoman: Math.round(data.pedoman_in_toko_only),
            bonus_pollux: 0,
            bonus_pedoman: 0,
            trainee: 0,
            total:
              data.komisi_total + (Math.round(data.pedoman_in_toko_only) || 0),
          });
        }
      });
    });

    return staffCost;
  };

  const getKetuaCost = (gajianKetua) => {
    return gajianKetua.map((ketua) => ({
      staff: ketua.staff,
      komisi: ketua.komisi_ketua,
      pedoman: ketua.pedoman,
      bonus_pedoman: ketua.bonus_pedoman,
      bonus_pollux: ketua.bonus_pollux,
      trainee: 0,
      total:
        Number(ketua.komisi_ketua) +
        Number(ketua.pedoman) +
        Number(ketua.bonus_pedoman) +
        Number(ketua.bonus_pollux),
    }));
  };

  const getTraineeCost = (gajianTrainee) => {
    return gajianTrainee.map((trainee) => ({
      staff: trainee.username,
      komisi: 0,
      pedoman: trainee.gaji_pokok,
      bonus_pedoman: 0,
      bonus_pollux: 0,
      total: trainee.gaji_pokok,
    }));
  };

  const appendKetuaAndStaffCost = () => {
    const appended = [
      ...getKetuaCost(gajianKetua),
      ...sumStaffCost(dataByPeriods),
    ];

    getTraineeCost(gajianTrainee).forEach((trainee) => {
      const staffIndex = appended.findIndex(
        (_sta) => _sta.staff === trainee.staff
      );

      if (staffIndex > 0) {
        appended[staffIndex].pedoman += trainee.pedoman;
        appended[staffIndex].total += trainee.total;
      } else {
        appended.push(trainee);
      }
    });

    return appended;
  };

  const getListKasir = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/getAllToko`);

      setListToko(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getOmzet = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/getPjKel?startdate=${formatDate(
          dateRangesKetua[0]
        )}&enddate=${formatDate(dateRangesKetua[1])}&toko=${toko}`
      );

      setOmzet(data.records.reduce((a, c) => (a += c.Klrp), 0));
    } catch (err) {}
  };

  const getGajianKetua = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/gajianKetua`, {
        params: {
          startDate: formatDate(dateRangesKetua[0]),
          endDate: formatDate(dateRangesKetua[1]),
          toko: toko === "-" ? "" : toko,
        },
      });

      setGajianKetua(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
      console.error(err);
    }
  };

  const getGajianTrainee = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/gajianTrainee`, {
        params: {
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
          toko,
        },
      });

      setGajianTrainee(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
      console.error(err);
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const headCells = [
    {
      id: "staff",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Staff",
      width: 100,
    },
    {
      id: "komisi",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Komisi",
      type: "number",
    },
    {
      id: "pedoman",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Pedoman",
      type: "number",
    },
    {
      id: "Bonus Pedoman",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Bonus Pedoman",
      type: "number",
    },
    {
      id: "Bonus Pollux",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Bonus Pollux",
      type: "number",
    },
    {
      id: "Total",
      disableSorting: true,
      sortable: false,
      flex: 1,
      label: "Total",
      type: "number",
    },
  ];

  const {
    TblHead: PsTblHead,
    TblContainer: PsTblContainer,
    styledTableCell: PsTableCell,
    recordsAfterPagingAndSorting: rsRecordsAfterPagingAndSorting,
  } = useTable(
    appendedCost,
    headCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  useEffect(() => {
    getListKasir();
  }, []);

  useEffect(() => {
    if (dateRangesKetua[0] && dateRangesKetua[1]) {
      setLoading(true);
      getGajianKetua();
      getGajianTrainee();
      getOmzet();
    }
  }, [dateRangesKetua, toko]);

  useEffect(() => {
    if (dataByPeriods.length && gajianKetua.length) {
      setAppendedCost(appendKetuaAndStaffCost());
    }
  }, [dataByPeriods, gajianKetua]);

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      setLoading(true);
      getGajianTrainee();
    }
  }, [dateRanges, toko]);

  useEffect(() => {
    setLoading(false);
  }, [dataByPeriods, dateRangesKetua]);

  return (
    <Paper style={{ padding: 20, position: "relative" }}>
      <Typography variant="h4">Gajian Cost</Typography>
      {isLoading && (
        <Fragment>
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.05)",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              borderRadius: 5,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: "50%",
              left: "50%",
              zIndex: 10000000000,
              transform: "translate(-50%,-50%)",
            }}
          >
            <CircularProgress size={80} />
          </div>
        </Fragment>
      )}
      <Toolbar style={{ alignItems: "center" }}>
        <Controls.Label label="Pilih toko: " />
        <Controls.Select
          name="toko"
          value={toko}
          noneLabel={"All"}
          onChange={(e) => {
            setToko(e.target.value);
          }}
          otherStyle={{
            marginBottom: 0,
            marginLeft: 10,
            marginRight: 20,
          }}
          options={listToko.map(({ name, role }) => {
            return {
              id: name,
              name: name,
            };
          })}
        />
        <Toolbar style={{ padding: 0, marginRight: 20 }}>
          <Controls.Label label="Pilih tanggal (Staff): " />
          <Box style={{ width: 10 }} />
          <Controls.DateRangeCustomPicker
            name="date-range"
            setDates={setDateRanges}
            dateRanges={dateRanges}
          />
        </Toolbar>

        <Toolbar style={{ padding: 0 }}>
          <Controls.Label label="Pilih bulan (Ketua): " />
          <Box style={{ width: 10 }} />
          <Controls.MonthPicker
            name="month-picker"
            setDates={setDateRangesKetua}
            dateRanges={dateRangesKetua}
            chartPeriod="monthly"
          />
        </Toolbar>

        <Toolbar style={{ marginRight: 0, paddingRight: 0 }}>
          <Box>
            <ReactToPrint
              trigger={() => (
                <Button
                  disabled={!dataByPeriods.length}
                  variant="outlined"
                  color="primary"
                >
                  Print
                </Button>
              )}
              content={() => ref.current}
              documentTitle={
                dataByPeriods.length
                  ? `staff_${dataByPeriods[0].startDate} - ${
                      dataByPeriods[dataByPeriods.length - 1].endDate
                    }`
                  : ""
              }
            />
          </Box>
        </Toolbar>
      </Toolbar>

      <div ref={ref}>
        <PsTblContainer>
          <PsTblHead />
          <TableBody>
            {rsRecordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ cursor: "pointer" }}
              >
                <PsTableCell style={{ fontSize: 30 }}>{item.staff}</PsTableCell>
                <PsTableCell style={{ fontSize: 30 }}>
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(item.komisi)
                  )}
                </PsTableCell>
                <PsTableCell style={{ fontSize: 30 }}>
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(item.pedoman)
                  )}
                </PsTableCell>
                <PsTableCell style={{ fontSize: 30 }}>
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(item.bonus_pedoman)
                  )}
                </PsTableCell>
                <PsTableCell style={{ fontSize: 30 }}>
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(item.bonus_pollux)
                  )}
                </PsTableCell>
                <PsTableCell style={{ fontSize: 30 }}>
                  {new Intl.NumberFormat(["ban", "id"]).format(
                    parseInt(item.total)
                  )}
                </PsTableCell>
              </TableRow>
            ))}
          </TableBody>
        </PsTblContainer>
      </div>

      <Box style={{ marginBottom: 20 }} />

      <Box style={{ textAlign: "right" }}>
        <Typography style={{ fontSize: 20 }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>Total Cost</span>: Rp{" "}
          {new Intl.NumberFormat(["ban", "id"]).format(
            appendedCost.reduce((a, c) => (a += c.total), 0)
          )}
        </Typography>
        <Typography style={{ fontSize: 20 }}>
          <span style={{ fontWeight: "bold" }}>Omzet</span> : Rp{" "}
          {new Intl.NumberFormat(["ban", "id"]).format(omzet)}
        </Typography>

        <Typography style={{ fontSize: 20 }}>
          <span style={{ fontWeight: "bold" }}>Cost Gaji/Omzet</span> :{" "}
          {(appendedCost.reduce((a, c) => (a += c.total), 0) / omzet).toFixed(
            2
          ) * 100}
          %
        </Typography>
      </Box>
    </Paper>
  );
};

export default GajianCost;
