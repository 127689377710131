import { Typography, TableRow, TableBody, Box, Paper } from "@material-ui/core";
import GajianDetail from "../../../components/DetailDialog";
import useTable from "../../../../../components/useTable";

const GajianTable = ({ periodData }) => {
  const numberize = (param) => {
    return Number(param);
  };

  const psHeadCells = [
    {
      id: "staff",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1.3,
      label: "St",
    },
    {
      id: "pollux_wanita",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "WP",
      type: "number",
      valueGetter: (params) => numberize(params.row.pollux_wanita),
    },
    {
      id: "anak",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "A",
      type: "number",
      valueGetter: (params) => numberize(params.row.anak),
    },
    {
      id: "sports",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "S",
      type: "number",
      valueGetter: (params) => numberize(params.row.sports),
    },
    {
      id: "harian",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "H",
      type: "number",
      valueGetter: (params) => numberize(params.row.harian),
    },
    {
      id: "aksesoris_kaoskaki",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "K",
      type: "number",
      valueGetter: (params) => numberize(params.row.aksesoris_kaoskaki),
    },
    {
      id: "wanita",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "W",
      type: "number",
      valueGetter: (params) => numberize(params.row.wanita),
    },
    {
      id: "pria",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "P",
      type: "number",
      valueGetter: (params) => numberize(params.row.pria),
    },
    {
      id: "tas",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "C",
      type: "number",
      valueGetter: (params) => numberize(params.row.tas),
    },
    {
      id: "pollux_pria",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "PP",
      type: "number",
      valueGetter: (params) => numberize(params.row.pollux_pria),
    },
    {
      id: "total",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1,
      label: "Ttl",
      type: "number",
      valueGetter: (params) => numberize(params.row.total),
    },
    // {
    //   id: "action",
    //   disableColumnMenu: true,
    //   disableSorting: false,
    //   flex: 1,
    //   type: "action",
    //   label: "Dtl",
    //   renderCell: (params) => {
    //     return <GajianDetail params={params} periodData={periodData} />;
    //   },
    // },
  ];

  const rpHeadCells = [
    {
      id: "staff",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "St",
    },
    {
      id: "komisi",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1.5,
      label: "Kms",
      type: "number",
      valueGetter: (params) => numberize(params.row.komisi),
    },
    {
      id: "komisi_lain_lain",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1.5,
      label: "Kms-L",
      type: "number",
      valueGetter: (params) => numberize(params.row.komisi_lain_lain),
    },
    {
      id: "komisi_pengganti_ketua",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1.5,
      label: "Kms-P",
      type: "number",
      valueGetter: (params) => numberize(params.row.komisi_pengganti_ketua),
    },
    {
      id: "komisi_total",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1.5,
      label: "Kms-T",

      type: "number",
      valueGetter: (params) => numberize(params.row.komisi_total),
    },
    {
      id: "pedoman",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1.5,
      label: "Pdm",

      type: "number",
      valueGetter: (params) => numberize(params.row.pedoman),
    },
    {
      id: "total_gaji",
      disableColumnMenu: true,
      disableSorting: false,
      flex: 1.5,
      label: "Gj-T",

      type: "number",
      valueGetter: (params) => numberize(params.row.total_gaji),
    },
    // {
    //   field: "action",
    //   disableColumnMenu: true,
    //   sortable: false,
    //   flex: 1,
    //   type: "action",
    //   headerName: "Detail",
    //   renderCell: (params) => {
    //     return (
    //       <GajianDetail params={params} periodData={periodData} toko={toko} />
    //     );
    //   },
    // },
  ];

  const {
    TblHead: PsTblHead,
    TblContainer: PsTblContainer,
    styledTableCell: PsTableCell,
    recordsAfterPagingAndSorting: rsRecordsAfterPagingAndSorting,
  } = useTable(
    periodData.data,
    psHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  const {
    TblHead: RpTblHead,
    TblContainer: RpTblContainer,
    styledTableCell: RpTableCell,
    recordsAfterPagingAndSorting: rpRecordsAfterPagingAndSorting,
  } = useTable(
    periodData.data,
    rpHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  return (
    <Paper style={{ padding: 5 }}>
      <Typography
        style={{ marginLeft: "auto", textAlign: "right", fontSize: 20 }}
      >
        {periodData.startDate} - {periodData.endDate}
      </Typography>
      <Box style={{ height: 10 }} />
      <PsTblContainer>
        <PsTblHead />
        <TableBody>
          {rsRecordsAfterPagingAndSorting().map((item, index) => (
            <TableRow
              key={index}
              hover
              role="checkbox"
              tabIndex={-1}
              style={{ cursor: "pointer" }}
            >
              <PsTableCell style={{ fontSize: 30 }}>{item.staff}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>
                {item.pollux_wanita}
              </PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.anak}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.sports}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.harian}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>
                {item.aksesoris_kaoskaki}
              </PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.wanita}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.pria}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.tas}</PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>
                {item.pollux_pria}
              </PsTableCell>
              <PsTableCell style={{ fontSize: 30 }}>{item.total}</PsTableCell>
            </TableRow>
          ))}
        </TableBody>
      </PsTblContainer>
      <Box style={{ height: 10 }} />
      <RpTblContainer>
        <RpTblHead />
        <TableBody>
          {rpRecordsAfterPagingAndSorting().map((item, index) => (
            <TableRow
              key={index}
              hover
              role="checkbox"
              tabIndex={-1}
              style={{ cursor: "pointer" }}
            >
              <PsTableCell style={{ fontSize: 30 }}>{item.staff}</PsTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.komisi)
                )}
              </RpTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.komisi_lain_lain)
                )}
              </RpTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.komisi_pengganti_ketua)
                )}
              </RpTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.komisi_total)
                )}
              </RpTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.pedoman)
                )}
              </RpTableCell>
              <RpTableCell style={{ fontSize: 30 }}>
                {new Intl.NumberFormat(["ban", "id"]).format(
                  parseInt(item.total_gaji)
                )}
              </RpTableCell>
            </TableRow>
          ))}
        </TableBody>
      </RpTblContainer>
    </Paper>
  );
};

export default GajianTable;
