import { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Toolbar,
  Box,
  Paper,
  Button,
  TableRow,
  TableBody,
} from "@material-ui/core";
import ReactToPrint from "react-to-print";

import { baseUrl } from "../../../../../config/const";
import Controls from "../../../../../components/controls/Controls";
import useTable from "../../../../../components/useTable";

const GajianTable = ({ listToko, setNotify }) => {
  const now = new Date(Date.now());
  const [dateRanges, setDateRanges] = useState([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const [gajianTrainee, setGajianTrainee] = useState([]);

  const ref = useRef();

  const getGajianTrainee = async () => {
    try {
      const {
        data: { data, msg },
      } = await axios.get(`${baseUrl}/gajianTrainee`, {
        params: {
          startDate: formatDate(dateRanges[0]),
          endDate: formatDate(dateRanges[1]),
        },
      });

      setGajianTrainee(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
      console.error(err);
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const numberize = (param) => {
    return Number(param);
  };

  const gajiHeadCells = [
    {
      id: "username",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "Trainee",
    },
    {
      id: "toko",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "Toko",
    },
    {
      id: "tanggalmasuk",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "Tanggal Masuk",
    },
    {
      id: "totalpresensi",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "Total Hadir",
    },
    {
      id: "gaji_pokok",
      disableColumnMenu: true,
      disableSorting: true,
      flex: 1,
      label: "Gaji Pokok",
      type: "number",
      valueGetter: (params) => numberize(params.row.total),
    },
  ];

  const {
    TblHead: PsTblHead,
    TblContainer: PsTblContainer,
    styledTableCell: PsTableCell,
    recordsAfterPagingAndSorting: rsRecordsAfterPagingAndSorting,
  } = useTable(
    gajianTrainee,
    gajiHeadCells,
    {
      fn: (items) => {
        return items;
      },
    },
    true
  );

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      getGajianTrainee();
    }
  }, [dateRanges]);

  return (
    <>
      <Box>
        <Typography variant="h4">Gajian Trainee</Typography>
        <Box style={{ marginBottom: 10 }} />
        <Paper style={{ padding: 10 }} elevation={2}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box style={{ display: "flex", width: "100%" }}>
              <Toolbar style={{ padding: 0 }}>
                <Controls.Label label="Select date: " />
                <Box style={{ width: 10 }} />
                <Controls.DateRangeCustomPicker
                  name="date-range"
                  setDates={setDateRanges}
                  dateRanges={dateRanges}
                />
              </Toolbar>

              <Toolbar style={{ marginLeft: "auto", paddingRight: 0 }}>
                <Box>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outlined" color="primary">
                        Print
                      </Button>
                    )}
                    content={() => ref.current}
                    documentTitle={`trainee_${
                      dateRanges[0]
                        ? dateRanges[0].toLocaleDateString("en-CA")
                        : ""
                    } - ${
                      dateRanges[1]
                        ? dateRanges[1].toLocaleDateString("en-CA")
                        : ""
                    }`}
                  />
                </Box>
              </Toolbar>
            </Box>
          </Box>
          <Box style={{ marginBottom: 10 }} />
          <div ref={ref}>
            <Paper style={{ padding: 5 }}>
              {dateRanges[0] && dateRanges[1] ? (
                <Typography
                  style={{
                    marginLeft: "auto",
                    textAlign: "right",
                    fontSize: 20,
                  }}
                >
                  Trainee | {formatDate(dateRanges[0])} -{" "}
                  {formatDate(dateRanges[1])}
                </Typography>
              ) : null}
              <Box style={{ height: 10 }} />

              <PsTblContainer>
                <PsTblHead />
                <TableBody>
                  {rsRecordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                    >
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.username}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {item.toko}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {formatDate(item.tanggalmasuk)}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.total_presensi)
                        )}
                      </PsTableCell>
                      <PsTableCell style={{ fontSize: 30 }}>
                        {new Intl.NumberFormat(["ban", "id"]).format(
                          parseInt(item.gaji_pokok)
                        )}
                      </PsTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </PsTblContainer>
            </Paper>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default GajianTable;
