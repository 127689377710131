import { useState, useCallback, useEffect } from "react";
import getDifferenceBetweenDates from "../helpers/getDifferenceBetweenDates";
import fillEveryPeriodWithGajianData from "../helpers/fillEveryPeriodWIthGajianData";
import createPeriods from "../helpers/createPeriods";

const useDataByPeriods = ({ dateRanges, setNotify, toko, partai }) => {
  const [periods, setPeriods] = useState([]);
  const [dataByPeriods, setDataByPeriods] = useState([]);

  const handlePeriod = useCallback(async () => {
    const weekCounts = Math.floor(
      getDifferenceBetweenDates(dateRanges[0], dateRanges[1]) / 7
    );
    const remainder =
      getDifferenceBetweenDates(dateRanges[0], dateRanges[1]) % 7;

    let _dataByPeriods = createPeriods(dateRanges[0], weekCounts, remainder);

    setPeriods(_dataByPeriods);
  }, [dateRanges]);

  const fetchPeriodData = useCallback(
    async (_dataByPeriods) => {
      _dataByPeriods = [...periods];
      try {
        _dataByPeriods = await fillEveryPeriodWithGajianData(
          _dataByPeriods,
          toko,
          partai
        );

        setNotify({
          isOpen: true,
          type: "success",
          message: "Sukses mendapatkan data gajian",
          location: "",
        });
      } catch (err) {
        console.error(err);
        setNotify({
          isOpen: true,
          type: "error",
          message: err.message,
          location: "",
        });
      }

      setDataByPeriods(_dataByPeriods);
    },
    [periods, setNotify, toko]
  );

  useEffect(() => {
    if (dateRanges[0] && dateRanges[1]) {
      handlePeriod(dateRanges);
    }
  }, [dateRanges, toko, handlePeriod]);

  useEffect(() => {
    fetchPeriodData(periods);
  }, [periods, toko, fetchPeriodData]);

  return { dataByPeriods };
};

export default useDataByPeriods;
