import { useEffect, useState, useRef } from "react";
import { Typography, Box, Paper, Toolbar, Button } from "@material-ui/core";
import GajianTable from "./GajianTable";
import ReactToPrint from "react-to-print";

const GajianFullRange = ({ dataByPeriods, DateRangePicker }) => {
  const [allData, setAllData] = useState([]);
  const ref = useRef();

  const reshapeAllData = (dataByPeriods) => {
    console.log("dataByPeriods", dataByPeriods);
    if (!dataByPeriods[0].data.length) {
      return [];
    }

    // break object references
    dataByPeriods = JSON.parse(JSON.stringify(dataByPeriods));

    const keyList = Object.keys(dataByPeriods[0].data[0]);
    return dataByPeriods.reduce((a, { data: c }, i) => {
      if (i === 0) {
        a = [...c];
        return a;
      }

      if (c.length) {
        c.forEach((obj) => {
          const _staff = a.find((st) => st.staff === obj.staff);

          if (!_staff) {
            a.push(obj);
          } else {
            const stIndex = a.findIndex((st) => st.staff === obj.staff);
            keyList.forEach((key) => {
              if (key !== "staff" && key !== "id") {
                a[stIndex][key] = Number(a[stIndex][key]) + Number(obj[key]);
              }
            });
          }
        });
      }

      return a;
    }, []);
  };

  useEffect(() => {
    if (dataByPeriods.length) {
      setAllData(reshapeAllData(dataByPeriods));
    }
  }, [dataByPeriods]);
  return (
    <Box>
      <Typography variant="h4">Gajian Staff Per Rentang Tanggal</Typography>
      <Box style={{ marginBottom: 10 }} />
      <Paper style={{ padding: 10 }} elevation={2}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Toolbar style={{ padding: 0 }}>{DateRangePicker}</Toolbar>
            <Box style={{ marginRight: 10 }} />
          </Box>
          <Toolbar style={{ marginRight: 0, paddingRight: 0 }}>
            <Box>
              <ReactToPrint
                trigger={() => (
                  <Button
                    disabled={!dataByPeriods.length}
                    variant="outlined"
                    color="primary"
                  >
                    Print
                  </Button>
                )}
                content={() => ref.current}
                documentTitle={
                  dataByPeriods.length
                    ? `staff_${dataByPeriods[0].startDate} - ${
                        dataByPeriods[dataByPeriods.length - 1].endDate
                      }_full_range`
                    : ""
                }
              />
            </Box>
          </Toolbar>
        </Box>
        <Box style={{ marginBottom: 10 }} />
        <div ref={ref}>
          {allData.length ? (
            <GajianTable
              periodData={{
                startDate: dataByPeriods[0].startDate,
                endDate: dataByPeriods[dataByPeriods.length - 1].endDate,
                data: allData,
              }}
            />
          ) : null}
          <Box style={{ width: 20 }} />
        </div>
      </Paper>
    </Box>
  );
};

export default GajianFullRange;
