import {
  Paper,
  TableBody,
  TableRow,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { baseUrl } from "../../config/const";
import { useForm } from "../../components/useForm";
import { AuthContext } from "../../AppContext";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const categories = [
  {
    id: "p",
    name: "Pria",
  },
  {
    id: "w",
    name: "Wanita",
  },
  {
    id: "a",
    name: "Anak",
  },
  {
    id: "s",
    name: "Sports",
  },
  {
    id: "h",
    name: "Harian",
  },
  {
    id: "k",
    name: "Aksesoris",
  },
];

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zindex: 1000 },
};

export default function ProductRank({ dateRanges }) {
  const { state } = useContext(AuthContext);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [productImage, setProductImage] = useState("");

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    category: "",
    toko: "PA2M",
    limit: 5,
  });

  const [filtered, setFiltered] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const initialValues = {
    category: "-",
    toko: "PA2M",
    limit: 5,
  };

  const headCells = [
    { id: "Klkode", label: "Peringkat", disableSorting: true },
    { id: "Klfak", label: "Informasi Produk", disableSorting: true },
    { id: "Klrp", label: "Jumlah Terjual", disableSorting: true },
  ];

  const {
    TblHead,
    TblContainer,
    TblPagination,
    styledTableCell: TableCell,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });

  const validate = () => false;

  const { values, handleInputChange } = useForm(initialValues, validate);

  const getTopSellingProducts = async () => {
    if (!dateRanges[1]) {
      return;
    }
    setLoading(true);
    await axios
      .get(
        `${baseUrl}/getTopSellingProducts?toko=${
          filteringContent.toko
        }&segmentation=${filteringContent.category}&limit=${
          filteringContent.limit
        }&startdate=${dateRanges[0].toLocaleDateString(
          "en-CA"
        )}&enddate=${dateRanges[1].toLocaleDateString(
          "en-CA"
        )}&kode=${search}&nama=${search}`
      )
      .then((response) => {
        setFiltered(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // setNotify({
        //   isOpen: true,
        //   type: "error",
        //   message: error.response.data.msg,
        //   location: "/sales/analytics",
        // });
      });
  };

  async function getProductImage(sku) {
    try {
      const {
        data: { records },
      } = await axios.get(`${baseUrl}/getProduct/${sku}`);

      setProductImage(
        records.length
          ? records[0].images[0]
          : `https://dummyimage.com/600x400/a1a1a1/f7f7f7.png&text=${sku}+Belum+Memiliki+Gambar`
      );
    } catch (err) {}
  }

  let timeout;

  useEffect(() => {
    if (productImage) {
      setModalOpen(true);
    }
  }, [productImage]);

  useEffect(() => {
    getTopSellingProducts();
  }, [filteringContent, dateRanges, search]); //eslint-disable-line

  return (
    <div className="online-sales" style={{ marginBottom: 20 }}>
      <h1>Peringkat Produk</h1>
      <Paper
        elevation={3}
        style={{ margin: "auto", minHeight: 325, position: "relative" }}
      >
        {isLoading ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.1)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}

        <div
          style={{ display: "flex", flexDirection: !matchSm ? "column" : null }}
        >
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Search: " />
            <Controls.Input
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                clearTimeout(timeout);
                setTimeout(() => {
                  setSearch(e.target.value);
                }, 1000);
              }}
              placeholder="CARI Kode atau Nama..."
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={values.toko}
              noneLabel="All"
              onChange={(e) => {
                // console.log(e)
                handleInputChange(e);

                setFilteringContent({
                  ...filteringContent,
                  toko: e.target.value === "-" ? "" : e.target.value,
                });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
            />
          </Toolbar>

          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Pilih Category : " />
            <Controls.Select
              name="category"
              value={values.category}
              onChange={(e) => {
                handleInputChange(e);
                setFilteringContent({
                  ...filteringContent,
                  category:
                    e.target.value === "Aksesoris"
                      ? "k"
                      : e.target.value === "-"
                      ? ""
                      : e.target.value[0],
                });
              }}
              options={categories.map(({ name }) => {
                return {
                  id: name,
                  name: name,
                };
              })}
            />
          </Toolbar>
          <Toolbar
            style={{ justifyContent: !matchSm ? "space-between" : null }}
          >
            <Controls.Label label="Atur Limit : " />
            <Controls.Input
              name="limit"
              value={values.limit}
              onChange={(e) => {
                handleInputChange(e);
                setFilteringContent({
                  ...filteringContent,
                  limit: e.target.value,
                });
              }}
              type="number"
            />
          </Toolbar>
        </div>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  style={{ cursor: "pointer" }}
                  //   onClick={() => {
                  //     handlePopUpOpen();
                  //     setInvoiceSelected(item.id);
                  //   }}
                >
                  {item.peringkat}
                </TableCell>
                <TableCell
                  onClick={() => {
                    getProductImage(item.Klkode);
                  }}
                >{`${item.Klkode} - ${item.Klnama}`}</TableCell>
                <TableCell>{item.totalPembelian}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Controls.Notification notify={notify} setNotify={setNotify} />

      <Controls.ConfirmPopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
      />
      <Controls.Popup
        title={"Product Preview Image"}
        openPopup={modalOpen}
        setOpenPopup={setModalOpen}
        onClose={() => setTimeout(() => setProductImage(""), 500)}
      >
        <div className="title">
          <img
            src={productImage}
            alt="product-preview"
            style={{
              width: "100%",
              height: 400,
              objectFit: "contain",
            }}
          />
        </div>
      </Controls.Popup>
    </div>
  );
}
