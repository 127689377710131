import axios from "axios";
import { baseUrl } from "../../config/const";

export async function getClaimedVouchers(dispatch, setNotify) {
  try {
    const { data } = await axios.get(`${baseUrl}/getClaimedVouchers`);

    dispatch({
      type: "UPDATE_LIST",
      payload: {
        vouchersClaimed: data.records,
      },
    });

    const { data: dataKasir } = await axios.get(`${baseUrl}/getPoinPenjualan`);

    dispatch({
      type: "UPDATE_LIST",
      payload: {
        listKasir: dataKasir.listKasir,
      },
    });
  } catch (err) {
    setNotify({
      message: err.response.data.msg,
      isOpen: true,
      type: "error",
    });
  }
}

export async function getPointsAdded(dispatch, setNotify) {
  try {
    const { data } = await axios.get(`${baseUrl}/getPoinPenjualan`);

    dispatch({
      type: "UPDATE_LIST",
      payload: {
        pointsAdded: data.listPoinPenjualan,
      },
    });

    dispatch({
      type: "UPDATE_LIST",
      payload: {
        listKasir: data.listKasir,
      },
    });
  } catch (err) {
    setNotify({
      message: err.response.data.msg,
      isOpen: true,
      type: "error",
    });
  }
}
