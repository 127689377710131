import {
  Grid,
  InputAdornment,
  Paper,
  TableCell,
  TableRow,
  Toolbar,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { Search } from "@material-ui/icons";
import axios from "axios";
import { baseUrl } from "../../config/const";
import CloseIcon from "@material-ui/icons/Close";
import { Form, useForm } from "../../components/useForm";
import Button from "../../components/controls/button";
import { AuthContext } from "../../AppContext";
import { getFormInfo } from "./helper/promoprice";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export function ManagePromoPrice({ records }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const initialValues = {
    promotions: "-",
    type: "-",
    discount: "",
    newPrice: "",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("type" in fieldValues) {
      temp.type = fieldValues.type !== "-" ? "" : "Tipe promo harus dipilih";
    }
    if ("discount" in fieldValues) {
      if (values.type === "disc") {
        temp.discount =
          fieldValues.discount !== "" ? "" : "Discount harus diisi";
      }
    }
    if ("newPrice" in fieldValues) {
      if (values.type === "ob") {
        temp.newPrice =
          fieldValues.newPrice !== "" ? "" : "Harga baru harus diisi";
      }
    }
    if ("promotions" in fieldValues) {
      if (values.type !== "NORMAL") {
        temp.promotions =
          fieldValues.promotions !== "-" ? "" : "Jenis promo harus dipilih";
      } else {
        temp.promotions = "";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const getAllPromotions = async () => {
    await axios
      .get(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/getAllPromotions`
          : "/getAllPromotions"
      )
      .then((response) => {
        setPromotions(response.data.records);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.msg,
          type: "error",
          location: "/marketing/promotion",
        });
      });
  };
  console.log(promotions);
  useEffect(() => {
    getAllPromotions();
  }, []);

  const typeOptions = [
    { id: "DISC", name: "Discount" },
    { id: "OB", name: "Obral" },
    { id: "NORMAL", name: "Balik ke Harga Normal" },
  ];

  const managePromotionPrice = async () => {
    if (validate()) {
      await axios
        .post(
          process.env.NODE_ENV === "production"
            ? `${baseUrl}/managePromotionProductPrice`
            : "/managePromotionProductPrice",
          {
            records: records,
            promotions: values.promotions,
            type: values.type,
            newPrice: values.newPrice,
            discount: values.discount,
          }
        )
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.msg,
            type: "success",
            location: "/products/priceManagement",
          });
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: err.response.data.msg,
            type: "error",
            location: "/products/priceManagement",
          });
        });
    }
  };

  return (
    <div>
      <Button
        disabled={records.length === 0 ? true : false}
        onClick={() => setOpenPopup(!openPopup)}
        text="Atur Harga Promo"
        style={{
          backgroundColor:
            records.length === 0 ? "hsl(0, 60%, 60%)" : "hsl(0, 74%, 40%)",
          color: "white",
          width: "100%",
        }}
      />
      <Controls.Popup
        title="Atur Harga Produk Promo"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <h4 style={{ fontSize: "1.2em" }}>
              Jumlah Produk: {records.length}
            </h4>
            <div style={{ fontSize: "1.2em" }}>
              {records.map((item, index) => (
                <span key={index}>{item.sku} , </span>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Label label="Pilih Promosi" />

            <Controls.Select
              name="promotions"
              value={values.promotions}
              onChange={handleInputChange}
              options={promotions}
              error={errors.promotions}
            />

            <Controls.Label label="Jenis Promosi" />

            <Controls.Select
              name="type"
              value={values.type}
              onChange={handleInputChange}
              options={typeOptions}
              error={errors.type}
            />

            <div style={{ display: values.type === "OB" ? "block" : "none" }}>
              <Controls.Label label="Masukkan harga promo nya" />
              <Controls.Input
                type="number"
                placeholder="ex:200000"
                name="newPrice"
                value={values.newPrice}
                onChange={handleInputChange}
                error={errors.newPrice}
              />
            </div>
            <div style={{ display: values.type === "DISC" ? "block" : "none" }}>
              <Controls.Label label="Masukkan Angka Discount nya" />
              <Controls.Input
                type="number"
                placeholder="ex: untuk 19%, isi 19 saja"
                name="discount"
                value={values.discount}
                onChange={handleInputChange}
                error={errors.discount}
                required={true}
              />
            </div>
            <div
              style={{ display: values.type === "NORMAL" ? "block" : "none" }}
            >
              <span>Harga Normal (setelah diskon 5% dari harga Barcode) </span>
            </div>
            <Controls.Button
              onClick={managePromotionPrice}
              type="submit"
              text="Submit"
            />
          </Grid>
        </Grid>
      </Controls.Popup>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export function SelectedProducts({ records, deleteSelectedProduct }) {
  const headCells = [
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "pname", label: "Product Name", disableSorting: true },
    { id: "actions", label: "Actions", disableSorting: true },
  ];
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  return (
    <div style={{ width: "auto" }}>
      <Paper elevation={2}>
        <h3>Selected Products</h3>
        <TblContainer>
          <TblHead />
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item.sku} hover role="checkbox" tabIndex={-1}>
              <TableCell>{item.sku}</TableCell>
              <TableCell>{item.pname}</TableCell>
              <TableCell>
                <Controls.ActionButton
                  onClick={() => deleteSelectedProduct(item)}
                >
                  <CloseIcon />
                </Controls.ActionButton>
              </TableCell>
            </TableRow>
          ))}
        </TblContainer>
        <TblPagination />
      </Paper>
    </div>
  );
}

export default function PromoPriceManagement() {
  const { state, dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const headCells = [
    { id: "actions", label: "", disableSorting: true },
    { id: "sku", label: "SKU", disableSorting: true },
    { id: "price3", label: "Harga Barcode" },
    { id: "price4", label: "harga Promo" },
    { id: "pgol", label: "Gol. Produk", disableSorting: true },
    { id: "currentpromo", label: "Current Promo", disableSorting: true },
    { id: "promostatus", label: "Promo Status" },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });
  const [filterContent, setFilterContent] = useState({
    departments: [],
    categories: [],
  });

  const [selectAll, setSelectAll] = useState(false);

  // for filtering
  const [filteringContent, setFilteringContent] = useState({
    search: "",
    department: "-",
    category: "-",
  });

  const [filtered, setFiltered] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const {
    styledTableCell: TableCell,
    TblHead,
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(filtered, headCells, {
    fn: (items) => {
      return items;
    },
  });

  const initialValues = {
    department: "-",
    category: "-",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("startdate" in fieldValues) {
      temp.startdate =
        fieldValues.startdate !== null ? "" : "Start Date shouldn't be empty";
    }
    if ("enddate" in fieldValues) {
      temp.enddate = fieldValues.enddate ? "" : "Start Date shouldn't be empty";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  function handleFilter() {
    let filteredDisplay = state.promotedProductList;

    if (filteringContent.search) {
      // console.log("search filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        return item.sku.includes(filteringContent.search);
      });
    }

    if (filteringContent.department) {
      // console.log("date filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (filteringContent.department === "-") {
          return true;
        }
        return item.department === filteringContent.department;
      });
    }

    if (filteringContent.category) {
      // console.log("toko filter")
      filteredDisplay = filteredDisplay.filter((item) => {
        if (filteringContent.category === "-") {
          return true;
        }
        return item.category === filteringContent.category;
      });
    }

    setFiltered(filteredDisplay);
  }

  const getCompleteProducts = async () => {
    await axios
      .get(
        process.env.NODE_ENV === "production"
          ? `${baseUrl}/getCompleteProducts`
          : `/getCompleteProducts`
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST",
          payload: {
            promotedProductList: response.data.records,
          },
        });
      })
      .catch((err) => {
        setNotify({
          message: err.response.data.msg,
          isOpen: true,
          type: "error",
        });
      });
  };

  const selectProduct = async (item) => {
    setSelectedProducts((selectedProducts) => selectedProducts.concat(item));
  };
  const deleteSelectedProduct = async (item) => {
    setSelectedProducts((selectedProducts) =>
      selectedProducts.filter((x) => x !== item)
    );
  };

  useEffect(() => {
    getFormInfo(setFilterContent);
  }, []);

  useEffect(() => {
    getCompleteProducts();
  }, []);

  useEffect(() => {
    if (selectAll) {
      selectProduct(filtered);
    } else {
      setSelectedProducts([]);
    }
  }, [selectAll]);

  useEffect(() => {
    handleFilter();
  }, [filteringContent]); //eslint-disable-line

  return (
    <div className="promotions" style={{ height: "100%" }}>
      <h1>Pengaturan Harga Promo</h1>
      <Grid container>
        <Grid item xs={12} sm={6} style={{ paddingRight: matchSm ? 5 : 0 }}>
          <div>
            <Paper elevation={1}>
              <h3>Choose Products</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: !matchSm ? "column" : null,
                }}
              >
                <Toolbar
                  style={{ justifyContent: !matchSm ? "space-between" : null }}
                >
                  <Controls.Label label="Cari: " />
                  <Controls.Input
                    onChange={(e) => {
                      setFilteringContent({
                        ...filteringContent,
                        search: e.target.value,
                      });
                    }}
                    placeholder="CARI KODE BARCODE"
                  />
                </Toolbar>

                <Toolbar
                  style={{ justifyContent: !matchSm ? "space-between" : null }}
                >
                  <Controls.Label label="PILIH DEPARTMENT " />
                  <Controls.Select
                    name="department"
                    value={values.department}
                    onChange={(e) => {
                      // console.log(e)
                      handleInputChange(e);

                      setFilteringContent({
                        ...filteringContent,
                        department: e.target.value,
                      });
                    }}
                    options={filterContent.departments.map(({ name }) => {
                      return {
                        id: name,
                        name: name,
                      };
                    })}
                  />
                </Toolbar>

                <Toolbar
                  style={{ justifyContent: !matchSm ? "space-between" : null }}
                >
                  <Controls.Label label="PILIH CATEGORY " />
                  <Controls.Select
                    name="category"
                    value={values.category}
                    onChange={(e) => {
                      // console.log(e)
                      handleInputChange(e);

                      setFilteringContent({
                        ...filteringContent,
                        category: e.target.value,
                      });
                    }}
                    options={filterContent.categories.map(({ name }) => {
                      return {
                        id: name,
                        name: name,
                      };
                    })}
                  />
                </Toolbar>
              </div>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    onChange={() => {
                      setSelectAll(!selectAll);
                    }}
                    checked={selectAll}
                  />
                }
                label="Select All"
              />
              <TblContainer>
                <TblHead />
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow
                    style={{
                      backgroundColor: selectedProducts.includes(item)
                        ? "#e4e4e4"
                        : "#fff",
                    }}
                    key={item.sku}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>
                      <Button
                        disabled={
                          selectedProducts.includes(item) ? true : false
                        }
                        onClick={() => selectProduct(item)}
                        text="Pilih"
                        style={{
                          backgroundColor: "rgb(177, 27, 27)",
                          color: selectedProducts.includes(item)
                            ? "white"
                            : "white",
                        }}
                      />

                      {/* <button
                                                disabled={selectedProducts.includes(item) ? true : false}
                                                onClick={() => selectProduct(item)}
                                                style={{backgroundColor:'lightblue',color:'black'}}>
                                                    Pilih
                                                </button> */}
                    </TableCell>
                    <TableCell>{item.sku}</TableCell>
                    {/* <TableCell>{item.color}</TableCell> */}
                    {/* <TableCell>{item.category}</TableCell> */}
                    <TableCell>{item.price3}</TableCell>
                    <TableCell>{item.price4}</TableCell>
                    <TableCell>{item.pgol_label}</TableCell>
                    <TableCell>{item.promo_code}</TableCell>
                    <TableCell>{item.promostatus}</TableCell>
                  </TableRow>
                ))}
              </TblContainer>
              <TblPagination />
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingLeft: matchSm ? 5 : 0 }}>
          <SelectedProducts
            records={selectedProducts}
            deleteSelectedProduct={deleteSelectedProduct}
          />
          <div style={{ right: 0, paddingTop: "10px" }}>
            <ManagePromoPrice records={selectedProducts} />
          </div>
        </Grid>
      </Grid>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
