import React from "react";
import "./styles/SummaryItem.css"; //moved to head tag

const SummaryItem = ({
  productImage,
  productCode,
  productSize,
  productColor,
  productPrice,
  productQty,
}) => {
  const totalPrice = productQty * productPrice;
  return (
    <td className="summary-item-container">
      <table role="presentation" border="0" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr className="item-purchased-inner">
            <td style={{ textAlign: "left" }}>
              <img
                className="product-img"
                src={productImage[0]}
                alt="product name"
              />
            </td>
            <td>
              <div className="product-bought-container">
                <p>{productCode}</p>
                <p>{productSize}</p>
                <p>{productColor}</p>
              </div>
            </td>
            <td>
              <p>{`x${productQty}`}</p>
            </td>
            <td style={{ textAlign: "right" }}>
              <p style={{ textAlign: "right" }}>{totalPrice}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  );
};

export default SummaryItem;
