import { Fragment, useState, useEffect } from "react";
import {
  IconButton,
  Box,
  TextField,
  MenuItem,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { AddCircleRounded, Delete, Edit } from "@material-ui/icons";
import axios from "axios";
import TeamTokoDialog from "./TeamTokoDialog";
import { baseUrl } from "../config/const";
import Controls from "../components/controls/Controls";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const roles = [
  { value: "ketua", label: "Ketua" },
  { value: "staff", label: "Staff" },
  { value: "penggantiketua", label: "Pengganti Ketua" },
  { value: "trainee", label: "Trainee" },
];

const tipeStaff = [
  { value: "harian", label: "Harian" },
  { value: "borongan", label: "Borongan" },
];

const keaktifan = [
  { value: true, label: "true" },
  { value: false, label: "false" },
];

const TeamPabrik = () => {
  const [teams, setTeams] = useState({});
  const [members, setMembers] = useState([]);
  const [memberModal, setMemberModal] = useState(false);
  const [listToko, setListToko] = useState([]);

  const matchSm = useMediaQuery("(min-width:1000px)");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [newMemberFields, setNewMemberFields] = useState({
    username: "",
    type: "",
  });

  const getMembers = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/staffPabrik`);
      setMembers(data);
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const handleMemberModal = () => {
    setMemberModal(!memberModal);
  };

  const registerNewMember = async () => {
    const { username, type } = newMemberFields;
    try {
      await axios.post(`${baseUrl}/staffPabrik`, {
        username,
        type,
      });

      handleMemberModal();
      setNewMemberFields({
        username: "",
        type: "",
      });
      getMembers();
    } catch (e) {
      setNotify({
        message: e.response.data.msg,
        isOpen: true,
        type: "error",
        location: "/hrd",
      });
      console.error(e);
    }
  };

  const columns = [
    {
      field: "username",
      headerName: "Name",
      width: 200,
    },
    {
      field: "type",
      headerName: "Golongan",
      width: 200,
    },
    {
      field: "date_registered",
      headerName: "Tanggal Masuk",
      width: 200,
    },
    {
      field: "cuti",
      headerName: "Cuti",
      width: 200,
      renderCell: (params) => (
        <CutiComponent {...params.row} getMembers={getMembers} />
      ),
    },
    {
      field: "active",
      headerName: "Aktif",
      width: 200,
    },
    {
      field: "edit",
      type: "action",
      headerName: "Edit",
      width: 200,
      renderCell: (params) => (
        <EditComponent {...params.row} getMembers={getMembers} />
      ),
    },
    {
      field: "delete",
      type: "action",
      headerName: "Hapus",
      width: 200,
      renderCell: (params) => (
        <DeleteComponent {...params.row} getMembers={getMembers} />
      ),
    },
  ];

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <Fragment>
      <Box position="relative">
        {matchSm && (
          <Typography
            style={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {new Date().toLocaleDateString("id", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
        )}
        <Typography variant="h4" style={{ marginBottom: 10 }}>
          HRD Pabrik Happy
        </Typography>
        <IconButton
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
          onClick={handleMemberModal}
        >
          <AddCircleRounded />
        </IconButton>
      </Box>

      {!matchSm && (
        <Typography>
          {new Date().toLocaleDateString("id", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
      )}

      <Box style={{ height: 800 }}>
        <DataGrid
          rowHeight={150}
          rows={members}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          disableSelectionOnClick
          density="compact"
        />
      </Box>

      <TeamTokoDialog
        open={memberModal}
        handleClose={handleMemberModal}
        onConfirmAction={registerNewMember}
        title={"Tambah anggota baru"}
        confirmText="Daftar"
      >
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Nama Pegawai"
          type="text"
          fullWidth
          onChange={(e) =>
            setNewMemberFields({ ...newMemberFields, username: e.target.value })
          }
        />
        <TextField
          margin="dense"
          id="type"
          label="Golongan"
          fullWidth
          select
          onChange={(e) =>
            setNewMemberFields({
              ...newMemberFields,
              type: e.target.value,
            })
          }
        >
          {tipeStaff.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </TeamTokoDialog>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </Fragment>
  );
};

const CutiComponent = ({ cuti, username, type, getMembers = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cutiOffModalOpen, setCutiOffModalOpen] = useState(false);

  const [cutiFields, setCutiFields] = useState({ alasan: "" });

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCutiOffModal = () => {
    setCutiOffModalOpen(!cutiOffModalOpen);
  };

  const cutiMember = async ({ staff, tanggal, alasan, type }) => {
    try {
      await axios.post(`${baseUrl}/insertAbsensiPabrik`, {
        staff,
        tanggal,
        alasan,
        type,
      });

      handleModal();
      getMembers();
    } catch (e) {
      // setNotify({
      //   message: e.response.data.msg,
      //   isOpen: true,
      //   type: "error",
      //   location: "/hrd",
      // });
      console.error(e);
    }
  };

  const disableCutiStaff = async ({ username: staff }) => {
    try {
      await axios.put(`${baseUrl}/disableStaffCutiPabrik`, {
        staff,
      });

      handleCutiOffModal();
      getMembers();
    } catch (e) {
      // setNotify({
      //   message: e.response.data.msg,
      //   isOpen: true,
      //   type: "error",
      //   location: "/hrd",
      // });
      console.error(e);
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        value="cuti"
        control={
          <Switch
            // disabled={alreadyPresence}
            name="toggle-cuti"
            color="primary"
            checked={cuti}
            onChange={() => {
              if (!cuti) {
                handleModal();
              } else {
                handleCutiOffModal();
              }
            }}
          />
        }
      />

      <TeamTokoDialog
        open={modalOpen}
        handleClose={handleModal}
        title={`Apakah staff ${username} akan mengambil cuti?`}
        onConfirmAction={() => {
          cutiMember({
            staff: username,
            tanggal: new Date().toISOString().split("T")[0],
            alasan: cutiFields.alasan,
            type,
          });
        }}
        confirmText="Cuti"
      >
        <TextField
          defaultValue={cutiFields.alasan}
          autoComplete="off"
          margin="dense"
          id="name"
          label="Alasan"
          type="text"
          fullWidth
          onChange={(e) =>
            setCutiFields({
              ...cutiFields,
              alasan: e.target.value,
            })
          }
        />
      </TeamTokoDialog>
      {/* lepas cuti */}
      <TeamTokoDialog
        open={cutiOffModalOpen}
        handleClose={() => {
          handleCutiOffModal();
        }}
        title={`Apakah anda ingin melepaskan status cuti untuk ${username}?`}
        onConfirmAction={() => {
          disableCutiStaff({ username });
        }}
        confirmText="Lepas Cuti"
      ></TeamTokoDialog>
    </Fragment>
  );
};

const DeleteComponent = ({ username, getMembers = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const deleteMember = async (username) => {
    try {
      await axios.delete(`${baseUrl}/staffPabrik`, {
        data: { username },
      });

      // setNotify({
      //   message: data.msg,
      //   isOpen: true,
      //   type: "success",
      //   location: "/hrd",
      // });

      handleModal();
      getMembers();
    } catch (e) {
      // setNotify({
      //   message: e.response.data.msg,
      //   isOpen: true,
      //   type: "error",
      //   location: "/hrd",
      // });
      console.error(e);
    }
  };

  return (
    <Fragment>
      <IconButton
        onClick={() => {
          handleModal();
        }}
      >
        <Delete />
      </IconButton>

      <TeamTokoDialog
        open={modalOpen}
        handleClose={handleModal}
        title={`Apakah anda ingin hapus ${username} dari pabrik?`}
        onConfirmAction={() => {
          deleteMember(username);
        }}
        confirmText="Delete"
      ></TeamTokoDialog>
    </Fragment>
  );
};

const EditComponent = ({
  username,
  type,
  cuti,
  active,
  updated_at = "",
  getMembers = () => {},
}) => {
  const [memberData, setMemberData] = useState({
    username,
    type,
    cuti,
    active,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const editMember = async () => {
    const { type, active } = memberData;
    try {
      const { data } = await axios.put(
        `${baseUrl}/staffPabrik?_username=${username}`,
        {
          username: memberData.username,
          type,
          active,
        }
      );

      console.log(data);
      handleModal();
      getMembers();
    } catch (e) {
      // setNotify({
      //   message: e.response.data.msg,
      //   isOpen: true,
      //   type: "error",
      //   location: "/hrd",
      // });
      console.error(e);
    }
  };

  return (
    <Fragment>
      <IconButton
        onClick={() => {
          handleModal();
        }}
      >
        <Edit />
      </IconButton>

      <TeamTokoDialog
        open={modalOpen}
        handleClose={handleModal}
        title="Edit data anggota"
        onConfirmAction={() => {
          editMember();
        }}
        confirmText="Edit"
      >
        <Typography>
          {`Last updated at
          ${
            updated_at
              ? new Date(updated_at).toLocaleString("en-GB")
              : " - never"
          } `}
        </Typography>
        <TextField
          defaultValue={memberData.username}
          autoComplete="off"
          margin="dense"
          id="name"
          label="ID Nama"
          type="text"
          fullWidth
          onChange={(e) =>
            setMemberData({
              ...memberData,
              username: e.target.value,
            })
          }
        />
        <TextField
          defaultValue={memberData.type}
          autoComplete="off"
          margin="dense"
          id="type"
          label="Golongan"
          fullWidth
          select
          onChange={(e) =>
            setMemberData({
              ...memberData,
              type: e.target.value,
            })
          }
        >
          {tipeStaff.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          defaultValue={memberData.active}
          margin="dense"
          id="active"
          label="Aktif"
          fullWidth
          select
          onChange={(e) =>
            setMemberData({
              ...memberData,
              active: e.target.value,
            })
          }
        >
          {keaktifan.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </TeamTokoDialog>
    </Fragment>
  );
};

export default TeamPabrik;
