import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./controls.css";

const datesInMonths = {
  0: 31,
  1: 28,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
};

const MonthPicker = ({ error, setDates, dateRanges, chartPeriod }) => {
  const [startDate, setStartDate] = useState(
    new Date(dateRanges[0].getFullYear(), dateRanges[0].getMonth(), 1)
  );

  function handleDates(date) {
    let tmpDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    let tmpDateEnd = new Date(tmpDateStart.getTime());

    // jika current month kurang dari month yang dicari
    // maka gunakan full month
    let currentMonth = new Date().getMonth();

    if (tmpDateEnd.getMonth() < currentMonth) {
      tmpDateEnd.setDate(
        tmpDateEnd.getDate() + datesInMonths[tmpDateEnd.getMonth()] - 1
      );
    } else {
      tmpDateEnd = new Date();
    }

    setDates([tmpDateStart, tmpDateEnd]);
    setStartDate(tmpDateStart);
  }

  useEffect(() => {
    if (chartPeriod === "monthly") {
      handleDates(dateRanges[0]);
    }
  }, [chartPeriod]); //eslint-disable-line

  return (
    <div style={{ zIndex: 10 }}>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          handleDates(date);
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
      />
      <span className="error">{error}</span>
    </div>
  );
};

export default MonthPicker;
