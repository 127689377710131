import {
  Paper,
  TextField,
  Button,
  Card,
  Toolbar,
  IconButton,
  Input,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useContext, useEffect, useState , Fragment } from "react";
import Controls from "../components/controls/Controls";
import { AuthContext } from "../AppContext";
import axios from "axios";
import { Add, Close } from "@material-ui/icons";
import https from "https";
import { CircularProgress } from "@material-ui/core";

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

const variableCategory = [
  { id: "firstname", name: "firstname" },
  { id: "email", name: "email" },
  { id: "custom", name: "custom" },
];

const tipeLangganan = [
  { key: "W", name: "Wanita" },
  { key: "P", name: "Pria" },
  { key: "A", name: "Anak" },
  { key: "PO", name: "Pollux" },
  { key: "H", name: "Daily" },
  { key: "S", name: "Sports" },
  { key: "K", name: "Aksesoris" },
];

const Whatsapp = () => {
  const { state } = useContext(AuthContext);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    location: "",
  });

  const [images, setImages] = useState({
    currentFile: undefined,
    previewImage: undefined,
    progress: 0,
    message: "",
    isError: false,
    imageInfos: [],
  });

  const [message, setMessage] = useState("");
  const [category, setCategory] = useState({
    name: null,
    value: null,
  });

  const [toko, setToko] = useState({ name: "", members: [] });
  const [variables, setVariables] = useState([null]);
  const [langgananToko, setLanggananToko] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [shareType, setShareType] = useState("member");
  const [langgananTerpilih, setLanggananTerpilih] = useState({ name: ""});

  function selectFile(event) {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      setImages({
        ...images,
        currentFile: file,
        previewImage: reader.result,
        message: "",
      });
    };

    reader.readAsDataURL(file);
  }

  async function sendMessages() {
    try {
      const formData = new FormData();

      const members =
        shareType === "member" ? toko.members : langgananToko;

      formData.append("file", images.currentFile);
      formData.append("message", message);
      formData.append("members", JSON.stringify(members));
      formData.append("variables", JSON.stringify(variables));

      const { data } = await axios.post(
        `https://api.notoputrooffice.com/api/whatsapp-web/broadcast/${
          toko.name ? toko.name : null
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          httpsAgent,
        }
      );

      setNotify({
        isOpen: true,
        message: data.msg,
        type: "success",
        location: "/whatsapp/send-messages",
      });
    } catch (e) {
      setNotify({
        isOpen: true,
        message: e.response.data.msg,
        type: "error",
        location: "/whatsapp/send-messages",
      });
      console.error(e);
    }
  }

  async function getStoreMembers() {
    const {
      data,
    } = await axios.get(
      `https://cashier-happystore.xyz.notoputrooffice.com/getStoreMembers/${toko.name}`
    );
    console.log(data.records)
    setToko({ ...toko, members: data.records.rows });
  }

  async function getLanggananToko(langgananType) {
    setLoading(true);
    const { data } = await axios.get(
      // URL ini diganti menjadi url/:toko/:segmentation n
      `https://cashier-happystore.xyz.notoputrooffice.com/getStoreLangganan/${toko.name}/${langgananType}`
    )
    if(data){
      setLanggananToko(data.hasil.rows)
      setLanggananTerpilih({name: langgananType.charAt(0).toUpperCase() + langgananType.slice(1)})  
      setLoading(false)
    }
  }

  useEffect(() => {

    getStoreMembers();

  }, [toko.name]); //eslint-disable-line

  return (
    <div>
      <h1>Send Whatsapp Messages</h1>
      <Paper elevation={3} style={{ padding: 10, textAlign: "left" }}>
      {isLoading ? (
          <Fragment>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.1)",
                height: "100%",
                width: "100%",
                zIndex: 8,
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={80} style={{}} />
            </div>
          </Fragment>
        ) : null}

        <div
          style={{
            display: "flex",
            marginBottom: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Add />}
            size="small"
            color="primary"
            onClick={() => {
              const latestVariables = [
                ...variables,
                {
                  id: variables.length++,
                  category: null,
                  value: null,
                },
              ];

              setVariables(latestVariables);
            }}
          >
            Tambah Variable
          </Button>

          <label htmlFor="btn-upload" style={{ margin: 0, marginLeft: 20 }}>
            <Input
              id="btn-upload"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={selectFile}
            />
            <Button
              size="small"
              variant="outlined"
              color="primary"
              component="span"
              style={{ height: "100%" }}
            >
              Add Image
            </Button>
          </label>

          <Toolbar
            style={{
              minHeight: 0,
              marginBottom: !matchSm ? 20 : undefined,
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <Controls.Label label="Pilih toko: " />
            <Controls.Select
              name="toko"
              value={toko.name}
              onChange={(e) => {
                setToko({ ...toko, name: e.target.value });
              }}
              options={state.listKasir.map(({ id, username }) => {
                return {
                  id: username,
                  name: username,
                };
              })}
              otherStyle={{
                margin: 0,
                marginLeft: 10,
                marginRight: 10,
              }}
              style={{ minWidth: 120, padding: 0 }}
            />
          </Toolbar>
        </div>

        <div style={{ marginBottom: 20, display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            <Button
              variant={shareType === "member" ? "contained" : "outlined"}
              color="primary"
              component="span"
              onClick={() => setShareType("member")}
            >
              Member
            </Button>
            {toko.members.length && shareType === "member" ? (
              <div
                style={{ marginTop: 10 }}
              >{`${toko.members.length} members ditemukan`}</div>
            ) : null}
          </div>

          <Button
            variant={shareType === "langganan" ? "contained" : "outlined"}
            color="primary"
            component="span"
            style={{ height: "100%" }}
            onClick={() => setShareType("langganan")}
          >
            Langganan
          </Button>
        </div>

        {shareType === "langganan" && (
          <div style={{ display: "flex" }}>
            {tipeLangganan.map((langganan) => (
              <div>
                <Button
                  key={langganan.name}
                  variant={
                    langgananTerpilih.name === langganan.name
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  component="span"
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    setLanggananTerpilih({
                      name: langganan.name
                    });
                    getLanggananToko((langganan.name).toLowerCase());
                  }
                  }
                >
                  {langganan.name}
                </Button>
                { langganan.name === langgananTerpilih.name && shareType === 'langganan' ? (
                  <div>
                    {`${langgananToko.length} langganan ditemukan`}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}

        {images.currentFile ? (
          <div
            className="file-name"
            style={{ marginTop: 10, textAlign: "left" }}
          >
            {images.currentFile.name}
          </div>
        ) : null}

        {images.previewImage && (
          <Card
            variant="outlined"
            style={{
              width: 150,
              padding: 5,
              marginTop: 10,
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <img
              className="preview my20"
              style={{ width: "100%" }}
              src={images.previewImage}
              alt=""
            />
          </Card>
        )}

        {variables.map((variable, index) => {
          if (!variable || !index) {
            return null;
          }
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              key={variable.id}
            >
              <TextField
                value={variable.id}
                disabled={true}
                variant="outlined"
                size="small"
                style={{ maxWidth: 50 }}
                inputProps={{ style: { textAlign: "center" } }}
              />
              <Toolbar
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 0,
                }}
              >
                <Controls.Label label="Kategori " />
                <Controls.Select
                  value={variables[variable.id].category}
                  onChange={(e) => {
                    const varCopy = [...variables];
                    varCopy[variable.id].category = e.target.value;

                    setVariables(varCopy);
                  }}
                  options={variableCategory}
                  otherStyle={{
                    margin: 0,
                    marginLeft: 10,
                  }}
                />
              </Toolbar>

              {variable.category === "custom" && (
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="value"
                  value={category.value}
                  onChange={(e) => {
                    const varCopy = [...variables];
                    varCopy[variable.id].value = e.target.value;

                    setVariables(varCopy);
                  }}
                />
              )}
              <IconButton
                onClick={() => {
                  const newVar = [...variables].filter((v, index) => {
                    if (!index) {
                      return false;
                    }
                    return variable.id !== v.id;
                  });

                  newVar.unshift(null);

                  newVar.forEach((v, index) => {
                    if (!index) {
                      return;
                    }

                    v.id = index;
                  });

                  setVariables(newVar);
                }}
              >
                <Close />
              </IconButton>
            </div>
          );
        })}

        <TextField
          id="outlined-multiline-static"
          label="Message Body"
          multiline
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          rows={4}
          variant="outlined"
          style={{ width: "100%", marginTop: 20 }}
        />
        <div
          style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}
        >
          <Button variant="contained" color="primary" onClick={sendMessages}>
            Send
          </Button>
        </div>
      </Paper>
      <Controls.Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default Whatsapp;
