import Summary from "./Summary";
import React from "react";
import "./styles/Invoice.css"; // use head tag

export default function SalesDetails({
  shippingFee,
  cart,
  shippingAddress,
  invoice,
  totalPrice,
  subtotalPrice
}) {



  return (
    // <!-- <span className="preheader"
    //   >This is preheader text. Some clients will show this text as a
    //   preview.</span
    // > -->
    <table
      role="presentation"
      border="0"
      cellPadding="0"
      cellSpacing="0"
      className="body"
    >
      <tr>
        <td>&nbsp;</td>
        <td className="container">
          <div className="content">
            {/* <!-- START CENTERED WHITE CONTAINER --> */}
            <table role="presentation" className="main">
              {/* <!-- START MAIN CONTENT AREA --> */}
              <tr>
                <td className="wrapper">
                  <table
                    role="presentation"
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tr>
                      <td>
                        <img
                          className="header-img"
                          src="https://notobedev.xyz/assets/happystorelogo.png"
                          alt="happy logo"
                        />
                        <p>Sales details from invoice :</p>
                        <p>
                          <b style={{ marginBottom: "0" }}>
                            {invoice}
                          </b>
                        </p>

                        <div class="address-invoice">
                          <div className="section__header">
                            <h2 className="section__title">
                              Alamat Pengiriman
                            </h2>
                          </div>
                          <div className="address-preview">
                            <p>
                              <strong>{`${shippingAddress.firstname} ${
                                shippingAddress.lastname || ""
                              }`}</strong>
                            </p>
                            <p>{shippingAddress.address}</p>
                            <p>{`${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.zipcode}`}</p>
                            <p>{shippingAddress.phone}</p>
                          </div>
                        </div>

                        <Summary
                          totalPrice={totalPrice}
                          subtotalPrice={subtotalPrice}
                          shippingFee={shippingFee}
                          cart={cart}
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* <!-- END MAIN CONTENT AREA --> */}
            </table>
            {/* <!-- END CENTERED WHITE CONTAINER --> */}
          </div>
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
  );
}
